export const ourls = {
  warehouseCategories: "data/warehousecategories",
  measurementUnits: "data/measurementunits",
  parcelCategories: "data/parcelcategories",
  permissionGroups: "data/permissiongroups",
  fertilizerKinds: "data/fertilizerkinds",
  mainIngredients: "data/mainingredients",
  annualWorkPlans: "data/annualworkplans",
  cropCategories: "data/cropcategories",
  parcelRegions: "data/parcelregions",
  deliveryTerms: "data/deliveryterms",
  reproductions: "data/reproductions",
  parcelSectors: "data/parcelsectors",
  paymentKinds: "data/paymentkinds",
  paymentTerms: "data/paymentterms",
  workStatuses: "data/workstatuses",
  fertilizers: "data/fertilizers",
  warehouses: "data/warehouses",
  countries: "data/countries",
  positions: "data/positions",
  customers: "data/customers",
  cropSorts: "data/cropsorts",
  reserves: "data/reserves",
  parcels: "data/parcels",
  modules: "data/modules",
  todos: "data/todos",
  crops: "data/crops",
  tools: "tools",
  users: "hr",


  temporaryOperationKinds: "temporaryoperationkinds",
  temporaryInAndOutItems: "temporaryinandoutitems",
  temporaryCustomers: "temporarycustomers",
  temporaryAccountKinds: "temporaryaccountkinds",
  temporaryPayAccounts: "temporarypayaccounts",
  temporaryParcels: "temporaryparcels",
  temporarySectors: "temporarysectors"
};
