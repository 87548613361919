import React, { useEffect } from "react";
import {Button, Form, Col, Input, Row, Select} from "antd";
import { useTranslation } from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import Authorize from "../../../../Elements/Authorize";
const { Option } = Select;
const EquipmentModel = (props) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    useEffect(() => {
        if (props.editing) {
            let names = props.editing.contentForLang;
            let obj = {};
            names.forEach((name) => {
                obj[`name_${name.languagename}`] = name.content;
            });
            form.setFieldsValue(obj);
        } else {
            form.setFieldsValue({
                name_az: "",
                name_en: "",
                name_ru: "",
                name:"",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.editing, t]);

    const saveItem = (values) => {
        // let langs = ["az", "en", "ru"];
        let obj = {
            modelId: values.modelId,
            // contentForLang: langs.map((lang, index) => {
            //     return { languagename: lang, content: values[`name_${lang}`] };
            // }),
            content: values.name
        };
        if (props.editing) {
            obj["id"] = props.editing.id;
        }
        props.saveItem(obj).then((res) => {
            form.resetFields();
        });
    };

    return (
        <Form onFinish={saveItem} form={form} layout="vertical">
            <Row gutter={[8, 0]}>
                <Col md={24} xs={24}>
                    <div className="form-lang">
                        <Form.Item
                            className="mb-5"
                            validateTrigger="onChange"
                            name="name"
                            rules={[whiteSpace(t("inputError"))]}
                        >
                            <Input placeholder={'Texnikanın modeli'} />
                        </Form.Item>
                        <div className="input-lang">az</div>
                    </div>
                </Col>
                <Col md={24} xs={24}>
                    <Form.Item
                        className="mb-5"
                        validateTrigger="onChange"
                        required
                        name="category"
                        rules={[noWhitespace(t("inputError"))]}
                    >
                        <Select
                            placeholder={<span className="ml-5">Texnikanın kateqoriyası</span>}
                        >
                            <Option key={1} value={'1'}>
                                Type1
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Form.Item
                        className="mb-5"
                        validateTrigger="onChange"
                        required
                        name="type"
                        rules={[noWhitespace(t("inputError"))]}
                    >
                        <Select
                            placeholder={<span className="ml-5">Texnikanın növü</span>}
                        >
                            <Option key={1} value={'1'}>
                                Type1
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col md={24} xs={24}>
                    <Form.Item
                        className="mb-5"
                        validateTrigger="onChange"
                        required
                        name="brand"
                        rules={[noWhitespace(t("inputError"))]}
                    >
                        <Select
                            placeholder={<span className="ml-5">Texnikanın markası</span>}
                        >
                            <Option key={1} value={'1'}>
                                Type1
                            </Option>
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <div className="flex  flex-between mt-15">
                <Button onClick={() => props.cancelEdit(props.name)}>
                    {t("cancel")}
                </Button>
                <Form.Item>
                    <Authorize
                        mainMenu={'administrator'}
                        page={['equipment', 'subs', 'equipmentModel','perms']}
                        type={props.editing ? 'update' : 'create' }
                    >
                        <Button htmlType="submit" type="primary">{t("save")}</Button>
                    </Authorize>
                </Form.Item>
            </div>
        </Form>
    );
};

export default EquipmentModel;
