import React, {useEffect, useState} from "react";
import {Button, Col, Form, Input, InputNumber, Row, Select, Table,} from "antd";
import {useTranslation} from "react-i18next";
import {convertColumns} from "../../../../../utils/columnconverter";
import {getOptions, notify} from "../../../../../redux/actions";
import {connect} from "react-redux";
import agros from "../../../../../const/api";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";

const {Option} = Select;

const ExportModal = (props) => {

    const [selectedRows , setSelectedRows] = useState([]);
    const [selectedRowKeys , setSelectedRowKeys] = useState([]);

    const rowSelection = {
        type: 'checkbox',
        onChange: (selectedRowKeys, selectedRows) =>{
            setStock(selectedRows , selectedRowKeys);
        },
        selectedRowKeys,
        preserveSelectedRowKeys:true,
        hideSelectAll:true,
    }
    const {notify} = props;
    const {t, i18n} = useTranslation();
    const cols = [
        {key: "tableIndex", value: "#", con: true},
        {key: "productCategory", value: t("productCategory"), con: true},
        {key: "product", value: t("product"), con: true},
        {key: "productSorts", value: t("productSorts"), con: true},
        {key: "reproductionName", value: t("reproduction"), con: true},
        {key: "quantity", value: t("quantity"), con: true},
        {key: "price", value: t("price"), con: true},
        {key: "warehouse", value: t("warehouse"), con: true},
    ];
    const [form] = Form.useForm();

    const [data, setData] = useState([]);
    const [stockId, setStockId] = useState(null);

    // table data columns
    const columns = [
        {
            title: "#",
            key: 1,
            dataIndex: "key",
            width: 70,
        },
        {
            title: t("name"),
            key: 7,
            dataIndex: "name",
        },
        {
            title: 'Bir ədədinin qiyməti',
            key: 7,
            dataIndex: "priceOfOne",
        },
        {
            title: t("quantity"),
            key: 7,
            dataIndex: "count",
        },
    ];

    useEffect(() => {
        console.log(props.data)
        setData([]);
        setSelectedRowKeys([]);
        setSelectedRows([]);
        form.resetFields()
        if(props?.data?.id){
            setStockId(props?.data?.id)
            getData(props.data.technique.vin)
        }else{
            setStockId(undefined)
        }
    }, [t, props.visibleExport]);

    const getData = (vin) => {
        let params = {
            vin: vin
        }
        agros.get(`technicalStock/export`, {params}).then((res) => {
            console.log(res);
            setData(
                res.data.map((r, index) => {
                    return {
                        ...r,
                        key: index + 1,
                        index: index,
                        name: r.sparePart.name
                    };
                })
            );
        });
    };


    const setStock = (selectedRows , selectedRowKeys)  => {
       setSelectedRowKeys(selectedRowKeys)
       setSelectedRows(selectedRows)
    };

    const resetAll = () => {
        form.resetFields();
        setData([]);
        setSelectedRowKeys([])
        setSelectedRows([])
        props.triggerFetch();
        props.setVisibleExport(false);
    }

    const saveExport = (values) => {
        if (stockId) {
            agros
                .post("TechnicalStock/Export", {...values, TaskDemandId : stockId})
                .then((res) => {
                    notify("", true);
                    resetAll()
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            notify("", false);
        }
    };

    return (
        <>
            <Form form={form} layout="vertical" onFinish={saveExport}>
                <Row gutter={[16, 0]}>
                    <Col className="mt-20" span={24}>
                        <Table
                            size="small"
                            rowSelection={rowSelection}
                            className="bg-white  mb-10 w-100"
                            columns={columns}
                            dataSource={convertColumns(data, cols)}
                            pagination={{pageSize: 5, current_page: 1}}
                        />
                    </Col>

                    {/*{props.modalType === 'selected' &&   <h3 className="red mb-5">Tələb olunan miqdar  {props.quantity} dir.</h3>}*/}

                    <>
                        <Col span={24}>
                            <Row  gutter={[16, 16]}>
                                {selectedRows.map((p , i)=>(
                                    <Col key={i} lg={4} md={12}>
                                        <div className={'animated zoomIn p-2 border'}>
                                            <Row className={'flex-a'} gutter={[16,8]}>
                                                <Col lg={24} sm={12} xs={24}>
                                                    <Form.Item
                                                        className={'d-none'}
                                                        initialValue={p.id}
                                                        validateTrigger="onChange"
                                                        name={[
                                                            "ExportSpareList",
                                                            i,
                                                            "stockId",
                                                        ]}
                                                        rules={[noWhitespace(t("inputError"))]}
                                                    >
                                                        <Input type={'hidden'}  className="w-100" />
                                                    </Form.Item>
                                                    <div className="form-lang">
                                                        <p className="flex mb-5 flex-between">
                                                            <p> {t("quantityOf")}</p>
                                                            <p>{p.warehouseName}</p>
                                                        </p>
                                                        <Form.Item
                                                            initialValue={p.quantity}
                                                            validateTrigger="onChange"
                                                            name={[
                                                                "ExportSpareList",
                                                                i,
                                                                "Count",
                                                            ]}
                                                            rules={[noWhitespace(t("inputError"))]}
                                                        >
                                                            <InputNumber
                                                                // onChange={()=>{inputChange()}}
                                                                max={p.quantity} className="w-100" />
                                                        </Form.Item>
                                                        <span className="input-lang btm">
                                                                    {p.measurementUnit}
                                                                </span>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </>
                </Row>
                <div
                    className="modalButtons"
                    style={{position: "absolute", bottom: "20px", right: "25px"}}
                >
                    <Button onClick={() => props.setVisibleExport(false)}>
                        {t("cancel")}
                    </Button>
                    <Button type="primary" className="ml-10" htmlType="submit">
                        {t("save")}
                    </Button>
                </div>
            </Form>




        </>
    );
};

const mapStateToProps = ({options, lang}) => {
    return {options, lang};
};

export default connect(mapStateToProps, {getOptions, notify})(ExportModal);
