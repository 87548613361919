import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {Row, Col, Button, Tooltip, Table, Modal, Popconfirm} from "antd";
import {UnorderedListOutlined, FileTextOutlined, DeleteFilled, CheckCircleFilled} from "@ant-design/icons";
import agros from "../../../../const/api";
import View from "./Modals/View";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import {connect} from "react-redux";
import { notify} from "../../../../redux/actions";

function Documents({notify}) {
  const { t } = useTranslation();
  const [demands, setDemands] = useState([]);
  const [selectedDemand, setSelectedDemand] = useState(0);
  let [trigger, setTrigger] = useState(0);
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "taskName", value: t("name"), con: false },
    { key: "taskNumber", value: t("demmandNo"), con: true },
    { key: "checkStatus", value: "", con: false },
    { key: "index", value: "", con: false },
  ];
  useEffect(() => {
    agros.get("TechniqueSpacePartPurchase").then((res) => {
      setDemands(
        res.data.map((r, index) => {
          return {
            ...r,
            key: index + 1,
            index,
            objectCount: r.sparePartList.length,
            tableIndex: index + 1,
          };
        })
      );
    });
  }, [trigger, t]);

  const [visibleViewDemand, setVisibleViewDemand] = useState(false);



  // table data columns
  const columns = [
    {
      title: "#",
      key: 1,
      dataIndex: "tableIndex",
      width: 60,
    },
    {
      title: 'Təchizatçı',
      key: 4,
      dataIndex: "customer",
    },
    {
      title: 'Son ödəniş tarixi',
      key: 4,
      dataIndex: "paymentLastDate",
    },
    {
      title: 'Ümumi məbləğ',
      key: 4,
      dataIndex: "totalCost",
      render(a){
        return(
            <div>
              {a} AZN
            </div>
        )
      }
    },
    {
      title: 'Çatdırılma məbləği',
      key: 4,
      dataIndex: "transportCost",
      render(a){
        return(
            <div>
              {a} AZN
            </div>
        )
      }
    },
    {
      title: "Tələb obyektləri",
      key: 5,
      dataIndex: "objectCount",
      render: (a) => <span>{a} obyekt</span>,
    },
    {
      title: "",
      key: 7,
      dataIndex: "index",
      width: 20,
      render: (i) => {
        return (
          <div className="flex flex-end">
              <Tooltip className="ml-5" title={'Ehtiyyat hissələri'}>
                <Button
                  onClick={() => viewDemand(i)}
                  className="border-none"
                  type="text"
                  shape="circle"
                >
                  <FileTextOutlined />
                </Button>
              </Tooltip>
          </div>
        );
      },
    },
  ];

  const viewDemand = (index) => {
    setSelectedDemand(index);
    setVisibleViewDemand(true);
  };

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col xs={24}>
          <div className="border page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <UnorderedListOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">Hazırlanan sənədlər</span>
            </div>
          </div>
        </Col>
        <Col xs={24}>
          <div>
            <Table
              size="small"
              className="bg-white w-100"
              columns={columns}
              dataSource={convertColumns(demands, cols)}
              pagination={{
                pageSizeOptions: ["10", "15", "20", "25", "30"],
                showSizeChanger: true,
                current_page: 1,
              }}
            />
          </div>
        </Col>
      </Row>

      <Modal
        title={false}
        centered
        className="addTaskModal demandModal"
        visible={visibleViewDemand}
        onOk={() => setVisibleViewDemand(false)}
        onCancel={() => setVisibleViewDemand(false)}
        footer={[]}
      >
        <View setTrigger={setTrigger} trigger={trigger} setVisibleViewDemand={setVisibleViewDemand} demand={demands[selectedDemand]} />
      </Modal>

      {/*addnewtask or edit task modal*/}

    </div>
  );
}



export default connect(null, {  notify })(Documents);

