import React, {useEffect, useState} from 'react';
import {Button, Col, Tabs , Row , Input} from "antd";
import {BankOutlined} from "@ant-design/icons";
// import Images from "../../../../assets/img/Images/Images";
import Datas from "./Datas";
import {getStorage} from "../../../../redux/actions";
import {connect} from "react-redux";
import { Line } from '@ant-design/plots';
import { Radio } from 'antd';

const { TabPane } = Tabs;


function Storage(props) {

    const {storage, getStorage} = props
    const [selectedWarehouse , setSelectedWarehouse] = useState(null)
    const [value, setValue] = React.useState(1);
    const [search, setSearchName] = React.useState();

    const onChange = s => {
        setValue(s.target.value)
        let e = s.target.value
        console.log(e)
        setData(
            e === 1 ?
                [
                    {
                        year: e === 1 ? '2019' : e === 2 ? 'Fevral' : '12-05-2022',
                        value: e === 1 ? 3 : e === 2 ? 4 : 5,
                    },
                    {
                        year: e === 1 ? '2022' : e === 2 ? 'May' : '14-05-2022',
                        value: e === 1 ? 6.1 : e === 2 ? 4.1 : 8,
                    },
                    {
                        year: e === 1 ? '2021' : e === 2 ? 'Aprel' : '14-05-2022',
                        value: e === 1 ? 5.2 : e === 2 ? 2.1 : 1,
                    },
                    {
                        year: e === 1 ? '2020' : e === 2 ? 'Mart' : '13-05-2022',
                        value: e === 1 ? 4 : e === 2 ? 5.1 : 2,
                    },

                ] :
                e === 2  ?
                [
                    {
                        year: e === 1 ? '2019' : e === 2 ? 'Fevral' : '12-05-2022',
                        value: e === 1 ? 3 : e === 2 ? 4 : 5,
                    },
                    {
                        year: e === 1 ? '2020' : e === 2 ? 'Mart' : '13-05-2022',
                        value: e === 1 ? 4 : e === 2 ? 5.1 : 2,
                    },
                    {
                        year: e === 1 ? '2021' : e === 2 ? 'Aprel' : '14-05-2022',
                        value: e === 1 ? 5.2 : e === 2 ? 2.1 : 1,
                    },
                    {
                        year: e === 1 ? '2022' : e === 2 ? 'May' : '14-05-2022',
                        value: e === 1 ? 6.1 : e === 2 ? 4.1 : 8,
                    },
                ] :
                [
                    {
                        year: e === 1 ? '2019' : e === 2 ? 'Fevral' : '12-05-2022',
                        value: e === 1 ? 3 : e === 2 ? 4 : 5,
                    },
                    {
                        year: e === 1 ? '2020' : e === 2 ? 'Mart' : '13-05-2022',
                        value: e === 1 ? 4 : e === 2 ? 5.1 : 2,
                    },
                    {
                        year: e === 1 ? '2021' : e === 2 ? 'Aprel' : '14-05-2022',
                        value: e === 1 ? 5.2 : e === 2 ? 2.1 : 1,
                    },
                    {
                        year: e === 1 ? '2022' : e === 2 ? 'May' : '14-05-2022',
                        value: e === 1 ? 6.1 : e === 2 ? 4.1 : 8,
                    },
                ]
        )
    };

    useEffect(() => {
        getStorage(search);
    }, [search])


    const [data , setData ] = useState([
        {
            year: '1991',
            value: 3,
        },
        {
            year: '1992',
            value: 4,
        },
        {
            year: '1993',
            value: 3.5,
        },
        {
            year: '1994',
            value: 5,
        },
        {
            year: '1995',
            value: 4.9,
        },
        {
            year: '1996',
            value: 6,
        },
        {
            year: '1997',
            value: 7,
        },
        {
            year: '1998',
            value: 9,
        },
        {
            year: '1999',
            value: 13,
        },
    ])


    const config = {
        data,
        xField: 'year',
        yField: 'value',
        label: {},
        point: {
            size: 5,
            shape: 'diamond',
            style: {
                fill: 'white',
                stroke: 'red',
                lineWidth: 2,
            },
        },
        animation: {
            // Configuration of the first animation
            appear: {
                animation: 'wave-in', // Effects of the first animation
                duration: 1000, // Duration of the first animation
            },
        },
        tooltip: {
            showMarkers: false,
        },
        smooth: true,
        state: {
            active: {
                style: {
                    shadowBlur: 4,
                    stroke: '#000',
                    fill: 'red',
                },
            },
        },
        interactions: [
            {
                type: 'marker-active',
            },
        ],
    };


    return (
        <Row>
            {selectedWarehouse ?
                <Col xs={24}>
                    <div className="border mt-10 animated fadeInDown page-heading flex-align-center flex-between flex p-1 mt-0 bg-white">

                            <>
                                <div className="page-name">
                                    <span className="f-16 pl-1 bold">{selectedWarehouse.warehouseName}</span>
                                </div>
                                <div>
                                    <Button className={'animated fadeIn'} onClick={()=>{setSelectedWarehouse(undefined) }} type={'primary'}>
                                        Geri
                                    </Button>
                                </div>
                            </>
                    </div>
                </Col>
                : ''
            }
            <Col xs={24}>
                <div className="position-relative animated fadeInDown mt-15">
                    <div className="position-absolute w-100 purchase-tabs tab-section">
                        {!selectedWarehouse ?
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <Input placeholder={'Axtar'} onChange={(e) =>{ setSearchName(e.target.value) }} />
                                </Col>
                                {
                                    storage.map((w, i) => (
                                        <Col key={i} onClick={()=>{setSelectedWarehouse(w)}} md={6} xs={12} className={''}>
                                            <div className="bg-white animated zoomIn mainBoxes cards cursor-pointer p-2 shadow">
                                                <div className="flex flex-align-center w-100">
                                                    <div className="circle-green">
                                                        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clipPath="url(#clip0_0_21)">
                                                                <path d="M53.9075 21.4815C53.8286 21.3056 51.9268 17.125 47.6698 13.0507C43.7679 9.31651 37.0146 4.86523 27 4.86523C16.9854 4.86523 10.232 9.31651 6.33008 13.0507C2.07118 17.1267 0.171171 21.3058 0.0922812 21.4817C-0.130256 21.9784 0.0616955 22.5629 0.535457 22.8309L4.56686 25.1115V48.08C4.56686 48.6624 5.03915 49.1347 5.62154 49.1347H48.2917C48.874 49.1347 49.3463 48.6624 49.3463 48.08V25.1112L53.456 22.8355C53.9355 22.5701 54.1317 21.9816 53.9075 21.4815ZM18.2197 38.7021H35.7799V41.8903H18.2197V38.7021ZM15.9226 31.2952V28.5594H38.0771V31.2952H15.9226ZM35.7799 33.4045V36.5927H18.2197V33.4045H35.7799ZM18.2197 47.0254V43.9996H35.7799V47.0254H18.2197ZM48.785 23.011C45.5386 16.3908 39.0162 11.7628 31.7874 10.4854C31.2139 10.3845 30.6666 10.7669 30.5652 11.3404C30.4672 11.8956 30.8465 12.4612 31.4203 12.5626C38.1931 13.7596 44.5141 18.2837 47.237 24.697V47.0254H37.8891V33.4045H39.1316C39.7139 33.4045 40.1862 32.9323 40.1862 32.3499V27.5048C40.1862 26.9224 39.7139 26.4501 39.1316 26.4501H14.8679C14.2855 26.4501 13.8132 26.9224 13.8132 27.5048V32.3499C13.8132 32.9323 14.2855 33.4045 14.8679 33.4045H16.1103V47.0253H6.67633V24.7146C9.50308 18.3285 15.7335 13.7815 22.549 12.5677C23.1226 12.4656 23.5047 11.9179 23.4025 11.3444C23.3132 10.8425 22.88 10.4236 22.3921 10.475C15.0644 11.2469 8.24105 16.7496 5.14852 23.0169L2.44907 21.4898C3.1865 20.1287 4.89287 17.346 7.78859 14.5746C11.4115 11.1075 17.6853 6.97449 27 6.97449C36.3147 6.97449 42.5886 11.1075 46.2114 14.5746C49.1003 17.3396 50.8061 20.1174 51.5464 21.4818L48.785 23.011Z" fill="#51BFA4"/>
                                                                <path d="M27.9734 10.7274C27.6116 9.8582 26.3252 9.88457 26.0068 10.776C25.6855 11.6754 26.6862 12.5182 27.5193 12.0479C27.9784 11.7887 28.1729 11.2136 27.9734 10.7274Z" fill="#51BFA4"/>
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_0_21">
                                                                    <rect width="54" height="54" fill="white"/>
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                    {w.warehouseName}
                                                </div>
                                            </div>
                                        </Col>
                                    ))
                                }
                            </Row>
                            :
                            <div>
                                <div className="w-100 mainBoxes animated zoomIn mb-20 bg-white p-2">
                                    <Line style={{height:'210px'}} {...config} />
                                    <Radio.Group className={'mt-15'} onChange={onChange} value={value}>
                                        <Radio value={1}>İllik</Radio>
                                        <Radio value={2}>Aylıq</Radio>
                                        <Radio value={3}>Həftəlik</Radio>
                                    </Radio.Group>
                                </div>
                                <Tabs defaultActiveKey="1">
                                    <TabPane tab={'Məhsul anbarı'} key={1}>
                                        <Datas className={'mainBoxes'} keys={["cropName", "cropNumber", "cropSortName", "quantity", "measurementUnit" ]} name={"cropList"} w={selectedWarehouse} wareHouse={storage}/>
                                    </TabPane>
                                    <TabPane tab={'Dərman anbarı'} key={2}>
                                        <Datas className={'mainBoxes'} keys={["productName", "mainIngredient", "fertilizerKind", "quantity", "measurementUnit" ]} name={"medicalList"} w={selectedWarehouse}  wareHouse={storage}/>
                                    </TabPane>
                                    <TabPane tab={'Ehtiyyat anbarı'} key={3}>
                                        <Datas className={'mainBoxes'} keys={["productName", "", "", "quantity", "measurementUnit" ]} name={"reserveList"} w={selectedWarehouse}  wareHouse={storage}/>
                                    </TabPane>
                                </Tabs>
                            </div>
                        }

                    </div>
                </div>
            </Col>
        </Row>
    );
}

const mapStateToProps = ({storage }) => {
    return { storage };
}

export default connect(mapStateToProps, {getStorage}) (Storage);
