import { useEffect } from "react";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import { useMap } from "react-leaflet";

L.Marker.prototype.options.icon = L.icon({
    iconUrl: require('./../../../assets/img/icons/car.svg')
});

export default function Routing({s,trigger}) {
    const map = useMap();

    useEffect(() => {
        if (!map) return;

        const routingControl = L.Routing.control({
            waypoints: [L.latLng(s['carLocation'][0].latitude, s['carLocation'][0].longitude), L.latLng(s['carLocation'][trigger].latitude, s['carLocation'][trigger].longitude)],
            routeWhileDragging: true
        }).addTo(map);

        return () => map.removeControl(routingControl);
    }, [map , trigger]);

    return null;
}
