import React, {useEffect, useState} from 'react';
import {UnorderedListOutlined} from "@ant-design/icons";
import {Select, Tabs} from "antd";
import BasicTemplate from "./BasicTemplate/BasicTemplate";
import agros from "../../../../const/api";
import {useTranslation} from "react-i18next";
import TrailTemplate from "./BasicTemplate/TrailTemplate";
import NormativeTemplate from "./BasicTemplate/NormativeTemplate";
const { TabPane } = Tabs;
const { Option } = Select;
const  Fortification = (props) => {
    const { t } = useTranslation();
    const [techCats, setTechCats] = useState([]);
    const [techs, setTechs] = useState([]);
    const [type, setType] = useState('tech'); //or tech or trailers or normatives

    const getCats = async () => {
        await agros.get("TechniqueCategory").then((res) => {
            setTechCats(
                res.data.map((p, index) => {
                    return { key: index + 1, ...p, index: index + 1 };
                })
            );
        });
    };

    const getTech = async () => {
        await agros.get("Technique").then((res) => {
            setTechs(
                res.data.map((p, index) => {
                    return { key: index + 1, ...p, index: index + 1 };
                })
            );
        });
    };

    useEffect(() => {
        getCats();
        getTech()
    }, [t]);

    const handleSectorChange = (i) => {
      setType(i)
    }


    return (
        <div>
            <div className="border bg-white p-2 mt-0">
                <UnorderedListOutlined className="f-20 mr5-15" />
                <span className="f-20 bold">Texnika və təhkim</span>
            </div>
            <div className="border bg-white p-2 mt-10">
                <Select className={'w-100'}
                        defaultValue={type}
                        placeholder={'Növ seçin'}
                    onChange={handleSectorChange}
                >
                    <Option key={'tech'}>Texnika</Option>
                    <Option key={'trailers'}>Aqreqat</Option>
                </Select>
            </div>
            {
                type === 'tech' ?
                    <div className="position-relative mt-15">
                        <div className="position-absolute w-100 purchase-tabs tab-section">
                            <Tabs defaultActiveKey={techCats.length ? techCats[0].id : null}
                                // onChange={}
                            >
                                {
                                    techCats.map((data , i)=>{
                                        return (
                                            <>
                                                {data.hideShowStatus ?
                                                    <TabPane tab={data.name} key={data.id}>
                                                        <BasicTemplate getTech={getTech} techs={techs} data={data}/>
                                                    </TabPane> : ''
                                                }
                                            </>
                                        )
                                    })
                                }
                            </Tabs>
                        </div>
                    </div>
                    :
                    type === 'trailers' ?
                    <div className="position-relative mt-15">
                        <TrailTemplate/>
                    </div>
                    : ''
            }
        </div>
    );
}

export default Fortification;
