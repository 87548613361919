import React from 'react';
import {UnorderedListOutlined} from "@ant-design/icons";
import {Tabs} from "antd";
import BasicTemplate from "./BasicTemplate/BasicTemplate";
const { TabPane } = Tabs;
const  EquipmentService = (props) => {
    const tabsData = [
        {
            id: 0,
            tabname: 'Traktorlar',
        },
        {
            id: 1,
            tabname: 'Kombanyerlər',
        },
        {
            id: 2,
            tabname: 'Yükləyicilər',
        },
        {
            id: 3,
            tabname: 'Aqreqatlar',
        },

    ]

    // basictamplateye id ni oturursenki ona uygun datanicekesen
    return (
        <div>
            <div className="border bg-white p-2 mt-0">
                <UnorderedListOutlined className="f-20 mr5-15" />
                <span className="f-20 bold">Texnikanın servisi</span>
            </div>
            <div className="position-relative mt-15">
                <div className="position-absolute w-100 purchase-tabs tab-section">
                    <Tabs defaultActiveKey="0"
                        // onChange={}
                    >
                        {
                            tabsData.map((data , i)=>{
                                return (
                                    <>
                                        <TabPane tab={data.tabname} key={data.id}>
                                            <BasicTemplate id={data.id}/>
                                        </TabPane>
                                    </>
                                )
                            })
                        }
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default EquipmentService;
