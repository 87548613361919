import React, {useState} from 'react';
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select} from "antd";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import {useTranslation} from "react-i18next";

const {Option} = Select;

function Import(props) {
    const {t} = useTranslation();
    const [show, setShow] = useState(false)
    const [form] = Form.useForm();
    const submitForm = () => {
        console.log('super')
    }
    return (
        <div className='w-100'>
            <Form onFinish={submitForm} layout="vertical" form={form}>
                <div className="commontask">
                    <Row gutter={[16, 16]}>
                        <Col lg={12} md={24}>
                            <Form.Item
                                label={'Tarixi'}
                                name="date"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("dateError"))]}
                            >
                                <DatePicker placeholder={t("selectDate")} className="w-100"/>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                            <Form.Item
                                label={'Növü'}
                                name="type"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Select
                                    // onChange={handleSectorChange}
                                >
                                    <Option key={1}>Marka</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={12} md={24}>
                            <Form.Item
                                label={'Yanacağın markası'}
                                name="brand"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Select
                                    // onChange={handleSectorChange}
                                >
                                    <Option key={1}>Marka</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={12} md={24}>
                            <div className="form-lang">
                                <Form.Item
                                    label={'Miqdarı'}
                                    name="quantity"
                                    validateTrigger="onChange"
                                    rules={[whiteSpace(t("inputError"))]}
                                >
                                    <InputNumber/>
                                </Form.Item>
                                <div className="input-lang btm">l</div>
                            </div>

                        </Col>
                    </Row>
                    <div
                        className="modalButtons"
                        style={{
                            position: "absolute",
                            bottom: "20px",
                            right: "20px",
                        }}
                    >
                        <Button onClick={() =>{props.setImportModal(false)}}>
                            {t("cancel")}
                        </Button>
                        <Button type="primary" className="ml-10" htmlType="submit">
                            {t("save")}
                        </Button>
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default Import;
