import React from 'react';

function PaymentStep(props) {
    return (
        <div className=' animated zoomIn border mt-15 mb-15'>
            <div className="p-2 ">
                Payment
            </div>
        </div>
    );
}
export default PaymentStep;
