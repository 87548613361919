import React, {useEffect, useState} from 'react';
import {UnorderedListOutlined} from "@ant-design/icons";
import {Select, Tabs} from "antd";
import BasicTemplate from "./BasicTemplate/BasicTemplate";
import {useTranslation} from "react-i18next";
import agros from "../../../../const/api";
import moment from "moment";
const { TabPane } = Tabs;
const { Option } = Select;

const  ReviewHistory = (props) => {


    const { t } = useTranslation();
    const [techCats, setTechCats] = useState([]);
    const [techs, setTechs] = useState([]);
    const [technicalInspections, setTechnicalInspections] = useState([]);

    const getCats = async () => {
        await agros.get("TechniqueCategory").then((res) => {
            setTechCats(
                res.data.map((p, index) => {
                    return { key: index + 1, ...p, index: index + 1 };
                })
            );
        });
    };

    const getTech = async () => {
        await agros.get("Technique").then((res) => {
            setTechs(
                res.data.map((p, index) => {
                    return { key: index + 1, ...p, index: index + 1 };
                })
            );
        });
    };

    const getTechnicalInspection = async () => {
        await agros.get("TechnicalInspection").then((res) => {
            setTechnicalInspections(
                res.data.map((p, index) => {
                    return {
                        key: index + 1, ...p, index: index + 1,
                        date: moment(p.date).format("DD-MM-YYYY HH:ss")
                    };
                })
            );
        });
    };

    useEffect(() => {
        getCats();
        getTech()
        getTechnicalInspection()
    }, [t]);

    return (
        <div>
            <div className="border bg-white p-2 mt-0">
                <UnorderedListOutlined className="f-20 mr5-15" />
                <span className="f-20 bold">Texnikanın baxış tarixçəsi</span>
            </div>
            <div className="position-relative mt-15">
                    <div className="position-absolute w-100 purchase-tabs tab-section">
                        <Tabs defaultActiveKey={techCats.length ? techCats[0].id : null}
                            // onChange={}
                        >
                            {
                                techCats.map((data , i)=>{
                                    return (
                                        <>
                                            {data.hideShowStatus ?
                                                <TabPane tab={data.name} key={data.id}>
                                                    <BasicTemplate
                                                        getTech={getTech}
                                                        techs={techs}
                                                        category={data}
                                                        data={technicalInspections.filter(tech => data.id === tech.techniqueCategory.id) }
                                                        technicalInspections={technicalInspections}
                                                        getTechnicalInspections={getTechnicalInspection}
                                                    />
                                                </TabPane> : ''
                                            }
                                        </>
                                    )
                                })
                            }
                        </Tabs>
                    </div>
                </div>
        </div>
    );
}

export default ReviewHistory;

