import React from 'react';
import {Col, Table, Row, Button, Tooltip, Form, Select, Input, PageHeader, Collapse} from "antd";
import {useTranslation} from "react-i18next";
import {CompassOutlined, EditFilled, EyeFilled} from "@ant-design/icons";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import {convertColumns} from "../../../../../utils/columnconverter";
import moment from "moment";
const { Panel } = Collapse;


function View(props) {
    const { t } = useTranslation();

    const  {detailedData} = props

    console.log(detailedData)

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                    <table className="customtable">
                        <tbody>
                            <tr>
                                <td>Texnika Kategoriyasi</td>
                                <td>{detailedData?.techniqueCategory.name}</td>
                            </tr>
                            <tr>
                                <td>Texnika</td>
                                <td>{detailedData?.technique.name}</td>
                            </tr>
                            <tr>
                                <td>Tarix</td>
                                <td> {detailedData?.date}</td>
                            </tr>
                            <tr>
                                <td>Qeyd</td>
                                <td>{detailedData?.note}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col lg={24} xs={24}>
                    {detailedData?.taskList?.length  ?
                        <PageHeader
                            className="border"
                            onBack={false}
                            title={`Tələblər`}
                        /> : ''
                    }
                </Col>
                {detailedData?.taskList?.map((task) => {
                        return (
                            <Col lg={24} xs={24}>
                                <Collapse  >
                                    <Panel header={
                                        <div className="flex flex-between">
                                            <div>{task.taskName}</div>
                                            <div> Tələb No  #{task.taskNumber}</div>
                                        </div>
                                    } key="1">
                                        <div>
                                            <Row gutter={[16,16]}>
                                                <Col xs={24}>
                                                    <table className="customtable">
                                                        <tbody>
                                                        <tr>
                                                            <td>Tələb No</td>
                                                            <td>#{task?.taskNumber}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Növü</td>
                                                            <td>{task?.type === 1 ? 'daxili' : 'servis'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Təxmini məbləğ</td>
                                                            <td>{task?.approximateAmount} azm</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tələbi yaradan şəxs</td>
                                                            <td> {task.requester} </td>
                                                        </tr>

                                                        </tbody>
                                                    </table>
                                                </Col>
                                                {task?.demandList?.length  ?
                                                    task?.demandList?.map(function (demand , index) {
                                                        return (
                                                            <Col xs={12}>
                                                                <PageHeader
                                                                    className="border"
                                                                    onBack={false}
                                                                    title={<div className={'f-16'}>{`Dəyişən detal ${index +1}`}</div>}
                                                                />
                                                                <table className="customtable">
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Texnika ehtiyyat hissəsi</td>
                                                                            <td>{demand?.techniqueSparePart.name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Miqdar</td>
                                                                            <td>{demand?.count} ədəd</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Əldə edilib</td>
                                                                            <td>{demand?.haveTaken ? 'Bəli' : 'Xeyr'}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </Col>
                                                        )
                                                    })
                                                     :  ' '
                                                }
                                            </Row>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </Col>
                        )
                    })
                }
            </Row>
            <div
                className="modalButtons"
                style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "40px",
                }}
            >
                <Button onClick={() =>{props.setvisibleView(false)}}>
                    {t("close")}
                </Button>
            </div>
        </div>
    );
}


export default View;
