import React, {useEffect, useState} from 'react';
import {Button, Form, Modal, Popconfirm, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {Table} from "antd";
import {convertColumns} from "../../../../../utils/columnconverter";
import {notify} from "../../../../../redux/actions";
import agros from "../../../../../const/api";
import {DeleteFilled, EditFilled, EyeFilled, FlagFilled, UsergroupAddOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import moment from "moment";
import CreateUser from "./CreateUser";

function ViewUser(props) {
    let {id , notify} = props;
    const [edit , setvisibleEdit] = useState(false);
    const [editing , setEditing] = useState(false);

    const [workers, setWorkers] = useState([]);
    const { t } = useTranslation();
    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "user", value: "Təhkim olunan", con: true },
        { key: "dateUsing", value: "Təhkim edilmə tarixi", con: true },
        { key: "status", value: "Status", con: true },
        { key: "index", value: "", con: false },
    ];


    const getData = () => {
        agros.get('TechniqueAttachment').then((res) => {
            setWorkers(res.data.find((s) => s.id === id).workers)
        })
    }

    useEffect(() => {
        getData()
    }, [id])


    const deleteWorker = (id) => {
        agros.delete(`TechniqueAttachment/${id}`).then((res) => {
           getData()
            notify('' , true)
        })
        .catch((err) => {
            notify(err.response.data  , false)
        })
    }


    const setEdit = (id) => {
        setEditing(id)
        setvisibleEdit(true)
        props.setVisibleUser(false)
    }

    const commonColumns = [
        {
            title: 'Təhkim olunan',
            key:  "name",
            dataIndex: "worker",
            render: (i) => {
                return (
                    <div>
                        {i.name}
                    </div>
                )
            }
        },
        {
            title: 'Təhkim edilmə tarixi',
            key: "dateUsing",
            dataIndex: "dateUsing",
            render: (i) => {
                return (
                    <div>
                        {moment(i.name).format('DD-MM-YYYY HH:mm')}
                    </div>
                )
            }
        },
        {
            title: "",
            key: 6,
            dataIndex: "id",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={t("edit")}>
                            <Button onClick={() => setEdit(i)} className="border-none" type="text" shape="circle">
                                <EditFilled />
                            </Button>
                        </Tooltip>
                        <Tooltip className="ml-5" title={t('delete')}>
                            <Popconfirm
                                placement="topRight"
                                title={t("areYouSure")}
                                onConfirm={() => deleteWorker(i)}
                                okText={t("yes")}
                                cancelText={t("no")}
                            >
                                <Tooltip className="ml-5" title={t("delete")}>
                                    <Button className="border-none" type="text" shape="circle">
                                        <DeleteFilled />
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];


    return (
        <div>
            {workers.length === 0 ?
                <div className='flex mt-10 flex-align-center dir-column'>
                    <h3 className='mb-15'>Texnika təhkim edilməyib</h3>
                    <Button onClick={()=>{
                        setvisibleEdit(true) ;
                        props.setVisibleUser(false);
                    }
                    } type={'primary'}>Təhkim et</Button>
                </div>:
                <div className={'w-100'}>
                    <Table
                        size="small"
                        className="bg-white"
                        dataSource={convertColumns(workers, cols)}
                        columns={commonColumns}
                        pagination={{
                            pageSize: 10,
                            current_page: 1,
                            pageSizeOptions: ["10", "15", "20", "25", "30"],
                        }}
                    />
                </div>
            }
            <div
                className="modalButtons"
                style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "40px",
                }}
            >
                <Button onClick={() =>{props.setVisibleUser(false)}}>
                    {t("close")}
                </Button>
                {workers.length < 2 &&
                    <Button
                        onClick={()=>{
                            setvisibleEdit(true);
                            props.setVisibleUser(false);
                        }}
                        type="primary" className="ml-10"
                    >
                        Təhkim et
                    </Button>
                }
            </div>




            <Modal
                title={'Təhkim et / Redaktə et'}
                centered
                visible={edit}
                onOk={() => setvisibleEdit(false)}
                onCancel={() => setvisibleEdit(false)}
                footer={null}
                className=" padModal"
            >
                <CreateUser
                    editing={editing}
                    setEditing={setEditing}
                    getData={getData} id={id} setVisibleUser={props.setVisibleUser} setvisibleEdit={setvisibleEdit}/>
            </Modal>

        </div>
    );
}



export default connect(null, { notify })(ViewUser);
