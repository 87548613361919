import React from "react";
import {Button, Col, Popconfirm, Table, Tooltip , Row} from "antd";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../../utils/columnconverter";
import agros from "../../../../../const/api";
import {CheckCircleFilled, FileTextOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {getOptions, notify} from "../../../../../redux/actions";
import moment from "moment";

const View = ({ demand , notify , setTrigger , trigger , setVisibleViewDemand}) => {
  const { t } = useTranslation();
  const cols = [
    {
      key: "parcel",
      value: <div>{t("area")} </div>,
      con: true,
    },
    { key: "demandType", value: t("demandType"), con: true },
    { key: "demandObject", value: t("demandObject"), con: true },
    { key: "amount", value: t("quantityOf"), con: true },
    { key: "country", value: t("countryOfOrigin"), con: true },
    { key: "requiredDate", value: t("dateMustBuy"), con: true },
    { key: "expirationDate", value: t("expirationDate"), con: true },
    { key: "requestingWorker", value: t("demandedPerson"), con: true },
    { key: "id", value: "", con: false },
  ];
  const modalColumns = [
    {
      title: 'Ehtiyat hissəsi',
      key: 1,
      dataIndex: "techniqueSparePart",
      render(i) {
        return <>{i.name}</>;
      }
    },
    {
      title: '1 ədədinin qiyməti',
      key: 1,
      dataIndex: "priceOfOne",
      render(i) {
        return <>{i} azn</>;
      }
    },
    {
      title: t("quantityOf"),
      key: 3,
      dataIndex: "count",
    },
    {
      title: "Vincode",
      key: 3,
      dataIndex: "vincode",
    },
    {
      title: "",
      key: 7,
      dataIndex: "sparePart",
      width: 20,
      render: (i) => {
        return (
            <div className="flex flex-end">
              <Popconfirm
                  placement="bottomRight"
                  title={'Mədaxil etmək istədiyinizə əminsiznizmi ?'}
                  onConfirm={async () =>
                      await agros.post(`TechnicalStock`, {
                        TechniqueSparePartPurchaseId: demand.id,
                        TechniqueSparePartsId: i.techniqueSparePart.id,
                        VIN: i.vincode,
                        PriceOfOne: i.priceOfOne,
                        Count: i.count,
                      }).then((res) => {
                        notify("", true);
                        setVisibleViewDemand(false)
                        setTrigger(++trigger)
                      })
                  }
                  okText={t("yes")}
                  cancelText={t("no")}
              >
                <Tooltip className="ml-5" title={'Anbara mədaxil et'}>
                  <Button className="border-none" type="text" shape="circle">
                    <CheckCircleFilled />
                  </Button>
                </Tooltip>
              </Popconfirm>
            </div>
        );
      },
    },
  ];

  const values = demand.sparePartList.map((sparePart) => {
    return {
      ...sparePart,
      sparePart:{
        ...sparePart
      }
    }
  })

  return (
      <div>
        <Row gutter={[16,16]}>
          <Col xs={12}>
            <table className="customtable">
              <tbody>
              <tr>
                <td>Təchizatçı</td>
                <td>{demand.customer}</td>
              </tr>
              <tr>
                <td>Nəqliyyat daxildir</td>
                <td>{demand.transportInclude ? 'Bəli': 'Xeyr'}</td>
              </tr>
              <tr>
                <td>Transport xərci</td>
                <td>{demand.transportCost} azn</td>
              </tr>
              <tr>
                <td>Ümumi xərc</td>
                <td>{demand.totalCost} azn</td>
              </tr>
              <tr>
                <td>Çatdırılma şərti</td>
                <td>{demand.deliveryTerm} </td>
              </tr>
              </tbody>
            </table>
          </Col>
          <Col xs={12}>
            <table className="customtable">
              <tbody>
              <tr>
                <td>Ödəniş şərti</td>
                <td>{demand.paymentTerm} </td>
              </tr>
              <tr>
                <td>Ödəniş növü</td>
                <td>{demand.paymentKind} </td>
              </tr>
              <tr>
                <td>Ödəniş müddəti</td>
                <td>{demand.paymentPeriod}</td>
              </tr>
              <tr>
                <td>Çatdırılma müddəti</td>
                <td>{moment(demand.paymentLastDate).format('DD-MM-YYYY')}</td>
              </tr>
              <tr>
                <td>Ödəniş son tarix</td>
                <td>{moment(demand.deliveryPeriod).format('DD-MM-YYYY')}</td>
              </tr>
              </tbody>
            </table>
          </Col>
        </Row>
        <Table
            size="small"
            className="bg-white w-100"
            columns={modalColumns}
            dataSource={convertColumns(values, cols)}
            pagination={{ pageSize: 10, current_page: 1 }}
        />
      </div>

  );
};



export default connect(null, {  notify })(View);

