import blacklogo from '../blacklogo.svg';
import whitelogo from '../whitelogo.svg'
import agrosWhite from  '../agrosWhite.png'
import list1 from '../list1.png'
import list2 from '../list2.png'
import list11 from '../list11.png'
import list12 from '../list12.png'
import list21 from '../list21.png'
import list31 from '../list31.png'
import list32 from '../list32.png'
import circle from '../circle.png'
import close from '../error.png'
import whiteTimesoft from '../whiteTime.png'
import products1 from  '../product1.png'
import products2 from  '../product2.png'
import products3 from  '../product3.png'
import blackTimesoft from '../timesoftLogo.png'
import fb from '../fb.svg';
import tw from  '../tw.svg';
import yt from  '../yt.svg'
import arrow from  '../arrow.svg'
import signIn from '../sign-in.png'
import product from '../product.png'
import menu from '../menu.png'
import bottomimg from  '../bottomimg.png'
import manatgreen from '../manatgreen.png'
import manatwhite from '../manatwhite.png'
import listshadow from '../listShadow.png'
import silo from '../silo.png'
import WareHouse from '../warehouse.png'
import Pool from '../pool.png'
import Horizontal from '../horizontal.svg'
import True from '../true.svg'
import False from '../false.svg'
import FlashOn from '../flash.svg'
import FlashOff from '../flash2.svg'
import Hot from '../hot.svg'
import Cold from '../cold.svg'
import WaterOn from '../waterOn.svg'
import WaterOff from '../waterOff.svg'
import Wind from '../wind.svg'
import Weather from '../weather.svg'
import WaterDrop from '../water-drop.svg'
import Land from '../land.svg'
import Leaf from '../leaf.svg'

export default  {
    bottomimg,
    products1,
    products2,
    products3,
    manatgreen,
    manatwhite,
    blacklogo,
    whitelogo,
    agrosWhite,
    circle,
    list1,
    signIn,
    list31,
    list32,
    menu,
    list2,
    list11,
    list12,
    list21,
    listshadow,
    close,
    whiteTimesoft,
    blackTimesoft,
    product,
    fb,
    tw,
    yt,
    arrow,
    silo,
    WareHouse,
    Pool,
    Horizontal,
    True,
    False,
    FlashOn,
    FlashOff,
    Hot,
    Cold,
    WaterOn,
    WaterOff,
    Wind,
    Weather,
    WaterDrop,
    Land,
    Leaf,
}
