import React, {useState} from 'react';
import {Button, Row, Col, Modal, Table, Tooltip, Form, Input , Select} from "antd";
import AddModal from "../Modals/AddModal";
import Edit from "../Modals/Edit";
import View from "../Modals/View";
import {convertColumns} from "../../../../../utils/columnconverter";
import {useTranslation} from "react-i18next";
import {EyeFilled, DeleteFilled } from "@ant-design/icons";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
// import Authorize from "../../../../Elements/Authorize";

const { Option } = Select;
const BasicTemplate = (props) => {
    const [edit , setvisibleEdit] = useState(false);
    const [view ,  setvisibleView] = useState(false);
    const [addModal , setVisibleAddModal] =  useState(false);
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "brand", value: "Markası", con: true },
        { key: "model", value: "Modeli", con: true },
        { key: "date", value: "Tarix", con: true },
        { key: "index", value: "", con: false },
    ];
    const commonColumns = [
        {
            title: "#",
            key: "index",
            dataIndex: "tableIndex",
        },
        {
            title: 'Markası',
            key:  "brand",
            dataIndex: "brand",
        },
        {
            title: 'Modeli',
            key: "model",
            dataIndex: "model",
        },
        {
            title: 'Tarix',
            key:  "date",
            dataIndex: "date",
        },
        {
            title: "",
            key: 6,
            dataIndex: "index",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={t("detailed")}>
                            <Button
                                onClick={() => setvisibleView(true)}
                                className="border-none"
                                type="text"
                                shape="circle"
                            >
                                <EyeFilled />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    const fakedata = [
        {
            tableIndex:'1',
            brand: 'Mercedes',
            model: 'yukdashiyan',
            date: '21.01.2021'
        }
    ]

    return (
        <div className="w-100">
            <Row gutter={16}>
                <Col lg={18} className='h-100'>
                    <Form layout="vertical" form={form}>
                        <div className="filter pr-1 pl-1 pt-1 border mt-15 mb-15 bg-white">
                            <Row gutter={[16 , 4]}>
                                <Col lg={5}>
                                    <Form.Item
                                        label={'Tarixə görə'}
                                        name="bydate"
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select
                                            // onChange={handleSectorChange}
                                        >
                                            <Option key={1}>Marka</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={5}>
                                    <Form.Item
                                        label={'Markasına görə'}
                                        name="byBrand"
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select
                                            // onChange={handleSectorChange}
                                        >
                                            <Option key={1}>Marka</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={5}>
                                    <Form.Item
                                        label={'Mdelinə görə'}
                                        name="model"
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select
                                            // onChange={handleSectorChange}
                                        >
                                            <Option key={1}>Marka</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={6}>
                                    <Form.Item
                                        label={'Dövlət nişanına görə'}
                                        name="byPlateNumber"
                                        validateTrigger="onChange"
                                        rules={[whiteSpace(t("inputError"))]}
                                    >
                                       <Input size={'large'}/>
                                    </Form.Item>
                                </Col>
                                <Col lg={3}>
                                    <Form.Item
                                        label={' '}
                                    >
                                       <Button type="primary" size={'large'} className="w-100  h-100" htmlType="submit">
                                           {t("search")}
                                       </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Col>
                <Col lg={6} className='h-100'>
                    <div className="border h-100 p-15  d-block mt-15 mb-15 bg-white flex all-center">
                        <Button onClick={()=>{setVisibleAddModal(true)}} className="mt-25 f-12 w-100 d-block mb-20" type={'primary'}>Yeni texniki servis</Button>
                    </div>
                </Col>
            </Row>
            <Table
                size="small"
                className="bg-white"
                dataSource={convertColumns(fakedata, cols)}
                columns={commonColumns}
                pagination={{
                    pageSize: 10,
                    current_page: 1,
                    pageSizeOptions: ["10", "15", "20", "25", "30"],
                }}
            />

            <Modal
                title={'Yeni texniki servis əlavə et'}
                centered
                visible={addModal}
                className="mediumModal padModal"
                onOk={() => setVisibleAddModal(false)}
                onCancel={() => setVisibleAddModal(false)}
                footer={null}
            >
                <AddModal setVisibleAddModal={setVisibleAddModal} />
            </Modal>

            <Modal
                title={'Redaktə et'}
                centered
                visible={edit}
                onOk={() => setvisibleEdit(false)}
                onCancel={() => setvisibleEdit(false)}
                footer={null}
                className="padModal"
            >
                <Edit setvisibleEdit={setvisibleEdit}/>
            </Modal>


            <Modal
                title={t('detailed')}
                centered
                visible={view}
                onOk={() => setvisibleView(false)}
                onCancel={() => setvisibleView(false)}
                footer={null}
                className="addTaskModal padModal"
            >
                <View setvisibleView={setvisibleView}/>
            </Modal>
        </div>
    );
}


export default BasicTemplate;
