import React from 'react';
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select} from "antd";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import {useTranslation} from "react-i18next";
const { Option } = Select;

function Edit(props) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const submitForm = () =>{
        console.log('super')
    }
    return (
        <div>
            <div className='w-100'>
                <Form onFinish={submitForm} layout="vertical" form={form}>
                    <div className="commontask">
                        <Row gutter={[16, 16]}>
                            <Col lg={24} md={24}>
                                <Form.Item
                                    label={'Dövlət nişanı'}
                                    name="platenumber"
                                    validateTrigger="onChange"
                                    rules={[whiteSpace(t("inputError"))]}
                                >
                                    <Input size={'large'}/>
                                </Form.Item>
                            </Col>
                            <Col lg={24} md={24}>
                                <Form.Item
                                    label={'Təhkim olunan'}
                                    name="type"
                                    validateTrigger="onChange"
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <Select
                                        // mode="multiple"
                                        size={'small'}
                                        // onChange={handleSectorChange}
                                    >
                                        <Option key={1}>Murad Nərimanlı</Option>
                                        <Option key={2}>Bəhruz Aydınlı</Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col lg={24} md={24}>
                                <div className="form-lang">
                                    <Form.Item
                                        label={'Moto saat'}
                                        name="motohour"
                                        validateTrigger="onChange"
                                        rules={[whiteSpace(t("inputError"))]}
                                    >
                                        <InputNumber/>
                                    </Form.Item>
                                    <div className="input-lang btm">km/saat</div>
                                </div>
                            </Col>
                        </Row>
                        <div
                            className="modalButtons"
                            style={{
                                position: "absolute",
                                bottom: "20px",
                                right: "40px",
                            }}
                        >
                            <Button onClick={() =>{props.setvisibleEdit(false)}}>
                                {t("cancel")}
                            </Button>
                            <Button type="primary" className="ml-10" htmlType="submit">
                                {t("save")}
                            </Button>
                        </div>
                    </div>
                </Form>
            </div>
        </div>
    );
}



export default Edit;
