import React from 'react';
import { Col, Row } from "antd";
import { BankOutlined, } from "@ant-design/icons";
import { Liquid} from '@ant-design/plots';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Images from "../../../../assets/img/Images/Images";

function Silo(props) {
    const percentage = 30;
    const config = {
        percent: 48 / 100,
        outline: {
            border: 1,
            color: 'F0EEEE',
            distance: 0,
            style: {
                stroke: '#F0EEEE',
                strokeOpacity: 0.7,
            },
        },
        wave: {
            length: 50,
        },
        theme: {
            styleSheet: {
                brandColor: '#00C2FF',
            },
        },
    };
    return (
        <div>
            <Col xs={24}>
                <div className="border animated fadeIn page-heading p-2 mt-0 bg-white">
                    <BankOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Silolar</span>
                </div>
            </Col>
            <Row className={'mt-15 '} gutter={[10, 10]}>
                <Col lg={12} className="mb-50" >
                    <div className="page-name">
                        <span className="f-20 bold">Silo 1</span>
                    </div>
                    <div className="card bg-white animated zoomIn">
                        <div className='flex flex-between mb-65'>
                            <div className="flex-column text-center">
                                <p>Məhsulun adı:</p>
                                <span>Buğda</span>
                                <div className='mt-25 mb-15'>
                                    <img src={Images.Horizontal} alt="" />
                                </div>
                                <div className='flex flex-align-baseline'>
                                    <p className='mr-5'>Tutumu :</p>
                                    <span>1000 T</span>
                                </div>
                            </div>
                            <div className="flex-column text-center">
                                <p>Məhsulun sortu:</p>
                                <span>Pitakor</span>
                                <Liquid className='liquid mt-10 mb-10' {...config} />
                                <div className='flex flex-align-baseline'>
                                    <p className='mr-5'>Nəmlik :</p>
                                    <span>{Math.round(config.percent * 100)} %</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-between mb-65'>
                            <div>
                                <CircularProgressbar className='mb-15' value={percentage} text={`${percentage} T`} />
                                <div className='flex flex-align-baseline'>
                                    <p className='mr-5'>Məhsulun miqdarı :</p>
                                    <span>{percentage}  T</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={12} className="mb-50" >
                    <div className="page-name">
                        <span className="f-20 bold">Silo 2</span>
                    </div>
                    <div className="card bg-white animated zoomIn">
                        <div className='flex flex-between mb-65'>
                            <div className="flex-column text-center">
                                <p>Məhsulun adı:</p>
                                <span>Buğda</span>
                                <div className='mt-25 mb-15'>
                                    <img src={Images.Horizontal} alt="" />
                                </div>
                                <div className='flex flex-align-baseline'>
                                    <p className='mr-5'>Tutumu :</p>
                                    <span>1000 T</span>
                                </div>
                            </div>
                            <div className="flex-column text-center">
                                <p>Məhsulun sortu:</p>
                                <span>Pitakor</span>
                                <Liquid className='liquid mt-10 mb-10' {...config} />
                                <div className='flex flex-align-baseline'>
                                    <p className='mr-5'>Nəmlik :</p>
                                    <span>{Math.round(config.percent * 100)} %</span>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-between mb-65'>
                            <div>
                                <CircularProgressbar className='mb-15' value={percentage} text={`${percentage} T`} />
                                <div className='flex flex-align-baseline'>
                                    <p className='mr-5'>Məhsulun miqdarı :</p>
                                    <span>{percentage}  T</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}


export default Silo;
