export const en = {
  // sidebar links
  admin: "Administrator",
  positions: "Positions",
  permissions: "Permissions",
  users: "Users",
  storageSettings: "Warehouse settings",
  areas: "Land parcels",
  typeAndConditions: "Types and conditions",
  clientAndConsumers: "Customer and consumers",
  todos: "Todos",
  humanResources: "Human resources",
  workers: "Workers",
  dependencyTable: "Dependency table",
  warehouse: "Warehouse",
  demands: "Demands",
  purchases: "Purchases",
  purchasesOnWait: "Purchases on wait",
  drugAndFertilizersWarehouse: "Drug and fertilizer warehouse",
  productWarehouse: "Crop warehouse",
  reserveWarehouse: "Reserves warehouse",
  history: "History",
  businessPlanning: "Work planning",
  daily: "Daily",
  yearly: "Annual",
  financeAdmin: "Financial management",
  operationTypes: "Operation types",
  paymentTypes: "Payment types",
  accountTypes: "Bank/Account types",
  areaNames: "Area names",
  customers: "Customers",
  operationPoints: "Operation points",
  sectors: "Sectors",
  sector: "Sector",
  dailyFinancialReports: "Daily financial reports",
  reports: "Reports",
  // bottom menu lang and settings
  langSelection: "Language selection",
  support: "Support",
  instruction: "Instructions of use",
  termsOfUse: "Terms of use",
  feedback: "Feedback",
  dakMode: "Dark mode",

  // validationMessages
  inputError: "The field is required",
  dateError: "The date is required",
  mailError: "Please enter correct email address",

  // common page headings
  name: "Name",
  fullName: "Fullname",
  fullnameLabel: "Name, surname, patronym",
  officalName: "Official name",
  phone: "Phone",
  contactNumber: "Contact number",
  country: "Country",
  city: "City",
  adress: "Address",
  industry: "Industry",
  relatedPerson: "Contact person",
  eMail: "Email",
  email: "Email",
  addTo: "Add",
  edit: "Edit",
  delete: "Delete",
  save: "Save",
  cancel: "Cancel",
  approved: "Approved",
  accepted: "Accepted",
  doConfirm: "Approve",
  canceled: "Cancelled",
  onWait: "On hold",
  close: "Close",
  type: "Type",
  typeOf: "Type",
  note: "Note",
  additionalNote: "Additional note",
  quantity: "Amount",
  quantityOf: "Amount",
  productName: "Crop name",
  product: "Crop",
  heading: "Heading",
  detailed: "See more",
  detailedInfo: "Detailed",
  selectDate: "Select a date",
  areYouSure: "Are you sure to delete?",
  yes: "Yes",
  no: "No",
  // positions page
  responsibility: "Responsibility",
  positionName: "Position name",

  // permissions page
  authorityGroups: "Permission groups",
  read: "View",
  write: "Write",
  editTo: "Edit",
  deleteTo: "Delete",

  readSectors: "View sectors",
  readCrops: "View crops",
  addCrop: "Add crop",
  deleteCrop: "Delete crop",
  updateCrop: "Update crop",

  hr: "Human resources",
  readSalaryHistory: "View salary history",
  createSalaryHistory: "Add to salary history",

  // userspage
  username: "Username",
  newPassword: "New password",
  worker: "Worker",
  positionGroup: "Position group",

  // storage-settings
  warehouses: "Warehouses",
  warehouseName: "Warehouse name",
  foodProducts: "Nutrition products",
  drugAndFertilizers: "Drug and fertilizers",
  drugAndFertilizer: "Drug and fertilizer",
  activeSubstance: "Main ingredient",
  activeSubstances: "Main ingredients",
  activeSubstanceName: "Main ingredient name",
  fertilizer: "Fertilizer",
  medicine: "Drug",
  kilograms: "Kilogram",
  meters: "Meters",
  liter: "Litres",
  pieces: "Piece",
  hundredliterRatio: "100l ratio",
  measurementUnit: "Measurement unit",
  plantProducts: "Plant products",
  plantProductsSorts: "Plant product sorts",
  reproduction: "Reproduction",
  reserves: "Reserves",
  reserv: "Reserve",
  reservName: "Reserve name",
  addReserve: "Add reserve",

  // areas page
  LandAreaRegulations: "Parcel settings",
  region: "Region",
  category: "Category",
  areaName: "Parcel name",
  area: "Parcel",
  treeNumber: "Tree count",
  respondentPeople: "Respondent people",
  respondent: "Respondent",
  company: "Company",
  position: "Position",
  createdDate: "Creation date",

  // typeAndConditions page
  paymentRules: "Payment instructions",
  deliveryTerms: "Delivery terms",

  // client-and-consumers
  allOf: "All",
  client: "Customer",
  clients: "Customers",
  consumer: "Consumers",
  agreementDocumentNumber: "Agreement document number",
  agreementDocumentDate: "Agreement document date",
  paymentTerm: "Payment term",
  paymentType: "Payment type",
  paymentTime: "Payment period",
  deliveryDate: "Delivery date",
  deliveryTern: "Delivery term",
  status: "Status",
  advancePaymentCondition: "Advance payment term",
  advanceCondition: "Advance condition",
  advancePaymentType: "Advance payment type",
  advancePaymentTime: "Advance payment period",

  // human-resources/workers
  workStartDate: "Work start date",
  totalSalary: "Total salary",
  salary: "Salary",
  salaryForm: "Salary form",
  salaryType: "Salary type",
  dailySalary: "Daily",
  salaryForWork: "Workbased",
  fin: "Fin code",
  seriaNo: "Serial №",
  birthdate: "Birthdate",
  sex: "Gender",

  // warehouse/demands
  demand: "Demand",
  demmandNo: "Demand №",
  demandType: "Demand type",
  demandObject: "Demand object",
  demandName: "Demand name",
  demandedPerson: "Demanding person",
  countryOfOrigin: "Production country",
  originCountry: "Production country",
  dateMustBuy: "Purchase date",
  expirationDate: "Expiration date",
  areaCategory: "Parcel category",

  // warehouse/purchases
  approveds: "Approved",
  preparedDocuments: "Documents on preparation",
  changeStatus: "Change status",
  dateToBuy: "Purchase date",
  endDate: "End date",
  startDate: "Start date",
  supplier: "Supplier",
  supplierConnection: "Supplier contact",
  DateOfApproval: "Date of approval",
  approvedPerson: "Approving person",
  createPurchaseDocument: "Create purchase document",
  createNewPurchaseDocument: "Create new purchase document",
  viewPurchaseDocument: "Open purchase document",
  customsCosts: "Custom costs",
  customsDuties: "Custom taxes",
  transportCosts: "Transportation costs",
  transportDuties: "Transportation taxes",
  mustDeliverDate: "Date of delivery",
  lastPaymentDate: "Last payment date",
  productType: "Crop type",
  productCategory: "Crop category",
  productSorts: "Crop sort",
  price: "Price",
  discount: "Discount",
  AdditionalTax: "Profit tax",
  finalPrice: "Final price",
  activityOf: "Activity",
  extraIncome: "Additional income",
  search: "Search",
  approximateTransportFee: "Approximate transportation fee",
  ApproximatePriceProducts: "Approximate crop value",

  // warehouse/purchases-on-wait
  addToReserves: "Add to reserves",
  barCode: "Barcode",
  // drug-and-fertilizers
  using: "Usage",
  documentNumber: "Document number",
  documents: "Documents",
  inReserves: "Reserves",
  import: "Import",
  export: "Export",
  tasks: "Tasks",
  task: "Task",
  operation: "Operation",
  mainIngridient: "Main ingredient",
  print: "Print",
  person: "Person",

  // warehouse/products
  sale: "Sale",
  sortOf: "Sort",
  barCodeOf: "Barcode",
  areaType: "Parcel type",
  handingOver: "Handing over",
  carNumber: "Car number",
  addNewProduct: "Add new crop",
  addProduct: "Add crop",
  acceptedPerson: "Receiving person",
  sendedPerson: "Sender",
  acceptedCarNumber: "Receiver car number",
  productQuantity: "Crop quantity",
  importExportDocuments: "Import/Export documents",

  // eservespage
  acceptedDate: "Date of acception",

  // historyPage
  operationHistory: "Operation history",
  buyer: "Buyer",

  // daily page
  dailyPlaning: "Daily workplan management",
  finishedPlans: "Finished workplans",
  WorkPlanName: "Workplan name",
  yearlyWorkPlan: "Annual workplan",
  totalArea: "Total area",
  workWillDo: "To do",
  manWorkerNumber: "Male worker count",
  womanWorkerNumber: "Female worker count",
  workerNumber: "Worker count",
  addtask: "Add task",
  addMedicine: "Add medicine",
  finalAmount: "Final amount",
  notStartedTasks: "Tasks to start",
  tasksOnDone: "On process tasks",
  finishedTasks: "Finished tasks",
  date: "Date",

  // yearly
  yearlyPlaning: "Annual workplan management",
  parsel: "Parcel",
  periods: "Periods",
  period: "Period",
  addSector: "Add a sector",
  addPeriod: "Add a period",
  workPlanWillDone: "Tasks plan",
  workWillDone: "To do",
  addWorkWillDonw: "Add a to do",
  operationItem: "Operation point",
  bankAccountName: "Bank/Account name",

  // new added words
  savedMessage: "Changes are saved",
  addPasswordError: "Insert a password",
  minimumPasswordError: "The password must contain at least 6 simvols",
  workerMustSelectError: "Employee must be selected",
  permissionMustSelectError: "Permission group must be selected",
  itemMustSelectError: "Ingredient must be selected",
  measurementUnitMustSelectError: "Measurement unit must be selected",
  typeMustBeSelected: "Type must be selected",
  positionMustSelectError: "Position must be selected",
  regionMustSelectError: "Region must be selected",
  categoryMustSelectError: "Category must be selected",
  selectCropSort: "Crop sort must be selected",
  selectCrop: "Crop must be selected",
  addNewArea: "Add new parcel",
  crops: "Crops",
  successMessage: "Operation was successfull",
  errMessage: "Operation was unsuccessfull",
  errorMessage: "An error occured",
  newDemandCreated: "New demand created.",
  newDocumentCreated: "New document created.",
  areaIsAdded: "New parcel is added.",
  importIsAdded: "New import recorded.",
  productIsAdded: "Product was added to the store",
  planIsAdded: "Plan is added.",
  clientIsAdded: "Customer is added.",
  clientDeleted: "Customer is deleted.",
  workerDeleted: "Employee was removed from the system.",
  workerIsAdded: "New employee is created.",
  addNewWorker: "Add new employee",
  sectorName: "Sector name",
  sectorArea: "Sector area",
  products: "Products",
  addNewClient: "Add new customer/supplier",
  operations: "Operations",
  salaryHistory: "Salary history",
  man: "Male",
  woman: "Female",
  createNewDemand: "Add new demand",
  moneyQuantity: "Amount",
  operationType: "Operation type",
  thereIs: "Existent",
  thereNo: "Non-existent",
  ApproximatecustomsCosts: "Approximate custom costs",
  barcodeMustAdd: "Barcode must be typed",
  warehouseMustSelect: "Warehouse must be selected",
  quantityMustAdd: "Amount must be typed",
  isUsed: "Used",
  isNotUsed: "Not used",
  newExoort: "New export",
  areasSector: "Sahənin sektoru",
  productMustSelect: "Product must be selected",
  areYouSureEndTask: "Do you want to finish the task?",
  endTask: "Finish the task",
  newReport: "New report",
  shortStory: "Short description",
  rejected: "Rejected",
  isEndsDate: "End date",
  createNewTask: "Craete a new task",
  settings: "Settings",
  notifications: "Notifications",
  login: "Login",
  register: "Register",
  activeWorkPlans: "Active workplans",
  createNewWorkPlan: "Create a new workplan",
  mustSelectWorkPlan: "Workplan must be selected",
  sectorMustSelect: "Sector must be selected",
  workMustSelect: "Task must be selected",
  workWillMustSelect: "To do must be selected",
  personMustSelect: "Person must be selected",
  numberMustAdd: "Count must be typed",
  medicines: "Medicines",
  personCount: "person",
  logOut: "Log out",
  password: "Password",
  EMailFull: "Email",

  // daily financial reports
  dailyreports: "Daily financial reports",
  report: "Report",

  // permissions
  administrator: "Administrator",
  readDemand: "View demand",
  create: "Create",
  update: "Update",
  deleteSector: "Delete sector",
  updateSector: "Update sector",
  warehouseSettings: "Warehouse settings",
  drugandFertilizers: "Drug and fertilizers",
  mainIngredients: "Main ingredients",
  cropCategories: "Crop categories",
  plantProductSorts: "Crop products sorts",
  reproductions: "Reproduction",
  typesAndConditions: "Type and conditions",
  landParcels: "Land parcels",
  paymentTerms: "Payment terms",
  paymentKinds: "Payment kinds",
  waiting: "Waiting",
  preparing: "Preparing",
  importAndExport: "Import and export",
  importExportAndDocs: "Import and export documents",
  excelExport: "Download as excel",
  productsWarehouse: "Products warehouse",
  reservesWarehouse: "Reserves warehouse",
  workplan: "Workplan",
  addTask: "Add task",
  doneWorkPlans: "Finished workplans",
  annual: "Annual",
  financeAdminstrator: "Financial administrator",

  links: {
    admin: "admin",
    positions: "positions",
    permissions: "permissions",
    users: "users",
    storageSettings: "storage-settings",
    areas: "areas",
    typeAndConditions: "type-and-conditions",
    clientAndConsumers: "client-and-consumers",
    todos: "todos",

    humanResources: "human-resources",
    workers: "workers",

    dependencyTable: "dependency-table",

    warehouse: "warehouse",
    demands: "demands",
    purchases: "purchases",
    purchasesOnWait: "purchases-on-wait",
    drugAndFertilizersWarehouse: "drug-and-fertilizers",
    productWarehouse: "products",
    reserveWarehouse: "reserves",
    history: "history",

    businessPlanning: "work-plan",
    daily: "daily",
    yearly: "yearly",

    financeAdmin: "finance",
    operationTypes: "operation-types",
    paymentTypes: "payment-types",
    accountTypes: "account-types",
    areaNames: "area-names",
    customers: "customers",
    operationPoints: "operation-points",
    sectors: "sectors",

    dailyFinancialReports: "daily-financial-reports",
    reports: "reports",
  },

  tools: "Tools",
  tool: "Tool",
  // layout pages
  // home
  slide1: 'Centralized management system of agro-parks.',
  subslide1: 'The purpose of this software is a system designed to manage agro-parks, allowing you to effectively manage your business and manage it more optimally.',

  slide2: 'Product of Azerbaijan.',
  subslide2: 'The purpose of the program is based on internal experience. The goal of our team is to create an Azerbaijani product. We are approaching this goal step by step.',

  slide3 : 'Constantly updated and adapted software assignment.',
  subslide3: 'The program is updated and adapted to the wishes of customers, we investigate and make the program more effective in order to eliminate the difficulties and problems of our customers in the agro-park..',

  layoutLinks: {
    home: 'HOME',
    about : 'ABOUT',
    products : 'PRODUCTS',
    packs : 'PACKS',
  },
  aboutCompany: 'Starting from 2018, TimeSoft is one of the IT companies operating in Azerbaijan. Our company\'s activity serves to automate and improve productivity, effectiveness and optimization of business and government management in various enterprises of Azerbaijan. To achieve this goal, by using the Information Technology effectively, time saving and a more efficient business process are achieved. We have signed a number of successful projects in this area in a short period of time and have implemented a number of state projects and we have developed a number of new platforms in our country.',
  aboutAgros: 'The Agros.az platform was established to evaluate agricultural products and show the mutual prices of products belonging to different farms. The platform will be useful for entrepreneurs and anyone interested in agriculture in general, giving the buyer the opportunity to choose and evaluate the prices of various products.',
  aboutAgros2: 'It will be a great help in managing the work of farmers and farmers operating in the field of agriculture. For example, an AgroPark facilitates all work by fully reflecting planting planning, work planning, irrigation systems, storage and resources, human resources and financial issues. By integrating other services such as Camera, Turnstile, Traffic Tracking, they will be able to plan and manage their areas and vehicles on the map.',


  aboutProduct:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui faucibus in ornare quam viverra. Sit amet aliquam id diam maecenas ultricies mi eget mauris. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend. Ac turpis egestas integer eget aliquet nibh praesent tristique. Nibh molestie a iaculis at erat pellentesque. Urna nunc id cursus metus aliquam eleifend mi. Consequat semper viverra nam libero justo. Gravida rutrum quisque non tellus orci ac. Diam donec adipiscing tristique risus nec feugiat in',
  aboutHr:'The Human Resources section stores the personal information of all employees. This module envisages the division of labor of employees, automation of salaries of employees working in the field. Also, statistical reports are reflected according to the database. Once employees are logged into the system, historical information about them is stored.',
  aboutManagement:'One of the main tests of the local park was field assignments. With the help of "Task Management" product, you can see the effectiveness of employee performance. In this module, you create an annual plan, and then create a daily plan based on the annual plan, and if completed, the person responsible for the task writes a report. In statistics, you can compare the annual plan, the daily plan, and the report after completing the task to see how many resources have been used. If resources are scarce, it makes the transition to procurement and warehousing processes.',
  aboutWarehouse:'This module shows all the procurement processes and warehousing processes of the agro-park. First there is a demand, then it goes to the purchase, and after confirmation the product is barcoded and goes to Anabra. There on the anabar page you can transfer medaxil, mexaric and.',
  // headings
  manegment:'Task management',
  // packs page
  garage: 'Garage',
  storage: 'Storage',
  user:'User',
  userCost:'User amount',
  storageCost:'Storage amount',
  packCost:'Package amount',
  additionalCost:'Additional amounts',
  finalCost:'Final amount',
  continue: 'Continue',
  // registration
  companyInfo: 'Company information',
  companyName: 'Company name',
  companyEmail:'Company email',
  remember:'Remember',
  entry: 'ENTRY',

};
