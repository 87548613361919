import React from 'react';
import {Col, Table, Row, Button, Tooltip, Form, Select, Input} from "antd";
import {useTranslation} from "react-i18next";
import {convertColumns} from "../../../../../utils/columnconverter";

function View(props) {
    const { t } = useTranslation();

    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "agregat", value: "İstifadə etdilən aqreqat", con: true },
        { key: "type", value: "Sürət", con: true },
        { key: "driver", value: "sərf olunan vaxt", con: true },
        { key: "plateNumber", value: "Sahə", con: true },
        { key: "plateNumber", value: "Sərf olunan yanacaq", con: true },
        { key: "index", value: "", con: false },
    ];
    const commonColumns = [
        {
            title: "#",
            key: "index",
            dataIndex: "tableIndex",
        },
        {
            title: 'İstifadə etdilən aqreqat',
            key:  "agregat",
            dataIndex: "agregat",
        },
        {
            title: 'Sürət',
            key: "Speed",
            dataIndex: "type",
        },
        {
            title: 'sərf olunan vaxt',
            key:  "date",
            dataIndex: "driver",
        },
        {
            title: 'Sahə',
            key: "area",
            dataIndex: "plateNumber",
        },
        {
            title: 'Sərf olunan yanacaq',
            key: "fuel",
            dataIndex: "plateNumber",
        },
    ];

    const fakedata = [

    ]


    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                    <table className="customtable">
                        <thead>
                           <tr>
                               <th className={'text-center border-right'} rowSpan={'2'}>Tarix</th>
                               <th className='text-center' colSpan={'3'}>Benzin</th>
                           </tr>
                           <tr>
                               <th className='border-right'>Normativin hesabladığı</th>
                               <th className='border-right'>Planda verilən </th>
                               <th>Faktiki olan </th>
                           </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='text-center border-right'>12.121.2</td>
                                <td className='border-right'>12l</td>
                                <td className='border-right'>19l</td>
                                <td className='text-left'>12l</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            <div
                className="modalButtons"
                style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "40px",
                }}
            >
                <Button onClick={() =>{props.setvisibleView(false)}}>
                    {t("close")}
                </Button>
            </div>
        </div>
    );
}


export default View;
