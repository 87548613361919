import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Switch,
  Col,
  Card,
  Table,
  Button,
  Form,
  Tooltip,
  Input,
  Popconfirm, InputNumber,
} from "antd";
import {
  UnorderedListOutlined,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../utils/columnconverter";
// notification
import { notify } from "../../../../redux/actions";
import { connect } from "react-redux";
import agros from "../../../../const/api";
import { useTranslation } from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../utils/rules";

const Silos = (props) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const mainUrl ='/silo'
  const [positions, setPositions] = useState([]);
  const [responsible, setResponsible] = useState(false);
  const [editing, setEditing] = useState(null);
  const cols = [
    { key: "index", value: "#", con: true },
    { key: "name", value: t("name"), con: true },
    { key: "id", value: "", con: false },
  ];
  const nameInput = useRef();

  // props
  const { notify } = props;

  const columns = [
    {
      title: "#",
      key: "1",
      dataIndex: "index",
      width: 80,
    },
    {
      title: t("name"),
      key: "2",
      dataIndex: "name",
    },
    {
      title: 'Həcmi',
      key: "4",
      dataIndex: "capacity",
    },
    {
      title: "",
      key: "3",
      dataIndex: "id",
      width: 30,
      render: (i) => {
        return (
            <div className="flex flex-end">
                <Popconfirm
                    placement="topRight"
                    title={t("areYouSure")}
                    onConfirm={() => deletePosition(i)}
                    okText={t("yes")}
                    cancelText={t("no")}
                >
                  <Tooltip className="ml-5" title={t("delete")}>
                    <Button className="border-none" type="text" shape="circle">
                      <DeleteFilled />
                    </Button>
                  </Tooltip>
                </Popconfirm>

                <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                  <Button
                      className="border-none"
                      type="text"
                      shape="circle"
                      onClick={() => setEditingObject(i)}
                  >
                    <EditFilled />
                  </Button>
                </Tooltip>
            </div>
        );
      },
    },
  ];

  const setEditingObject = async (i) => {
    setEditing(i);
    await agros.get(`${mainUrl}/${i}`).then((res) => {
      form.setFieldsValue({...res.data});
    });
  };

  const cancelEditing = () => {
    setEditing(null);
    setResponsible(false);
    form.resetFields()
  };

  const deletePosition = async (i) => {
    await agros
        .delete(`${mainUrl}/${i}`)
        .then(() => {
          // description
          notify("", true);
          getPositions();
        })
        .catch((err) => {
          //error
          notify(err.response, false);
        });
  };

  const savePosition = async (values) => {
    let langs = ["az", "en", "ru"];
    let obj = {
     ...values
    };
    if (!editing) {
      await agros
          .post(mainUrl, obj)
          .then((res) => {
            notify("", true);
            getPositions();
            cancelEditing();
          })
          .catch((err) => {
            notify(err.response, false);
          });
    } else {
      obj["id"] = editing;
      await agros
          .put(`${mainUrl}/${editing}`, obj)
          .then((res) => {
            notify("", true);
            getPositions();
            cancelEditing();
          })
          .catch((err) => {
            notify(err.response, false);
          });
    }
  };

  const getPositions = async () => {
    await agros.get(mainUrl).then((res) => {
      setPositions(
          res.data.map((p, index) => {
            return { key: index + 1, ...p, index: index + 1 , capacity: p.capacity + ' kq' };
          })
      );
    });
  };

  useEffect(() => {
    getPositions();
  }, [t]);


  return (
      <Row gutter={[10, 10]}>
        <Col xs={24}>
          <div className="border p-2 mt-0 bg-white">
            <UnorderedListOutlined className="f-20 mr5-15" />
            <span className="f-20 bold">{t("positions")}</span>
          </div>
        </Col>
        <Col lg={12} xs={24}>
          <Table
              size="small"
              className="bg-white"
              columns={columns}
              dataSource={convertColumns(positions, cols)}
              pagination={{
                pageSize: 10,
                current_page: 1,
                total: positions.length,
              }}
          />
        </Col>
        <Col lg={12} xs={24}>
          <Card title={t("addTo")}>
            <Form layout="vertical" onFinish={savePosition} form={form}>
              <p className="mb-5">{t("name")}</p>
              <div className="form-lang">
                <Form.Item
                    className="mb-5"
                    validateTrigger="onChange"
                    name="name"
                    rules={[whiteSpace(t("inputError"))]}
                >
                  <Input ref={nameInput} />
                </Form.Item>
              </div>

              <div className="form-lang">
                <Form.Item
                    className="mb-5"
                    validateTrigger="onChange"
                    name="capacity"
                    rules={[noWhitespace(t("inputError"))]}
                >
                  <InputNumber />
                </Form.Item>
                <div className="input-lang">KQ</div>
              </div>


              <div className="flex  flex-between mt-15">
                <Button onClick={cancelEditing}>{t("cancel")}</Button>
                  <Button htmlType="submit">{t("save")}</Button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
  );
};

export default connect(null, { notify })(Silos);
