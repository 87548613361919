import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { useTranslation } from "react-i18next";
import agros from "../../../../const/api";
import moment from "moment";

const View = (props) => {
  const { t } = useTranslation();
  const [task, setTask] = useState(null);
  const [alltasks , setAllTasks] = useState([])
  useEffect(() => {
    agros.get("workplan/task/" + props.task).then((res) => {
      setTask(res.data[0]);
      setAllTasks(res.data);
    });
  }, [props.task]);
  return (alltasks && task ) != null ? (
    <>
      {alltasks.map((task , i)=> (
          <div key={i} className={'fadeIn animated'}>
            <Row>
              <Col xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">{t("heading")}</td>
                    <td>{task.toDoName}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">Cəmi sahə</td>
                    <td>
                      {task.sectorsArea} ha
                    </td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">{t("status")}</td>
                    <td>
                      <span className="text-primary">{task.workStatus}</span>{" "}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              <Col xs={12}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td className="bold">{t("shortStory")}</td>
                    <td>{task.description}</td>
                  </tr>
                  </tbody>
                </table>
              </Col>
              {task.workPlanReports.length ?
              <Col xs={24}>
                <table className="customtable">
                  <tbody>
                  <tr>
                    <td>Hesabatın qeydləri:</td>
                    <td>{task.workPlanReports[0].description}</td>
                  </tr>
                  </tbody>
                </table>
              </Col> : null
              }
            </Row>
            <div className="border mt-15 p-1">
              <Row gutter={[16, 16]}>
                <Col  md={task.workPlanReports.length ? 8 : 12} xs={24}>
                  <div className="flex p-1 flex-between">
                    <p>{t("startDate")}</p>
                    <Button shape="round" type="primary" size="small">
                      {moment(task.startDate).format("DD-MM-YYYY")}
                    </Button>
                  </div>
                </Col>
                <Col md={task.workPlanReports.length ? 8 : 12}  xs={24}>
                  <div className="flex p-1 flex-between">
                    <p>{t("endDate")}</p>
                    <Button shape="round" type="primary" size="small">
                      {moment(task.endDate).format("DD-MM-YYYY")}
                    </Button>
                  </div>
                </Col>

                {task.workPlanReports.length ?
                <Col md={8}  xs={24}>
                  <div className="flex p-1 flex-between">
                    <p>Hesabat tarixi</p>
                    <Button shape="round" type="primary" size="small">
                      {moment(task.workPlanReports[0].reportDate).format("DD-MM-YYYY")}
                    </Button>
                  </div>
                </Col> : null
                }

                <Col md={task.workPlanReports.length ? 12 : 24} xs={24}>
                 <div className="p-1 border">
                   <p className={'mb-10 font-weight-bold'}>Planlamada</p>
                   <table className="customtable">
                     <tbody>
                     <tr>
                       <td>{t("manWorkerNumber")}:</td>
                       <td>
                         {task.manWorkerCount} {t("personCount")}
                       </td>
                     </tr>
                     <tr>
                       <td>{t("womanWorkerNumber")}:</td>
                       <td>
                         {task.womanWorkerCount} {t("personCount")}
                       </td>
                     </tr>
                     </tbody>
                   </table>
                 </div>
                </Col>
                {task.workPlanReports.length ?
                <Col md={12} xs={24}>
                  <div className="p-1 border">
                    <p className={'mb-10 font-weight-bold'}>Hesabatda</p>
                    <table className="customtable">
                      <tbody>
                      <tr>
                        <td>{t("manWorkerNumber")}:</td>
                        <td>
                          {task.workPlanReports[0].manCount} {t("personCount")}
                        </td>
                      </tr>
                      <tr>
                        <td>{t("womanWorkerNumber")}:</td>
                        <td>
                          {task.workPlanReports[0].womanCount} {t("personCount")}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
                  : null
                }

                 <Col xs={24}>
                   <div className="p-1 border">
                     <h3 className={'mb-10 font-weight-bold'}>İş planlamasında</h3>
                     <Row gutter={[16,16]}>
                       {task.workPlanTaskSectors?.length ? (
                           <Col span={24}>
                             <div className={'p-1 w-100 border'}>
                               <p className="font-weight-bold mb-10">{t('sectors')} </p>
                               {task.workPlanTaskSectors.map((s , i)=>{
                                 return <div className="p-1 border">
                                   <p className="font-weight-bold mb-10">{t('sector') + ' ' + (i + 1)} </p>
                                   <Row gutter={16}>
                                     <Col span={8}>
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">Sahə kateqoriyası:</td>
                                           <td>{s.parcelCategory}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                     <Col span={8}>
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">Sahə:</td>
                                           <td>{s.parcel}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                     <Col span={8} >
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">
                                             Sektor
                                           </td>
                                           <td>{s.sectorName}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                   </Row>
                                 </div>
                               })}
                             </div>
                           </Col>
                       ) : null}
                       {task.workPlanTaskFertilizers?.length ? (
                           <Col span={24}>
                             <div className={'p-1 w-100 border'}>
                               <p className="font-weight-bold mb-10">{t('drugandFertilizers')} </p>
                               {task.workPlanTaskFertilizers.map((f , i)=>{
                                 return <div key={i} className="p-1 border">
                                   <p className="font-weight-bold mb-10">{t('drugAndFertilizer') + ' ' + (i + 1)} </p>
                                   <Row gutter={16}>
                                     <Col span={4} >
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">Məhsul:</td>
                                           <td>{f.name}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                     <Col span={4} >
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">Növü:</td>
                                           <td>{f.fertilizerKind}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                     <Col span={6} >
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">Əsas inqredient:</td>
                                           <td>{f.mainIngredient}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                     <Col span={6} >
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">100l nisbəti:</td>
                                           <td>{f.quantityPerHundred}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                     <Col span={4} >
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td className="bold">Miqdar:</td>
                                           <td>
                                             {f.quantity} {f.measurementUnit}
                                           </td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </Col>
                                   </Row>
                                 </div>
                               })}
                             </div>

                           </Col>
                       ) : null}
                       {task.workPlanTaskCrops?.length ? (
                           <Col span={24}>
                             {/* <p className="mt-10 mb-10">Məhsullar</p> */}
                             <div className={'p-1 w-100 border'}>
                               <p className="font-weight-bold mb-10">{t('Məhsullar')} </p>
                               {task.workPlanTaskCrops.map((f, findex) => {
                                 return (
                                     <div className={'p-1 border'} key={findex}>
                                       <p className="font-weight-bold mb-10">{t('Məhsul') + ' ' + (i + 1)} </p>
                                       <Row gutter={16}>
                                         <Col span={6} key={findex}>
                                           <table className="customtable">
                                             <tbody>
                                             <tr>
                                               <td className="bold">Kateqoriya:</td>
                                               <td>{f.cropCategory}</td>
                                             </tr>
                                             </tbody>
                                           </table>
                                         </Col>
                                         <Col span={6} key={findex}>
                                           <table className="customtable">
                                             <tbody>
                                             <tr>
                                               <td className="bold">Məhsul:</td>
                                               <td>{f.crop}</td>
                                             </tr>
                                             </tbody>
                                           </table>
                                         </Col>
                                         <Col span={6} key={findex}>
                                           <table className="customtable">
                                             <tbody>
                                             <tr>
                                               <td className="bold">Sort:</td>
                                               <td>{f.name}</td>
                                             </tr>
                                             </tbody>
                                           </table>
                                         </Col>
                                         <Col span={6} key={findex}>
                                           <table className="customtable">
                                             <tbody>
                                             <tr>
                                               <td className="bold">Miqdar:</td>
                                               <td>
                                                 {f.quantity} {f.measurementUnit}
                                               </td>
                                             </tr>
                                             </tbody>
                                           </table>
                                         </Col>
                                       </Row>
                                     </div>
                                 );
                               })}
                             </div>
                           </Col>
                       ) : null}

                       {task.workPlanTaskTools?.length ? (
                           <Col md={12} xs={24}>
                             <div className={'p-1 w-100 border'}>
                               <p className="font-weight-bold mb-10">{t('Alətlər')} </p>
                               {task.workPlanTaskTools.map((f, findex) => {
                                 return (
                                     <div className={'border p-1'} key={findex}>
                                       <p className="font-weight-bold mb-10">{t('Alət') + ' ' + (i + 1)} </p>
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td>{f.name}</td>
                                           <td>{f.quantity} ədəd</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </div>
                                 );
                               })}
                             </div>
                           </Col>
                       ) : null}

                       {task.workPlanTaskReserves?.length ? (
                           <Col md={12} xs={24}>
                             <div className={'p-1 w-100 border'}>
                               <p className="font-weight-bold mb-10">{t('reserves')} </p>
                               {task.workPlanTaskReserves.map((f, findex) => {
                                 return (
                                     <div className={'border p-1'} key={findex}>
                                       <p className="font-weight-bold mb-10">{t('Ehtiyat') + ' ' + (i + 1)} </p>
                                       <table className="customtable">
                                         <tbody>
                                         <tr>
                                           <td>{f.name}</td>
                                           <td>{f.quantity} {f.measurementUnit}</td>
                                         </tr>
                                         </tbody>
                                       </table>
                                     </div>
                                 );
                               })}
                             </div>
                           </Col>
                       ) : null}
                     </Row>
                   </div>
                 </Col>
                {task.workPlanReports.length ?
                    <Col xs={24}>
                       <div className="p-1 border">
                         <h3 className={'mb-10 font-weight-bold'}>Hesabatda</h3>
                         <Row gutter={[16,16]}>
                        {task.workPlanReports[0].workPlanSectors?.length ? (
                            <Col span={24}>
                              <div className={'p-1 w-100 border'}>
                                <p className="font-weight-bold mb-10">{t('sectors')} </p>
                                {task.workPlanReports[0].workPlanSectors.map((s , i)=>{
                                  return <div className="p-1 border">
                                    <p className="font-weight-bold mb-10">{t('sector') + ' ' + (i + 1)} </p>
                                    <Row gutter={16}>
                                      <Col span={8}>
                                        <table className="customtable">
                                          <tbody>
                                          <tr>
                                            <td className="bold">Sahə kateqoriyası:</td>
                                            <td>{s.parcelCategory}</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </Col>
                                      <Col span={8}>
                                        <table className="customtable">
                                          <tbody>
                                          <tr>
                                            <td className="bold">Sahə:</td>
                                            <td>{s.parcel}</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </Col>
                                      <Col span={8} >
                                        <table className="customtable">
                                          <tbody>
                                          <tr>
                                            <td className="bold">
                                              Sektor
                                            </td>
                                            <td>{s.sectorName}</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </Col>
                                      <Col span={8}>
                                        <table className="customtable">
                                          <tbody>
                                          <tr>
                                            <td className="bold">Sektorun işlənmiş sahəsi:</td>
                                            <td>{s.workingArea} ha</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </Col>
                                    </Row>
                                  </div>
                                })}
                              </div>
                            </Col>
                        ) : null}
                        {task.workPlanReports[0].workPlanFertilizer?.length ? (
                            <Col span={24}>
                              <div className={'p-1 w-100 border'}>
                                <p className="font-weight-bold mb-10">{t('drugandFertilizers')} </p>
                                {task.workPlanReports[0].workPlanFertilizer.map((f , i)=>{
                                  return <div key={i} className="p-1 border">
                                    <p className="font-weight-bold mb-10">{t('drugAndFertilizer') + ' ' + (i + 1)} </p>
                                    <Row gutter={16}>
                                      <Col span={4} >
                                        <table className="customtable">
                                          <tbody>
                                          <tr>
                                            <td className="bold">Məhsul:</td>
                                            <td>{f.name}</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </Col>
                                      <Col span={4} >
                                        <table className="customtable">
                                          <tbody>
                                          <tr>
                                            <td className="bold">Növü:</td>
                                            <td>{f.fertilizerKind}</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </Col>
                                      <Col span={6} >
                                        <table className="customtable">
                                          <tbody>
                                          <tr>
                                            <td className="bold">Əsas inqredient:</td>
                                            <td>{f.mainIngredient}</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </Col>
                                      <Col span={6} >
                                        <table className="customtable">
                                          <tbody>
                                          <tr>
                                            <td className="bold">100l nisbəti:</td>
                                            <td>{f.quantityPerHundred}</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </Col>
                                      <Col span={4} >
                                        <table className="customtable">
                                          <tbody>
                                          <tr>
                                            <td className="bold">Miqdar:</td>
                                            <td>
                                              {f.quantity} {f.measurementUnit}
                                            </td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </Col>
                                    </Row>
                                  </div>
                                })}
                              </div>

                            </Col>
                        ) : null}
                        {task.workPlanReports[0].workPlanTaskCrops?.length ? (
                            <Col span={24}>
                              {/* <p className="mt-10 mb-10">Məhsullar</p> */}
                              <div className={'p-1 w-100 border'}>
                                <p className="font-weight-bold mb-10">{t('Məhsullar')} </p>
                                {task.workPlanReports[0].workPlanTaskCrops.map((f, findex) => {
                                  return (
                                      <div className={'p-1 border'} key={findex}>
                                        <p className="font-weight-bold mb-10">{t('Məhsul') + ' ' + (i + 1)} </p>
                                        <Row gutter={16}>
                                          <Col span={6} key={findex}>
                                            <table className="customtable">
                                              <tbody>
                                              <tr>
                                                <td className="bold">Kateqoriya:</td>
                                                <td>{f.cropCategory}</td>
                                              </tr>
                                              </tbody>
                                            </table>
                                          </Col>
                                          <Col span={6} key={findex}>
                                            <table className="customtable">
                                              <tbody>
                                              <tr>
                                                <td className="bold">Məhsul:</td>
                                                <td>{f.crop}</td>
                                              </tr>
                                              </tbody>
                                            </table>
                                          </Col>
                                          <Col span={6} key={findex}>
                                            <table className="customtable">
                                              <tbody>
                                              <tr>
                                                <td className="bold">Sort:</td>
                                                <td>{f.name}</td>
                                              </tr>
                                              </tbody>
                                            </table>
                                          </Col>
                                          <Col span={6} key={findex}>
                                            <table className="customtable">
                                              <tbody>
                                              <tr>
                                                <td className="bold">Miqdar:</td>
                                                <td>
                                                  {f.quantity} {f.measurementUnit}
                                                </td>
                                              </tr>
                                              </tbody>
                                            </table>
                                          </Col>
                                        </Row>
                                      </div>
                                  );
                                })}
                              </div>
                            </Col>
                        ) : null}

                        {task.workPlanReports[0].workPlanTaskTools?.length ? (
                            <Col md={12} xs={24}>
                              <div className={'p-1 w-100 border'}>
                                <p className="font-weight-bold mb-10">{t('Alətlər')} </p>
                                {task.workPlanReports[0].workPlanTaskTools.map((f, findex) => {
                                  return (
                                      <div className={'border p-1'} key={findex}>
                                        <p className="font-weight-bold mb-10">{t('Alət') + ' ' + (i + 1)} </p>
                                        <table className="customtable">
                                          <tbody>
                                          <tr>
                                            <td>{f.name}</td>
                                            <td>{f.quantity} ədəd</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                  );
                                })}
                              </div>
                            </Col>
                        ) : null}

                        {task.workPlanReports[0].workPlanTaskReserves?.length ? (
                            <Col md={12} xs={24}>
                              <div className={'p-1 w-100 border'}>
                                <p className="font-weight-bold mb-10">{t('reserves')} </p>
                                {task.workPlanTaskReserves.map((f, findex) => {
                                  return (
                                      <div className={'border p-1'} key={findex}>
                                        <p className="font-weight-bold mb-10">{t('Ehtiyat') + ' ' + (i + 1)} </p>
                                        <table className="customtable">
                                          <tbody>
                                          <tr>
                                            <td>{f.name}</td>
                                            <td>{f.quantity} {f.measurementUnit}</td>
                                          </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                  );
                                })}
                              </div>
                            </Col>
                        ) : null}
                      </Row>
                       </div>
                    </Col>
                    : null}

              </Row>
            </div>
          </div>
      ))}
    </>
  ) : null;
};

export default View;
