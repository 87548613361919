import React, { useState, useEffect } from "react";
import {Button, Col, Input, InputNumber, Row, Table, Form, Select} from "antd";
import moment from "moment";
import agros from "../../../../../const/api";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../../utils/columnconverter";
import { getOptions, notify } from "../../../../../redux/actions";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import {ClearOutlined} from "@ant-design/icons";

const { Option } = Select;

const Edit = (props) => {
    const { t,i18n } = useTranslation();
    const { getOptions } = props;
    const [selectedRowKeys , setSelectedRowKeys] = useState([])
    const [selectedRows , setSelectedRows] = useState([])

    const rowSelection = {
        type: props.modalType === 'export' ? 'checkbox' : 'radio',
        onChange: (selectedRowKeys, selectedRows) =>{
            // inputChange()
            setStock(selectedRows , selectedRowKeys);
        },
        // onSelect: ()=>{inputChange()},
        selectedRowKeys,
        preserveSelectedRowKeys:true,
        hideSelectAll:true,
    }

    const options = props.options[props.lang];
    const cols = [
        { key: "productName", value: t("name"), con: true },
        { key: "fertilizerKind", value: t("typeOf"), con: true },
        { key: "mainIngredient", value: t("activeSubstance"), con: true },
        { key: "quantity", value: t("quantityOf"), con: true },
        { key: "price", value: t("price"), con: true },
        { key: "documentNumber", value: t("documentNumber"), con: true },
        { key: "wareHouse", value: t("warehouse"), con: true },
        { key: "quantity", value: t("quantity"), con: true },
        { key: "operation", value: t("operation"), con: true },
        { key: "productName", value: t("productName"), con: true },
        { key: "takenPerson", value: t("person"), con: true },
        { key: "expireDate", value: t("expirationDate"), con: false },
        { key: "usedStatus", value: "", con: false },
        { key: "index", value: "", con: false },
    ];

    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const { notify , workPlanId} = props;
    const [data, setData] = useState([]);
    const [stockId, setStockId] = useState(null);
    const [wareHouseId, setWarehouseId] = useState(null);
    const [unit , setUnit] = useState('')
    const [vals, setVals] = useState({});


    const handleKeyChange = (e, key) => {
        const all = { ...vals };
        all[key] = e;
        setVals(all);
    };

    const setStock = (selectedRows, selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys)
        if (props.modalType !== 'export'){
           setStockId(selectedRows[0].id);
           setWarehouseId(selectedRows[0].wareHouseId);
           form.setFieldsValue({
               quantity:selectedRows[0].quantitNumber,
               WarehouseId:selectedRows[0].wareHouseId
           })
           setUnit(selectedRows[0].measurementUnit)
       }
       else {
           setSelectedRows(selectedRows)
       }
    };


    useEffect(() => {
        form.resetFields()
        setSelectedRowKeys([])
        setSelectedRows([])
        setData([])
        form2.resetFields()
        getOptions(
            [
                "fertilizers",
                "fertilizerKinds",
                "mainIngredients",
                "customers",
                "reserves",
                "warehouses",
                "parcelCategories",
                "parcels",
                "parcelSectors",
            ],
            props.options,
            i18n.language
        );
        if (props.modalType === 'export' && props.id !== -1){
            form.setFieldsValue({
                parcelCategoryId:props.parcelCategory,
                parcelId: props.parcel,
                ParcelSectorIds: props.parcelSectors
            })
        }
        if (props.id !== -1){
            getData(props.id)
        }
        else{
            setData([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t , props.id , props.visibleEdit , props.modalType]);


    const getData =  (id) =>{
        agros.get(`ReserveStock/${id}`).then((res) => {
            let data  = res.data
            if (props.modalType === 'export'){
                data = res.data.filter((d) => {return d.quantity !== 0})
                console.log(data)
            }
            console.log(res.data)
            setData(
                data.map((r, index) => {
                    return { ...r, key: index + 1, index, tableIndex: index + 1 ,
                        quantitNumber: r.quantity ,
                        quantity:
                            r.quantity + (r.measurementUnit ? " " + r.measurementUnit : null),
                        expireDate:
                            r.expireDate && moment(r.expireDate).format("DD-MM-YYYY"),
                        documentNumber: r.documentNumber !== null ? r.documentNumber : "-",
                    };
                })
            )
        });
    }

    // table data columns
    const columns = [
        {
            title: t("name"),
            key: 1,
            dataIndex: "productName",
        },
        // {
        //     title: t("typeOf"),
        //     key: 2,
        //     dataIndex: "fertilizerKind",
        // },
        // {
        //     title: t("activeSubstance"),
        //     key: 3,
        //     dataIndex: "mainIngredient",
        // },
        {
            title: t("quantityOf"),
            key: 4,
            dataIndex: "quantity",
        },
        // {
        //     title: t("expirationDate"),
        //     key: 5,
        //     dataIndex: "expireDate",
        // },
        {
            title: t("price"),
            key: 6,
            dataIndex: "price",
        },
        {
            title: t("finalPrice"),
            key: 6,
            dataIndex: "totalPrice",
        },
        {
            title: t("warehouse"),
            key: 7,
            dataIndex: "wareHouse",
        },
    ];

    const onSearch = (values) =>{
        getData(values.ProductId)
    }

    const saveExport = (values) => {
       if (props.modalType === 'edit'){
           if (stockId) {
               agros
                   .put(`ReserveStock/${stockId}`, { ...values, stockId })
                   .then((res) => {
                       notify("", true);
                       props.setVisibleEdit(false);
                       props.triggerFetch();
                   })
                   .catch((err) => {
                       notify(err.response, false);
                   });
           } else {
               notify("", false);
           }
       }
       if (props.modalType === 'transfer'){
           agros
               .post(     `ReserveStock/Transfer`, { ...values, stockId })
               .then((res) => {
                   notify("", true);
                   props.setVisibleEdit(false);
                   props.triggerFetch();
               })
               .catch((err) => {
                   notify(err.response, false);
               });
       }
       if (props.modalType === 'export'){
           let obj = {
               ...values,
               ParcelSectorIds : values.ParcelSectorIds ? values.ParcelSectorIds : [],
               workPlanId
           }

           agros
               .post("ReserveStock/export", obj)
               .then((res) => {
                   notify("", true);
                   props.setVisibleEdit(false);
                   // props.triggerFetch();
               })
               .catch((err) => {
                   notify(err.response, false);
               });

       }
    };

    return (
        <>
            <Row>
                {props.id === -1 &&
                  <Col span={24}>
                    <Form onFinish={onSearch} layout="vertical" form={form2}>
                        <div className="commontask bg-white">
                            <Row gutter={[16, 16]}>
                                <Col md={12} sm={12} xs={24}>
                                    <Form.Item
                                        name={'ProductId'}
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select
                                            placeholder={t('product')}
                                            showSearch
                                            notFoundContent={null}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            filterSort={(optionA, optionB) =>
                                                optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                            }
                                        >
                                            {options.reserves.map((c, index) => {
                                                return (
                                                    <Option key={index} value={c.id}>
                                                        {c.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>



                                <Col md={2} sm={12} xs={24}>
                                    <Form.Item
                                        name={" "}
                                    >
                                        <Button type="primary" size={'large'}  className="w-100 f-13" htmlType="submit">
                                            {t("search")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Col>
                }

                <Col span={24}>
                    <Table
                        size="small"
                        className="w-100"
                        columns={columns}
                        rowSelection={rowSelection}
                        dataSource={convertColumns(data, cols)}
                        pagination={{ pageSize: 5, current_page: 1 }}
                    />
                </Col>
            </Row>

            <Form form={form} layout="vertical" onFinish={saveExport}>
                <div>
                    <Row gutter={[16, 8]}>
                        {props.modalType === 'export' &&
                            <>
                                <h3 className="red mb-5">Tələb olunan miqdar  {props.quantity} dir.</h3>
                                <Col span={24}>
                                    <Row  gutter={[16, 16]}>
                                        {selectedRows.map((p , i)=>(
                                            <Col key={i} lg={6} md={12}>
                                                <div className={'animated zoomIn p-2 border'}>
                                                    <Row className={'flex-a'} gutter={[16,8]}>
                                                        <Col lg={24} sm={12} xs={24}>
                                                            <Form.Item
                                                                className={'d-none'}
                                                                initialValue={p.id}
                                                                validateTrigger="onChange"
                                                                name={[
                                                                    "StockInfo",
                                                                    i,
                                                                    "stockId",
                                                                ]}
                                                                rules={[noWhitespace(t("inputError"))]}
                                                            >
                                                                <Input type={'hidden'}  className="w-100" />
                                                            </Form.Item>
                                                            <div className="form-lang">
                                                                <p className="flex mb-5 flex-between">
                                                                    <p> {t("quantityOf")}</p>
                                                                    <p>{p.warehouseName}</p>
                                                                </p>
                                                                <Form.Item
                                                                    initialValue={p.quantity}
                                                                    validateTrigger="onChange"
                                                                    name={[
                                                                        "StockInfo",
                                                                        i,
                                                                        "Quantity",
                                                                    ]}
                                                                    rules={[noWhitespace(t("inputError"))]}
                                                                >
                                                                    <InputNumber
                                                                        // onChange={()=>{inputChange()}}
                                                                        max={p.quantity} className="w-100" />
                                                                </Form.Item>
                                                                <span className="input-lang btm">
                                                                    {p.measurementUnit}
                                                                </span>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Col>
                                <Col lg={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("areaCategory")}
                                        validateTrigger="onChange"
                                        name="parcelCategoryId"
                                        // rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select
                                            disabled={props.id !== -1}
                                            onChange={(e) => handleKeyChange(e, "parcelCategoryId")}
                                        >
                                            {options.parcelCategories.map((pc, index) => {
                                                return (
                                                    <Option key={index} value={pc.id}>
                                                        {pc.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("area")}
                                        validateTrigger="onChange"
                                        name="parcelId"
                                        // rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select
                                            disabled={props.id !== -1}
                                            onChange={(e) => handleKeyChange(e, "parcelId")}>
                                            {
                                                props.id === -1 ?
                                                    <>
                                                        {options.parcels
                                                            .filter((p) => p.parcelCategoryId === vals.parcelCategoryId)
                                                            .map((pc, index) => {
                                                                return (
                                                                    <Option key={index} value={pc.id}>
                                                                        {pc.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                    </> :
                                                    <>
                                                        {options.parcels
                                                            .map((pc, index) => {
                                                                return (
                                                                    <Option key={index} value={pc.id}>
                                                                        {pc.name}
                                                                    </Option>
                                                                );
                                                            })}
                                                    </>
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("areasSector")}
                                        validateTrigger="onChange"
                                        name="ParcelSectorIds"
                                        // rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select
                                            disabled={props.id !== -1}
                                            mode="multiple"
                                        >
                                            {props.id === -1 ?
                                                <>
                                                    {options.parcelSectors
                                                        .filter((p) => p.parcelId === vals.parcelId)
                                                        .map((pc, index) => {
                                                            return (
                                                                <Option key={index} value={pc.id}>
                                                                    {pc.name}
                                                                </Option>
                                                            );
                                                        })}
                                                </> :
                                                <>
                                                    {options.parcelSectors
                                                        .filter((p) => p.parcelId === props.parcel)
                                                        .map((pc, index) => {
                                                            return (
                                                                <Option key={index} value={pc.id}>
                                                                    {pc.name}
                                                                </Option>
                                                            );
                                                        })}
                                                </>
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </>
                        }
                        {props.modalType  !== 'export' &&
                            <Col lg={8} sm={12} xs={24}>
                                <div className="form-lang">
                                    <Form.Item
                                        label={t("quantityOf")}
                                        validateTrigger="onChange"
                                        name="quantity"
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <InputNumber  className="w-100" />
                                    </Form.Item>
                                    <span className="input-lang btm">
                                        {unit}
                                    </span>
                                </div>
                            </Col>
                        }

                        {props.modalType === 'transfer' &&
                             <Col md={6} sm={12} xs={24}>
                            <Form.Item
                                name="WarehouseId"
                                label={t("warehouse")}
                                validateTrigger="onChange"
                            >
                                <Select
                                    showSearch
                                    notFoundContent={null}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    filterSort={(optionA, optionB) =>
                                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                    }
                                    // onChange={(e)=>{handleFertilizerChange(e)}}
                                >
                                    {options.warehouses.map((c, index) => {
                                        return (
                                            <Option key={index} value={c.id}>
                                                {c.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        }
                        {props.modalType === 'export' &&
                            <>
                                <Col lg={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("acceptedPerson")}
                                        validateTrigger="onChange"
                                        name="handingPerson"
                                        rules={[whiteSpace(t("inputError"))]}
                                    >
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </Col>
                                <Col lg={8} sm={12} xs={24}>
                                    <Form.Item
                                        label={t("acceptedCarNumber")}
                                        validateTrigger="onChange"
                                        name="handingCarNumber"
                                        rules={[whiteSpace(t("inputError"))]}
                                    >
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </Col>
                            </>
                        }
                    </Row>
                    <div
                        className="modalButtons"
                        style={{ position: "absolute", bottom: "20px", right: "25px" }}
                    >
                        <Button onClick={() => props.setVisibleEdit(false)}>
                            {t("cancel")}
                        </Button>
                        <Button type="primary" className="ml-10" htmlType="submit">
                            {t("save")}
                        </Button>
                    </div>
                </div>
            </Form>
        </>
    );
};

const mapStateToProps = ({ options, lang }) => {
    return { options, lang };
};

export default connect(mapStateToProps, { getOptions, notify })(Edit);
