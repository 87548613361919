import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {Row, Col, Button, Tooltip, Table, Modal, Form, Input, InputNumber, Select, DatePicker, Progress} from "antd";
import { UnorderedListOutlined, FileTextOutlined , ClearOutlined} from "@ant-design/icons";
import agros from "../../../../const/api";
import View from "./Modals/View";
import Authorize from "../../../Elements/Authorize";
import moment from "moment";
import Create from "./Modals/Create";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
// import {noWhitespace, whiteSpace} from "../../../../utils/rules";

const { Option } = Select;


function Demands(props) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [demands, setDemands] = useState([]);
  const [selectedDemand, setSelectedDemand] = useState(0);
  let [trigger, setTrigger] = useState(0);
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "name", value: t("name"), con: false },
    { key: "createDate", value: t("createdDate"), con: true },
    { key: "demandNumber", value: t("demmandNo"), con: true },
    { key: "estimatedValue", value: "Təxmini məbləğ", con: true },
    { key: "checkStatus", value: "", con: false },
    { key: "percent", value: "", con: false },
    { key: "index", value: "", con: false },
  ];

  useEffect(() => {
    agros.get("demand").then((res) => {
      setDemands(
        res.data.map((r, index) => {
          return {
            ...r,
            key: index + 1,
            index,
            objectCount: r.demandProducts.length,
            tableIndex: index + 1,
            createDate: moment(r.createDate).format("DD-MM-YYYY"),
            percent: getPercent(r.demandProducts)
          };
        })
      );
    });
  }, [trigger, t]);


  const  getPercent = (demandProducts) =>{
    let length = demandProducts?.length
    console.log(demandProducts)
    if (length){
      let falseDemandLength = 0
      for(let d of demandProducts) {
          falseDemandLength += d.orderQuantity/ d.quantity
      }
      let totalpercent = (falseDemandLength/length)*100
      return totalpercent.toFixed(1)
    }
  }


  const [visibleAddNewDemand, setVisibleAddNewDemand] = useState(false);
  const [visibleViewDemand, setVisibleViewDemand] = useState(false);

  const renderStatus = (val) => {
    switch (val) {
      case 1:
        return { color: "#E49F33", word: t("onWait") };
      case 2:
        return { color: "#7FBFA4", word: t("approved") };
      case 3:
        return { color: "#D24C41", word: t("canceled") };
      case 4:
        return { color: "#4154d2", word: 'Bitmiş' };
      default:
        break;
    }
  };

  // table data columns
  const columns = [
    {
      title: "#",
      key: 1,
      dataIndex: "tableIndex",
      width: 60,
    },
    {
      title: t("name"),
      key: 2,
      dataIndex: "name",
      render(a){
        return(
            <div className={'line-clamp'}>
              {a}
            </div>
        )
      }
    },
    {
      title: t("demmandNo"),
      key: 3,
      dataIndex: "demandNumber",
    },
    {
      title:'Əməliyyatçı',
      key: 3,
      dataIndex: "approvedOrRejectedWorker",
    },
    {
      title: t("createdDate"),
      key: 4,
      dataIndex: "createDate",
    },
    {
      title: "Tələb obyektləri",
      key: 5,
      dataIndex: "objectCount",
      render: (a) => <span>{a} obyekt</span>,
    },
    {
      title: "Təxmini məbləğ (azn)",
      key: 6,
      dataIndex: "estimatedValue",
    },
    {
      title: t("status"),
      key: 7,
      dataIndex: "checkStatus",
      render: (i) => {
        let options = renderStatus(i);
        return (
          <div className="flex statusTd">
            <p className={'f-10'}>{t("status")}</p>
            <span
              style={{
                fontSize:'8px',
                backgroundColor: options?.color,
              }}
              className="statusBox f-10"
            >
              {options?.word}
            </span>
          </div>
        );
      },
    },
    {
      title: "Bitmə dərəcəsi",
      width:'200px',
      key: 8,
      dataIndex: "percent",
      render: (i) => {
        return (
            <div className={'flex all-center'}>
              <Progress percent={i} size="small" />
            </div>
        );
      },
    },
    {
      title: "",
      key: 8,
      dataIndex: "index",
      width: 20,
      render: (i) => {
        return (
          <div className="flex flex-end">
            {/*must add to database*/}
            <Authorize
              mainMenu={"warehouse"}
              page={["demands", "perms"]}
              type={"readDemand"}
            >
              <Tooltip className="ml-5" title={t("demand")}>
                <Button
                  onClick={() => viewDemand(i)}
                  className="border-none"
                  type="text"
                  shape="circle"
                >
                  <FileTextOutlined />
                </Button>
              </Tooltip>
            </Authorize>
          </div>
        );
      },
    },
  ];

  const viewDemand = (index) => {
    setSelectedDemand(index);
    setVisibleViewDemand(true);
  };

  const ops = demands[selectedDemand]
    ? renderStatus(demands[selectedDemand].checkStatus)
    : {};

  const newDemandAdded = () => {
    setVisibleAddNewDemand(false);
    setTrigger(++trigger);
  };


  const onSearch = (values) =>{
    let obj = {}
    // eslint-disable-next-line no-unused-expressions
     values.EndDate?._d ? obj['EndDate'] = values.EndDate?._d : null
    // eslint-disable-next-line no-unused-expressions
     values.StartDate?._d ? obj['StartDate'] = values.StartDate?._d : null
    // eslint-disable-next-line no-unused-expressions
     values.Name ? obj['Name'] = values.Name : null
    // eslint-disable-next-line no-unused-expressions
     values.CheckStatus ? obj['CheckStatus'] = values.CheckStatus : null
    agros.get("demand" , { params: obj }).then((res) => {
      setDemands(
          res.data.map((r, index) => {
            return {
              ...r,
              key: index + 1,
              index,
              objectCount: r.demandProducts.length,
              tableIndex: index + 1,
              createDate: moment(r.createDate).format("DD-MM-YYYY"),
            };
          })
      );
    });
  }

  const clearFilter = () =>{
      setTrigger(++trigger)
      form.resetFields()
  }

  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <div className="border page-heading flex p-2 mt-0 bg-white">
            <div className="page-name">
              <UnorderedListOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">{t("demands")}</span>
            </div>
            <Authorize
              mainMenu={"warehouse"}
              page={["demands", "perms"]}
              type={"create"}
            >
              <Button
                type="primary"
                onClick={() => setVisibleAddNewDemand(true)}
              >
                {t("addTo")}
              </Button>
            </Authorize>
          </div>
        </Col>
        <Col xs={24}>
          <Form onFinish={onSearch} layout="vertical" form={form}>
            <div className="commontask bg-white px-2 pt-15">
              <Row className={'mt-5'} gutter={[16, 8]}>
                <Col md={5} sm={12} xs={24}>
                  <Form.Item
                      name="Name"
                      className={'mb-0'}
                      // rules={[whiteSpace(t("inputError"))]}
                  >
                    <Input placeholder={t("demandName")} size={'large'} />
                  </Form.Item>
                </Col>
                <Col md={5} sm={12} xs={24}>
                  <Form.Item
                      name={'StartDate'}
                      // rules={[noWhitespace(t("dataError"))]}
                  >
                    <DatePicker
                        format={"DD-MM-YYYY"}
                        placeholder={'Tarixdən'}
                        className="w-100"
                    />
                  </Form.Item>
                </Col>

                <Col md={5} sm={12} xs={24}>
                  <Form.Item
                      name={'EndDate'}
                      // rules={[noWhitespace(t("dataError"))]}
                  >
                    <DatePicker
                        format={"DD-MM-YYYY"}
                        placeholder={'Tarixə'}
                        className="w-100"
                    />
                  </Form.Item>
                </Col>
                 <Col md={5} sm={12} xs={24}>
                   <Form.Item
                       name={"CheckStatus"}
                       // rules={[noWhitespace(t("inputError"))]}
                   >
                     <Select placeholder={t('status')}>
                       <Option key={1} value={1}>
                         {t('onWait')}
                       </Option>
                       <Option key={2} value={2}>
                         {t('approved')}
                       </Option>
                       <Option key={3} value={3}>
                         {t('canceled')}
                       </Option>
                       <Option key={4} value={4}>
                         Bitmiş
                       </Option>
                     </Select>
                   </Form.Item>
                </Col>

                <Col md={2} sm={12} xs={24}>
                   <Form.Item
                       name={" "}
                   >
                     <Button type="primary" size={'large'}  className="w-100 f-13" htmlType="submit">
                       {t("search")}
                     </Button>
                   </Form.Item>
                </Col>

                <Col md={2} sm={12} xs={24}>
                   <Form.Item
                       name={" "}
                   >
                     <Button type="primary" size={'large'}  onClick={()=>{clearFilter()}} className="w-100" htmlType="submit">
                       <ClearOutlined />
                     </Button>
                   </Form.Item>
                </Col>

              </Row>
            </div>
          </Form>
        </Col>
        <Col xs={24}>
          <div>
            <Table
              size="small"
              className="bg-white w-100"
              columns={columns}
              dataSource={convertColumns(demands, cols)}
              pagination={{
                pageSizeOptions: ["10", "15", "20", "25", "30"],
                showSizeChanger: true,
                current_page: 1,
              }}
            />
          </div>
        </Col>
      </Row>

      {/*details modal*/}
      <Modal
        title={
          <div className="status">
            <div className="flex flex-align-center flex-between">
              {demands[selectedDemand] ? (
                <>
                  <h3>
                    {demands[selectedDemand].name} / №
                    {demands[selectedDemand].demandNumber}
                  </h3>
                  <span
                    style={{
                      backgroundColor: ops.color,
                    }}
                    className="statusBox"
                  >
                    {ops.word}
                  </span>
                </>
              ) : null}
            </div>
          </div>
        }
        centered
        className="addTaskModal demandModal"
        visible={visibleViewDemand}
        onOk={() => setVisibleViewDemand(false)}
        onCancel={() => setVisibleViewDemand(false)}
        footer={[]}
      >
        <View demand={demands[selectedDemand]} />
      </Modal>

      {/*addnewtask or edit task modal*/}

      <Modal
        title={t("createNewDemand")}
        centered
        className="addTaskModal padModal"
        visible={visibleAddNewDemand}
        onOk={() => setVisibleAddNewDemand(false)}
        onCancel={() => setVisibleAddNewDemand(false)}
        footer={null}
      >
        <Create
          setVisibleAddNewDemand={setVisibleAddNewDemand}
          newDemandAdded={newDemandAdded}
        />
      </Modal>
    </div>
  );
}

export default Demands;
