import React, {useState} from 'react';
import {Button, Col, Form, Input, Modal, Row, Select, Table, Tabs, Tooltip} from "antd";
import Import from "../Modals/Import";
import Export from "../Modals/Export";
import View from "../Modals/View";
import {convertColumns} from "../../../../../utils/columnconverter";
import {useTranslation} from "react-i18next";
import {EyeFilled} from "@ant-design/icons";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
// import Authorize from "../../../../Elements/Authorize";
const {TabPane} = Tabs;
const {Option} = Select;
const BasicTemplate = (props) => {
    const [view, setvisibleView] = useState(false);
    const [importModal, setImportModal] = useState(false);
    const [exportModal, setExportModal] = useState(false);
    const [form] = Form.useForm();
    const {t} = useTranslation();

    const cols = [
        {key: "tableIndex", value: "#", con: true},
        {key: "brand", value: "Markası", con: true},
        {key: "type", value: "Növü", con: true},
        {key: "driver", value: "Sürücü", con: true},
        {key: "plateNumber", value: "Dövlət nişanı", con: true},
        {key: "index", value: "", con: false},
    ];
    const importCols = [
        {
            title: "#",
            key: "index",
            dataIndex: "tableIndex",
        },
        {
            title: 'Benzinin markası',
            key: "brand",
            dataIndex: "brand",
        },
        {
            title: 'Tarıxı',
            key: "date",
            dataIndex: "date",
        },
        {
            title: 'Miqdarı',
            key: "quantity",
            dataIndex: "quantity",
        },
    ];
    const exportCols = [
        {
            title: "#",
            key: "index",
            dataIndex: "tableIndex",
        },
        {
            title: 'Markası',
            key: "brand",
            dataIndex: "brand",
        },
        {
            title: 'Dövlət nişanı',
            key: "plateNumber",
            dataIndex: "plateNumber",
        },
        {
            title: 'Tarıxı',
            key: "date",
            dataIndex: "date",
        },
        {
            title: 'Miqdarı',
            key: "quantity",
            dataIndex: "quantity",
        },
        {
            title: "",
            key: 6,
            dataIndex: "index",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={t("detailed")}>
                            <Button
                                onClick={() => setvisibleView(true)}
                                className="border-none"
                                type="text"
                                shape="circle"
                            >
                                <EyeFilled/>
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];
    const docCols = [
        {
            title: "#",
            key: "index",
            dataIndex: "tableIndex",
        },
        {
            title: 'Yanacağın növü',
            key: "brand",
            dataIndex: "brand",
        },
        {
            title: 'Yanacağın növü',
            key: "type",
            dataIndex: "type",
        },
        {
            title: 'Mədaxil tarixi',
            key: "importDate",
            dataIndex: "importDate",
        },
        {
            title: 'Statusu',
            key: "status",
            dataIndex: "status",
        },
    ];


    const fakedata = [
        {
            tableIndex: '1',
            brand: 'Mercedes',
            type: 'Benzin',
            plateNumber: '10-DD-999',
            date:'12.12.21',
            importDate:'12.12.21',
            quantity:'12l',
            status:'Mədaxil'
        }
    ]

    return (
        <div className="w-100">
            <Row gutter={16}>
                <Col lg={18} className='h-100'>
                    <Form layout="vertical" form={form}>
                        <div className="filter pr-1 pl-1 pt-1 border mt-15 mb-15 bg-white">
                            <Row gutter={[16, 4]}>
                                <Col lg={7}>
                                    <Form.Item
                                        label={'Markasına görə'}
                                        name="byBrand"
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select
                                            // onChange={handleSectorChange}
                                        >
                                            <Option key={1}>Marka</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={7}>
                                    <Form.Item
                                        label={'Növünə görə'}
                                        name="byType"
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select
                                            // onChange={handleSectorChange}
                                        >
                                            <Option key={1}>Marka</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={7}>
                                    <Form.Item
                                        label={'Dövlət nişanına görə'}
                                        name="byPlateNumber"
                                        validateTrigger="onChange"
                                        rules={[whiteSpace(t("inputError"))]}
                                    >
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </Col>
                                <Col lg={3}>
                                    <Form.Item
                                        label={' '}
                                    >
                                        <Button type="primary" size={'large'} className="w-100  h-100"
                                                htmlType="submit">
                                            {t("search")}
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Col>
                <Col lg={6} className='h-100'>
                    <div className="border h-100 d-block mt-15 mb-15 p-1 pb-0 bg-white flex dir-column all-center">
                        <Row gutter={[16, 16]}>
                            <Col lg={24}>
                                <Button onClick={() => {
                                    setImportModal(true)
                                }} className=" f-12  w-100 d-block" type={'primary'}>Yanacağın mədaxili</Button>
                            </Col>
                            <Col lg={24}>
                                <Button onClick={() => {
                                    setExportModal(true)
                                }} className=" f-12  w-100 d-block" type={'primary'}>Yanacağın məxarici</Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <Tabs defaultActiveKey="0"
                // onChange={}
            >
                <TabPane key={0} tab={'Mədaxil'}>
                    <Table
                        size="small"
                        className="bg-white"
                        dataSource={convertColumns(fakedata, cols)}
                        columns={importCols}
                        pagination={{
                            pageSize: 10,
                            current_page: 1,
                            pageSizeOptions: ["10", "15", "20", "25", "30"],
                        }}
                    />
                </TabPane>
                <TabPane key={1} tab={'Məxaric'}>
                    <Table
                        size="small"
                        className="bg-white"
                        dataSource={convertColumns(fakedata, cols)}
                        columns={exportCols}
                        pagination={{
                            pageSize: 10,
                            current_page: 1,
                            pageSizeOptions: ["10", "15", "20", "25", "30"],
                        }}
                    />
                </TabPane>
                <TabPane key={2} tab={'Mədaxil/Məxaric sənədləri'}>
                    <Table
                        size="small"
                        className="bg-white"
                        dataSource={convertColumns(fakedata, cols)}
                        columns={docCols}
                        pagination={{
                            pageSize: 10,
                            current_page: 1,
                            pageSizeOptions: ["10", "15", "20", "25", "30"],
                        }}
                    />
                </TabPane>
            </Tabs>



            <Modal
                title={'Mədaxil'}
                centered
                visible={importModal}
                className="padModal"
                onOk={() => setImportModal(false)}
                onCancel={() => setImportModal(false)}
                footer={null}
            >
                <Import setImportModal={setImportModal}/>
            </Modal>


            <Modal
                title={'Məxaric'}
                centered
                visible={exportModal}
                className="mediumModal padModal"
                onOk={() => setExportModal(false)}
                onCancel={() => setExportModal(false)}
                footer={null}
            >
                <Export setExportModal={setExportModal}/>
            </Modal>

            <Modal
                title={t('detailed')}
                centered
                visible={view}
                onOk={() => setvisibleView(false)}
                onCancel={() => setvisibleView(false)}
                footer={null}
                className="addTaskModal padModal"
            >
                <View setvisibleView={setvisibleView}/>
            </Modal>
        </div>
    );
}


export default BasicTemplate;
