import React , {useEffect , useState} from 'react';
import {
    Row,
    Col,
    Table,
} from "antd";
import {CarOutlined, LineChartOutlined, UsergroupAddOutlined} from "@ant-design/icons";
import {convertColumns} from "../../../../utils/columnconverter";
import {connect} from "react-redux";
import {notify} from "../../../../redux/actions";
import {agros} from "../../../../const/api";
import moment from "moment";
function WorkContiuned(props) {
    const [data , setData] = useState([]);
    let trigger =  1;
    const { notify } = props;

    const columns = [
        {
            title: 'Ad, Soyad, Ata adı',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Avadanlıq adı',
            dataIndex: 'deviceName',
            key: 'deviceName',
        },
        {
            title: 'Tarix',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'inOutStatus',
            key: 'inOutStatus',
        },
    ];

    useEffect(()=>{
        const getItems = async () => {
            await agros.get('Services/Turnstile').then((res) => {
                setData(
                    res.data.map((p, index) => {
                        return {
                            key: index + 1, ...p, index: index + 1,
                            name: p.name + ' ' + p.surname,
                            date: moment(p.date).format("DD-MM-YYYY HH:ss")
                        };
                    })
                );
            }).catch(()=>{
                notify("" , false);
            });
        };
        getItems();
    } ,  [trigger])

    return (
        <div>
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border animated fadeIn page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <LineChartOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">İşə davamiyyət</span>
                        </div>
                    </div>
                </Col>
                <Col xs={24}>
                    <Col xs={24}>
                        <Table
                            size="small"
                            className="bg-white animated fadeInUp"
                            columns={columns}
                            dataSource={data}
                            pagination={{
                                pageSize: 10,
                                current_page: 1,
                            }}
                        />
                    </Col>
                </Col>
            </Row>
        </div>
    );
}

export default connect(null, { notify })(WorkContiuned);
