import React,{useState , useEffect} from 'react';
import {
    Row,
    Switch,
    Col,
    Card,
    Table,
    Button,
    Form,
    Tooltip,
    Input,
    Popconfirm, Modal,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import {convertColumns} from "../../../../utils/columnconverter";
import {notify} from "../../../../redux/actions";
import { connect } from "react-redux";
import agros from "../../../../const/api";
import Edit from "./Modal/Edit";
import { useTranslation } from "react-i18next";
import { whiteSpace } from "../../../../utils/rules";
import Authorize from "../../../Elements/Authorize";

function Trailers(props) {
    const [trailers, setTrailers] = useState([{}]);
    const [visibleEdit , setVEdit] = useState(false);
    const [editing , setediting] = useState(null);
    const deleteTrailer = (i)=>{console.log(i)}

    const setEditingObject = (i)=>{
        console.log(i)
        setVEdit(true)
    }
    const { t } = useTranslation();
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "marka", value: 'Markası', con: true },
        { key: "model", value: 'Modeli', con: true },
        { key: "originCountry", value: 'İstehsalçı ölkə', con: true },
        { key: "originDate", value: 'İstehsal tarixi', con: true },
        { key: "originCompany", value: 'İstehsal edən şirkət', con: true },
        { key: "buyDate", value: 'Alınma tarixi', con: true },
        { key: "purpose", value: 'İstifadə məqsədi', con: true },
        { key: "id", value: "", con: false },
    ];

    const { notify } = props;

    const columns = [
        {
            title: "#",
            key: "1",
            dataIndex: "index",
            width: 80,
        },
        {
            title:'Markası',
            key: "2",
            dataIndex: "marka",
        },
        {
            title:'Modeli',
            key: "3",
            dataIndex: "model",
        },
        {
            title:'İstehsalçı ölkə',
            key: "4",
            dataIndex: "originCountry",
        },
        {
            title:'İstehsal tarixi',
            key: "4",
            dataIndex: "originDate",
        },
        {
            title:'İstehsal edən şirkət',
            key: "5",
            dataIndex: "originCompany",
        },
        {
            title:'Alınma tarixi',
            key: "5",
            dataIndex: "buyDate",
        },
        {
            title:'İstifadə məqsədi',
            key: "5",
            dataIndex: "purpose",
        },
        {
            title: "",
            key: "3",
            dataIndex: "id",
            width: 30,
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Authorize
                            mainMenu={"administrator"}
                            page={["positions", "perms"]}
                            type={"delete"}
                        >
                            <Popconfirm
                                placement="topRight"
                                title={t("areYouSure")}
                                onConfirm={() => deleteTrailer(i)}
                                okText={t("yes")}
                                cancelText={t("no")}
                            >
                                <Tooltip className="ml-5" title={t("delete")}>
                                    <Button className="border-none" type="text" shape="circle">
                                        <DeleteFilled />
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        </Authorize>
                        <Authorize
                            mainMenu={"administrator"}
                            page={["positions", "perms"]}
                            type={"update"}
                        >
                            <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                                <Button
                                    className="border-none"
                                    type="text"
                                    shape="circle"
                                    onClick={() => setEditingObject(i)}
                                >
                                    <EditFilled />
                                </Button>
                            </Tooltip>
                        </Authorize>
                    </div>
                );
            },
        },
    ];

    return (
      <div>
          <Row gutter={[10, 10]}>
              <Col xs={24}>
                  <div className="border p-2 mt-0 bg-white">
                      <div className="flex flex-between">
                          <div>
                              <UnorderedListOutlined className="f-20 mr5-15" />
                              <span className="f-20 bold">Qoşqular</span>
                          </div>
                          <Button onClick={()=>{setVEdit(true)}} type={'primary'}>Əlavə et</Button>
                      </div>
                  </div>
              </Col>
              <Col xs={24}>
                  <Table
                      size="small"
                      className="bg-white"
                      columns={columns}
                      dataSource={convertColumns(trailers, cols)}
                      pagination={{
                          pageSize: 10,
                          current_page: 1,
                          total: trailers.length,
                      }}
                  />
              </Col>
          </Row>

          <Modal
              title={'Qoşqu əlavə et / Redaktə et'}
              centered
              className="addTaskModal padModal"
              visible={visibleEdit}
              onOk={() => setVEdit(false)}
              onCancel={() => setVEdit(false)}
              footer={[]}
          >
              <Edit
                  setVEdit={setVEdit}
              />
          </Modal>
      </div>
    );
}

export default connect(null, { notify })(Trailers);

