import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Switch,
    Col,
    Card,
    Table,
    Button,
    Form,
    Tooltip,
    Input,
    Popconfirm, Select, InputNumber,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import { convertColumns } from "../../../../../utils/columnconverter";
// notification
import { notify } from "../../../../../redux/actions";
import { connect } from "react-redux";
import agros from "../../../../../const/api";
import { useTranslation } from "react-i18next";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import Authorize from "../../../../Elements/Authorize";
import moment from "moment";
const { Option } = Select;
const NormativeTemplate = (props) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [positions, setPositions] = useState([]);
    const [norms, setNorms] = useState([]);
    const [trailers, setTrailers] = useState([]);
    const [responsible, setResponsible] = useState(false);
    const [editing, setEditing] = useState(null);
    const { notify  , detailed} = props;


    const nameInput = useRef();
    let techniqueId = detailed.id;


    console.log(techniqueId)
    const setEditingObject = async (i) => {
        setEditing(i);
        await agros.get(`TechniqueNormative/${i}`).then((res) => {
            form.setFieldsValue({
                ...res.data,
                TrailerId:res.data.trailerId
            });
        });
    };

    const cols = [
        { key: "trailer", value: "İstifadə etdilən aqreqat", con: false },
        { key: "speed", value: "Sürət", con: false },
        { key: "area", value: "Sahə", con: false },
        { key: "fuelConsumption", value: "Sərf olunan yanacaq", con: false },
        { key: "id", value: "", con: false },
    ];

    const commonColumns = [
        {
            title: 'İstifadə etdilən aqreqat',
            key:  "trailer",
            dataIndex: "trailer",
            render: (i) => {
                return (
                    <span>{i.name}</span>
                )
            }
        },
        {
            title: 'Sürət',
            key: "speed",
            dataIndex: "speed",
            render: (i) => {
                return(
                    <div>{i} km/saat</div>
                )
            }
        },
        {
            title: 'Sahə',
            key: "area",
            dataIndex: "area",
            render: (i) => {
                return(
                    <div>{i} ha</div>
                )
            }
        },
        {
            title: 'Sərf olunan yanacaq',
            key: "fuelConsumption",
            dataIndex: "fuelConsumption",
            render: (i) => {
                return(
                    <div>{i} litr</div>
                )
            }
        },
         {
            title: '',
            key: "id",
            dataIndex: "id",
             render: (i)=>{
                return (
                    <div className="flex flex-end">
                        <Popconfirm
                            placement="topRight"
                            title={t("areYouSure")}
                            onConfirm={() => deletePosition(i)}
                            okText={t("yes")}
                            cancelText={t("no")}
                        >
                            <Tooltip className="ml-5" title={t("delete")}>
                                <Button className="border-none" type="text" shape="circle">
                                    <DeleteFilled />
                                </Button>
                            </Tooltip>
                        </Popconfirm>

                        <Tooltip className="ml-5" title={t("edit")} placement="topRight">
                            <Button
                                className="border-none"
                                type="text"
                                shape="circle"
                                onClick={() => setEditingObject(i)}
                            >
                                <EditFilled />
                            </Button>
                        </Tooltip>
                    </div>
                )
             }
        },

    ];

    const cancelEditing = () => {
        setEditing(null);
        setResponsible(false);
        form.resetFields();
    };


    const getTrailes = async () => {
        await agros.get("Trailer").then((res) => {
            setTrailers(
                res.data.map((p, index) => {
                    return { key: index + 1, ...p, index: index + 1 };
                })
            );
        });
    }

    const deletePosition = async (i) => {
        await agros
            .delete(`/TechniqueNormative/${i}`)
            .then(() => {
                // description
                notify("", true);
                getPositions();
                props.setVisibleNormatives(false);
            })
            .catch((err) => {
                //error
                notify(err.response, false);
            });
    };

    const savePosition = async (values) => {
        let obj1 = {
          ...values,
          techniqueId
        };
        let obj2 = {
            techniqueId,
            TrailerNormatives:[
                {
                    ...values
                }
            ]
        }
        if (!editing) {
            await agros
                .post("/TechniqueNormative", obj2)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();
                })
                .catch((err) => {
                    notify(err.response, false);
                });
        } else {
            obj1["id"] = editing;
            await agros
                .put(`/TechniqueNormative/${editing}`, obj1)
                .then((res) => {
                    notify("", true);
                    getPositions();
                    cancelEditing();

                })
                .catch((err) => {
                    notify(err.response, false);
                });
        }
    };

    const getPositions = async () => {
        await agros.get("TechniqueNormative").then((res) => {
            setPositions(
                res.data.filter(res => res.technique.id === techniqueId).map((p, index) => {
                    return { key: index + 1, ...p, index: index + 1 };
                })
            );
        });
    };

    useEffect(() => {
        getTrailes();
        getPositions();
    }, [t]);

    const onSwitchChange = () => {
        setResponsible(!responsible);
    };

    return (
        <Row gutter={[10, 10]}>
            <Col lg={16} xs={24}>
                {
                    positions.map((p, index) =>(
                        <Card title={'Texnika - '+ p.technique.name} >
                            <div className="border">
                                <Table
                                    size="small"
                                    className="bg-white"
                                    dataSource={convertColumns(p.trailerNormatives, cols)}
                                    columns={commonColumns}
                                    pagination={{
                                        pageSize: 10,
                                        current_page: 1,
                                        pageSizeOptions: ["10", "15", "20", "25", "30"],
                                    }}
                                />
                            </div>
                        </Card>
                    ))
                }
            </Col>
            <Col lg={8} xs={24}>
                <Card title={'Əlavə et / Redaktə et' }>
                    <Form layout="vertical" onFinish={savePosition} form={form}>
                        <Form.Item
                            label={'Aqreqat'}
                            name={"TrailerId"}
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("inputError"))]}
                        >
                            <Select
                                showSearch
                                notFoundContent={null}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                                filterSort={(optionA, optionB) =>
                                    optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                }
                            >
                                {trailers.map((c, index) => {
                                    return (
                                        <Option key={index} value={c.id}>
                                            { c.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <div className="form-lang">
                            <Form.Item
                                label={"Sürət"}
                                className="mb-5"
                                validateTrigger="onChange"
                                name="speed"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <InputNumber  />
                            </Form.Item>
                            <div className="input-lang btm">km/saat</div>
                        </div>

                        <div className="form-lang">
                            <Form.Item
                                label={"Sahə"}
                                className="mb-5"
                                validateTrigger="onChange"
                                name="area"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <InputNumber  />
                            </Form.Item>
                            <div className="input-lang btm"> Ha </div>
                        </div>

                        <div className="form-lang">
                            <Form.Item
                                label={"Yanacaq sərfiyyatı"}
                                className="mb-5"
                                validateTrigger="onChange"
                                name="fuelConsumption"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <InputNumber  />
                            </Form.Item>
                            <div className="input-lang btm"> Litr </div>
                        </div>


                        <div className="flex  flex-between mt-15">
                            <Button onClick={cancelEditing}>{t("cancel")}</Button>
                            {/*<Authorize*/}
                            {/*    mainMenu={"administrator"}*/}
                            {/*    page={["positions", "perms"]}*/}
                            {/*    type={editing ? "update" : "create"}*/}
                            {/*>*/}
                                <Button htmlType="submit">{t("save")}</Button>
                            {/*</Authorize>*/}
                        </div>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
};

export default connect(null, { notify })(NormativeTemplate);



