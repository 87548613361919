import React ,{useState , useEffect} from 'react';
import {
    Row,
    Col,
    Table,
} from "antd";
import {CarOutlined} from "@ant-design/icons";
import {agros} from  '../../../../const/api'
import {connect} from "react-redux";
import {notify} from "../../../../redux/actions";
import moment from "moment";
function MainCars(props) {
    const [data , setData] = useState([]);
    let trigger =  1;
    const { notify } = props;

    useEffect(()=>{
        const getItems = async () => {
            await agros.get('Services/PlateRecognition').then((res) => {
                setData(
                    res.data.map((p, index) => {
                        return { key: index + 1, ...p, index: index + 1 ,   date: moment(p.date).format("DD-MM-YYYY HH:ss")};
                    })
                );
            }).catch(()=>{
                notify("" , false);
            });
        };
        getItems();
    } ,  [trigger])

    const columns = [
        {
            title: 'Avtomobil nömrəsi',
            dataIndex: 'carNumber',
            key: 'carNumber',
        },
        {
            title: 'Tarix',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Status',
            dataIndex: 'inOutStatus',
            key: 'inOutStatus',
        },
        {
            title: 'Kamera adı',
            dataIndex: 'cameraName',
            key: 'cameraName',
        },
    ];



    return (
        <div>
            <Row gutter={[10, 10]}>
                <Col lg={24}>
                    <div className="border animated fadeIn page-heading flex p-2 mt-0 bg-white">
                        <div className="page-name">
                            <CarOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">Avtomobillər</span>
                        </div>
                    </div>
                </Col>
                <Col lg={24}>
                    <Table
                        size="small"
                        className="bg-white animated fadeInUp"
                        columns={columns}
                        dataSource={data}
                        pagination={{
                            pageSize: 10,
                            current_page: 1,
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}


export default connect(null, { notify })(MainCars);