import React from "react";
import "@ant-design/compatible/assets/index.css";
import {
    Row,
    Col,
    Card,
    Table,
    Button,
    Tooltip,
    Popconfirm,
    Select,
} from "antd";
import {
    UnorderedListOutlined,
    EditFilled,
    DeleteFilled,
} from "@ant-design/icons";
import agros from "./../../../../const/api";
import { withTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import {connect} from "react-redux";
import {notify} from "../../../../redux/actions";
import EquipmentCategories from "./Forms/EquipmentCategories";
import EquipmentTypes from "./Forms/EquipmentTypes";
import EquipmentBrands from "./Forms/EquipmentBrands";
import EquipmentModel from "./Forms/EquipmentModel";
import TrailerCategory from "./Forms/TrailerCategory";
import TrailerModel from "./Forms/TrailerModel";
import Trailers from "./Forms/Trailers";



const { Option } = Select;

const urls = {
    EquipmentCategories: 'equipmentcategories',
    EquipmentTypes: 'equipmentypes',
    EquipmentBrands: 'equipmentbrands',
    EquipmentModel: 'equipmentmodel',
    TrailerCategory: 'trailercategory',
    TrailerModel: 'trailermodel',
    Trailers: 'trailers',
};
// permissions yazanda yaratmalisan
const mappers = {
    EquipmentCategories: 'equipmentCategories',
    EquipmentTypes: 'equipmentTypes',
    EquipmentBrands: 'equipmentBrands',
    EquipmentModel: 'equipmentModel',
    TrailerCategory: 'trailerCategory',
    TrailerModel: 'trailerModel',
};

class Equipment extends React.Component {
    admin = this.props.perms.administrator.subs;
    cols = [
        { key: "index", value: "#", con: true },
        { key: "category", value: this.props.t("typeOf"), con: true },
        { key: "name", value: this.props.t("name"), con: true },
        { key: "type", value: this.props.t("type"), con: true },
        { key: "brand", value: 'Marka', con: true },
        { key: "buttons", value: "", con: false },
    ];

    state = {
        forms: {
            EquipmentCategories: {
                items: [],
                page: 1,
                editing: null,
            },
            EquipmentTypes:{
                items: [],
                page: 1,
                editing: null,
            },
            EquipmentBrands:{
                items: [],
                page: 1,
                editing: null,
            },
            EquipmentModel:{
                items: [],
                page: 1,
                editing: null,
            },
            TrailerCategory:{
                items: [],
                page: 1,
                editing: null,
            },
            TrailerModel:{
                items: [],
                page: 1,
                editing: null,
            },
        },
        selected: "EquipmentCategories",
        columns: this.initialColumns,
        editing: null,
    };


    // table data columns
    initialColumns = [
        {
            title: "#",
            dataIndex: "index",
            key: "1",
        },
        {
            title: this.props.t("name"),
            dataIndex: "name",
            key: "2",
        },
        {
            title: "",
            dataIndex: "buttons",
            key: "3",
            width: 30,
            render: (b) => <>{b}</>,
        },
    ];
    extraColumns = {
        EquipmentCategories: [],
        EquipmentTypes: [],
        EquipmentBrands: [],
        EquipmentModel: [
            {
                title: this.props.t("category"),
                dataIndex: "category",
                key: "4",
            },
            {
                title: this.props.t("type"),
                dataIndex: "type",
                key: "5",
            },
            {
                title:'Marka',
                dataIndex: "brand",
                key: "5",
            },
        ],
        TrailerCategory: [],
        TrailerModel: [
            {
                title: this.props.t("category"),
                dataIndex: "category",
                key: "4",
            },
        ],
    };


    // notification component
    openNotification = () => {
        this.props.notify("", true);
    };

    insertExtraColumns = () => {
        const columns = [...this.initialColumns];
        this.extraColumns[this.state.selected].forEach((e, index) => {
            columns.splice(2 + index, 0, e);
        });
        this.setState({ columns });
    };

    selectHandleChange = (selected) => {
        this.setState({ selected }, () => {
            this.getTableData();
            this.insertExtraColumns();
        });
    };

    cancelEdit = () => {
        const s = this.state.selected;
        const forms = { ...this.state.forms };
        if (forms[s].editing === null) {
            forms[s].editing = undefined;
        } else {
            forms[s].editing = null;
        }
        this.setState({ forms });
    };

    renderRightSide = () => {
        const s = this.state.selected;
        switch (s) {
            case "EquipmentCategories":
                return (
                  <EquipmentCategories
                      cancelEdit={this.cancelEdit}
                      saveItem={this.saveItem}
                      name={"EquipmentCategories"}
                      editing={this.state.forms[s].editing}
                  />
                );
            case "EquipmentTypes":
                return (
                   <EquipmentTypes
                       cancelEdit={this.cancelEdit}
                       saveItem={this.saveItem}
                       name={"EquipmentTypes"}
                       editing={this.state.forms[s].editing}
                   />
                );
            case "EquipmentBrands":
                return (
                    <EquipmentBrands
                        cancelEdit={this.cancelEdit}
                        saveItem={this.saveItem}
                        name={"EquipmentBrands"}
                        editing={this.state.forms[s].editing}
                    />
                );
            case "EquipmentModel":
                return (
                    <EquipmentModel
                        cancelEdit={this.cancelEdit}
                        saveItem={this.saveItem}
                        name={"EquipmentModel"}
                        editing={this.state.forms[s].editing}
                    />
                );
            case "TrailerCategory":
                return (
                    <TrailerCategory
                        cancelEdit={this.cancelEdit}
                        saveItem={this.saveItem}
                        name={"TrailerCategory"}
                        editing={this.state.forms[s].editing}
                    />
                );
            case "TrailerModel":
                return (
                    <TrailerModel
                        cancelEdit={this.cancelEdit}
                        saveItem={this.saveItem}
                        name={"TrailerModel"}
                        editing={this.state.forms[s].editing}
                    />
                );
            default:
                return null;
        }
    };

    getTableData = async () => {
        await agros.get(urls[this.state.selected]).then((res) => {
            const forms = this.state.forms;
            console.log(res.data)
            forms[this.state.selected].items = res.data.map((d, index) => ({
                ...d,
                index: index + 1,
                key: d.id,
                category:
                    this.state.selected !== "Medicines"
                        ? d.category
                        : d.category === 1
                        ? this.props.t("medicine")
                        : this.props.t("fertilizer"),
                buttons: (
                    <div className="flex flex-end">
                        {this.admin.equipment.subs[mappers[this.state.selected]]
                            .perms.delete && (
                            <Popconfirm
                                placement="topRight"
                                title={this.props.t("areYouSure")}
                                onConfirm={() => this.deleteItem(d.id)}
                                okText={this.props.t("yes")}
                                cancelText={this.props.t("no")}
                            >
                                <Tooltip
                                    className="ml-5"
                                    title={this.props.t("delete")}
                                    placement="leftBottom"
                                >
                                    <Button className="border-none" type="text" shape="circle">
                                        <DeleteFilled />
                                    </Button>
                                </Tooltip>
                            </Popconfirm>
                        )}
                        {this.admin.equipment.subs[mappers[this.state.selected]]
                            .perms.update && (
                            <Tooltip
                                className="ml-5"
                                title={this.props.t("edit")}
                                placement="topRight"
                            >
                                <Button
                                    className="border-none"
                                    type="text"
                                    shape="circle"
                                    onClick={() => this.setEditingObject(d.id)}
                                >
                                    <EditFilled />
                                </Button>
                            </Tooltip>
                        )}
                    </div>
                ),
            }));
            this.setState({ forms });
        });
    };

    setEditingObject = async (id) => {
        const s = this.state.selected;
        await agros.get(urls[s] + "/" + id).then((res) => {
            const forms = { ...this.state.forms };
            forms[s].editing = res.data;
            this.setState({ forms }, () => {});
        });
    };

    deleteItem = async (id) => {
        await agros.delete(`${urls[this.state.selected]}/${id}`).then((res) => {
            this.openNotification();
            this.getTableData();
        });
    };

    componentDidMount() {
        this.getTableData();
        this.insertExtraColumns();
    }

    saveItem = (object) =>
        new Promise(async (resolve, reject) => {
            const s = this.state.selected;
            if (this.state.forms[s].editing) {
                await agros
                    .put(`${urls[s]}/${object.id}`, object)
                    .then((res) => {
                        const forms = { ...this.state.forms };
                        forms[s].editing = null;
                        this.setState({ forms });
                        this.openNotification();
                        this.getTableData();
                        resolve();
                    })
                    .catch((err) => {
                        this.props.notify("", false);
                    });
            } else {
                await agros
                    .post(urls[s], object)
                    .then((res) => {
                        const forms = { ...this.state.forms };
                        forms[s].editing = undefined;
                        this.setState({ forms });
                        this.openNotification();
                        this.getTableData();
                        resolve();
                    })
                    .catch((err) => {
                        this.props.notify("", false);
                    });
            }
        });

    render() {
        const { t } = this.props;
        return (
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border p-2 mt-0 bg-white">
                        <UnorderedListOutlined className="f-20 mr5-15" />
                        <span className="f-20 bold">Texnika</span>
                    </div>
                </Col>
                <Col lg={12} xs={24}>
                    <Select
                        className="w-100 mb-5 menu-options"
                        defaultValue="EquipmentCategories"
                        onChange={this.selectHandleChange}
                    >
                        <Option value="EquipmentCategories">Texnika kateqoriyası</Option>
                        <Option value="EquipmentTypes">Texnikanın növü</Option>
                        <Option value="EquipmentBrands">Texnikanın markası</Option>
                        <Option value="EquipmentModel">Texnikanın modeli</Option>
                        <Option value="TrailerCategory">Qpşqunun kateqoriyası</Option>
                        <Option value="TrailerModel">Qoşqunun modeli</Option>
                    </Select>

                    <Table
                        size="small"
                        className="bg-white"
                        columns={this.state.columns}
                        dataSource={convertColumns(
                            this.state.forms[this.state.selected].items,
                            this.cols
                        )}
                        pagination={{
                            pageSize: 10,
                            current_page: 1,
                            total: this.state.forms[this.state.selected].items.length,
                        }}
                    />
                </Col>
                <Col lg={12} xs={24}>
                    <Card title={t("addTo")}>{this.renderRightSide()}</Card>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = ({ user }) => {
    return { perms: user.data.userPermissions };
};
const exp = withTranslation()(Equipment);
export default connect(mapStateToProps, { notify })(exp);
