import React from 'react';
import {Col, Table, Row, Button, Tooltip, Form, Select, Input} from "antd";
import {useTranslation} from "react-i18next";
import {CompassOutlined, EditFilled, EyeFilled} from "@ant-design/icons";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import {convertColumns} from "../../../../../utils/columnconverter";

function View(props) {
    const { t } = useTranslation();

    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "agregat", value: "İstifadə etdilən aqreqat", con: true },
        { key: "type", value: "Sürət", con: true },
        { key: "driver", value: "sərf olunan vaxt", con: true },
        { key: "plateNumber", value: "Sahə", con: true },
        { key: "plateNumber", value: "Sərf olunan yanacaq", con: true },
        { key: "index", value: "", con: false },
    ];
    const commonColumns = [
        {
            title: "#",
            key: "index",
            dataIndex: "tableIndex",
        },
        {
            title: 'İstifadə etdilən aqreqat',
            key:  "agregat",
            dataIndex: "agregat",
        },
        {
            title: 'Sürət',
            key: "Speed",
            dataIndex: "type",
        },
        {
            title: 'sərf olunan vaxt',
            key:  "date",
            dataIndex: "driver",
        },
        {
            title: 'Sahə',
            key: "area",
            dataIndex: "plateNumber",
        },
        {
            title: 'Sərf olunan yanacaq',
            key: "fuel",
            dataIndex: "plateNumber",
        },
    ];

    const fakedata = [

    ]


    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                    <table className="customtable">
                        <tbody>
                            <tr>
                                <td>Tarixi</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Qeyd</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Tələbin vacibliyi</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Tələbin növü</td>
                                <td></td>
                            </tr>

                        </tbody>
                    </table>
                </Col>
                {/*<Col lg={12} xs={24}>*/}
                {/*    <table className="customtable">*/}
                {/*        <tbody>*/}
                {/*            <tr>*/}
                {/*                <td>Alınma tarixi </td>*/}
                {/*                <td></td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <td>İstifadə məqsədi</td>*/}
                {/*                <td></td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <td>Çəkmə gücü</td>*/}
                {/*                <td></td>*/}
                {/*            </tr>*/}
                {/*            <tr>*/}
                {/*                <td>İstifadə etdiyi yanacaq növü </td>*/}
                {/*                <td></td>*/}
                {/*            </tr>*/}
                {/*        </tbody>*/}
                {/*    </table>*/}
                {/*</Col>*/}
                {/*<Col xs={24}>*/}
                {/*    <div className="border p-15">*/}
                {/*        <h3>Normativlər</h3>*/}
                {/*    </div>*/}
                {/*</Col>*/}
                {/*<Col xs={24}>*/}
                {/*   <div className="border">*/}
                {/*       <Table*/}
                {/*           size="small"*/}
                {/*           className="bg-white"*/}
                {/*           dataSource={convertColumns(fakedata, cols)}*/}
                {/*           columns={commonColumns}*/}
                {/*           pagination={{*/}
                {/*               pageSize: 10,*/}
                {/*               current_page: 1,*/}
                {/*               pageSizeOptions: ["10", "15", "20", "25", "30"],*/}
                {/*           }}*/}
                {/*       />*/}
                {/*   </div>*/}
                {/*</Col>*/}
            </Row>
            <div
                className="modalButtons"
                style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "40px",
                }}
            >
                <Button onClick={() =>{props.setvisibleView(false)}}>
                    {t("close")}
                </Button>
            </div>
        </div>
    );
}


export default View;
