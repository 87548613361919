import React from 'react';
import {Button, Col, Row} from "antd";
import {useTranslation} from "react-i18next";

function Gps(props) {
    const { t } = useTranslation();
    return (
        <div>

            <Row gutter={[16, 16]}>
                <Col lg={24} xs={24}>
                    <table className="customtable">
                        <tbody>
                            <tr>
                                <td>Hansı işlə məşğul olması</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Məşğul olma müddəti</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>Xəritədə yeri</td>
                                <td><Button type={'primary'}>Göstər</Button></td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
            <div
                className="modalButtons"
                style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "40px",
                }}
            >
                <Button onClick={() =>{props.setvisibleGps(false)}}>
                    {t("close")}
                </Button>
            </div>
        </div>
    );
}

export default Gps;
