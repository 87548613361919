import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Form, Input, InputNumber, notification, Row, Select, Switch} from "antd";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import {useTranslation} from "react-i18next";
import agros from "../../../../../const/api";
import moment from "moment";
import {FrownOutlined} from "@ant-design/icons";
import {connect} from "react-redux";
import {notify} from "../../../../../redux/actions";
const { Option } = Select;
const { TextArea } = Input;

function AddModal(props) {
    const [users, setUsers] = useState([]);
    const [techStatus, setTechStatus] = useState([]);
    const [techniqueSpareParts, setTechniqueSparePart] = useState([]);
    const { t } = useTranslation();
    const [tasks, setTasks] = useState([]);
    const [form] = Form.useForm();
    const  {techs , notify} =  props;



    const submitForm = (values) =>{
        agros
            .post("TechnicalInspection", { Date: moment(values.Date).format('YYYY.MM.DD'), ...values })
            .then((res) => {
                notify("Plan əlavə olundu", true);
                form.resetFields();
                props.setVisibleAddModal(false);
                props.getTechnicalInspections();
            })
            .catch((err) => {
                notify(err.response, false);
            });
    }



    const getUsers = async () => {
        await agros.get("hr").then((res) => {
            setUsers(res.data);
        });
    }

    const getTechniqueSparePart = async () => {
        await agros.get("TechniqueSparePart").then((res) => {
            setTechniqueSparePart(res.data);
        });
    }

    useEffect(() => {
        getUsers()
        getTechniqueSparePart()
    } , [])

;

    const addTask = () => {
        const all = [...tasks];
        const id = tasks.length ? tasks[tasks.length - 1].id + 1 : 0;
        all.push({
            id,
            type:2,
            demands: [],
        });
        setTasks(all);
    };

    const deleteTask = (index) => {
        const all = [...tasks];
        all.splice(index, 1);
        setTasks(all);
    };



    const addDemand = (index) => {
        const all = [...tasks];
        const id = all[index].demands.length
            ? all[index].demands[all[index].demands.length - 1].id + 1
            : 0;
        all[index].demands.push({
            id,
        });
        setTasks(all);
    };


    const setService = (e , index) => {
        const all = [...tasks];
        all[index].type = e
        all[index].demands = []
        setTasks(all);
    };



    const deleteDemand = (index, bindex) => {
        const all = [...tasks];
        all[index].demands.splice(bindex, 1);
        setTasks(all);
    };


    return (
        <div className='w-100'>
            <Form onFinish={submitForm} layout="vertical" form={form}>
                <div className="commontask">
                    <Row gutter={[16, 16]}>
                        <Col lg={12} md={12}>
                            <Form.Item
                                label={'Texnika'}
                                name="TechniqueId"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Select>
                                    {techs.map(tech => (
                                        <Option value={tech.id} key={tech.id}>
                                            {' ' + tech.techniqueCategory.name + ' '}/
                                            {' ' + tech.mark + ' '}/
                                            {' ' + tech.model + ' '}/
                                            #{tech.vin + ' '}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col md={12} sm={12}>
                            <Form.Item
                                label={'Tarixi'}
                                name="Date"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("dateError"))]}
                            >
                                <DatePicker placeholder={t("selectDate")} className="w-100" />
                            </Form.Item>
                        </Col>

                        <Col md={12} sm={12}>
                            <div className="flex mt-10 flex-align-center">
                                <Switch checked={techStatus} onChange={(e)=>{setTechStatus(e)}} />
                                <span className="ml-10">{techStatus ? 'Yararlı' : 'Yararsız'}</span>
                            </div>
                        </Col>
                        <Col md={24} sm={24}>
                            <Form.Item
                                label={'Qeyd'}
                                name="Note"
                                validateTrigger="onChange"
                                rules={[whiteSpace(t("inputError"))]}
                            >
                                <TextArea/>
                            </Form.Item>
                        </Col>
                    </Row>

                    {
                        tasks.length === 0 &&
                        <div className={'w-100'}>
                            <Button className={'w-100'} type="primary"
                                    onClick={addTask}
                            >
                                Tələb əlavə et
                            </Button>
                        </div>
                    }

                    {tasks.map((task , index, i) => (
                        <div className="task1 border mt-15 p-2">
                            <Row gutter={[16,16]}>
                                <Col xs={24}>
                                    <div className="w-100 flex-align-center flex flex-between">
                                        <h3>
                                            Tələb  {index + 1}
                                        </h3>
                                        <div className="flex">
                                            <Button
                                                className="mr5-5 btn-danger"
                                                onClick={() => deleteTask(index)}
                                            >
                                                {t("delete")}
                                            </Button>
                                            <Button type="primary"
                                                onClick={addTask}
                                            >
                                                {t("addTo")}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={6} md={12}>
                                    <Form.Item
                                        label={'Tələbin  adı'}
                                        name={["TaskList", index, "TaskName"]}
                                        validateTrigger="onChange"
                                        rules={[whiteSpace(t("inputError"))]}
                                    >
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </Col>
                                <Col md={6} sm={12}>
                                    <Form.Item
                                        label={'Tələbin Növü'}
                                        name={["TaskList", index, "Type"]}
                                        validateTrigger="onChange"

                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select onChange={(e)=>setService(e , index)}>
                                            <Option value={1}>
                                                Daxili
                                            </Option>
                                            <Option  value={2}>
                                                Servis
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={6} md={12}>
                                    <Form.Item
                                        label={'Tələb edən şəxs'}
                                        name={["TaskList", index, "Requester"]}
                                        validateTrigger="onChange"
                                        rules={[whiteSpace(t("inputError"))]}
                                    >
                                        <Select>
                                            {users.map(user => (
                                                <Option value={user.name} key={user.id}>
                                                    {user.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col lg={6} md={12}>
                                    <div className="form-lang">
                                        <Form.Item
                                            label={'Tələbin təxmini məbləği'}
                                            name={["TaskList", index, "ApproximateAmount"]}
                                            validateTrigger="onChange"
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <InputNumber/>
                                        </Form.Item>
                                        <div className="input-lang btm">azn</div>
                                    </div>
                                </Col>
                                {task.type === 1 && task.demands.length === 0 ? (
                                    <Button
                                        onClick={() => addDemand(index)}
                                        className="w-100  mt-15 flex all-center"
                                        type="primary"
                                    >
                                        Dəyişən detal əlavə et
                                    </Button>
                                ) : null}
                            </Row>
                            {task.type === 1 && task.demands.map((dm, bindex) => {
                                return (
                                         <div key={bindex} className="task1  border mt-15  p-2">
                                <Row gutter={[16, 16]}>
                                    <Col xs={24}>
                                        <div className="w-100 flex-align-center flex flex-between">
                                            <h3>
                                                Dəyişən detal {index+1}.{bindex + 1}
                                            </h3>
                                            <div className="flex">
                                                <Button
                                                    className="mr5-5 btn-danger"
                                                    onClick={() => deleteDemand(index , bindex)}
                                                >
                                                    {t("delete")}
                                                </Button>
                                                <Button type="primary"
                                                    onClick={()=>{addDemand(index)}}
                                                >
                                                    {t("addTo")}
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md={12} sm={12}>
                                        <Form.Item
                                            label={'Dəyişilən material'}
                                            name={[
                                                "TaskList",
                                                index,
                                                "DemandList",
                                                bindex,
                                                "TechniqueSparePartId",
                                            ]}
                                            validateTrigger="onChange"
                                            rules={[noWhitespace(t("inputError"))]}
                                        >
                                            <Select>
                                                {techniqueSpareParts.map(tech => (
                                                    <Option value={tech.id} key={tech.id}>
                                                        {tech.name}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>


                                    <Col lg={12} md={12}>
                                        <div className="form-lang">
                                            <Form.Item
                                                label={t('quantity')}
                                                name={[
                                                    "TaskList",
                                                    index,
                                                    "DemandList",
                                                    bindex,
                                                    "Count",
                                                ]}
                                                validateTrigger="onChange"
                                                rules={[noWhitespace(t("inputError"))]}
                                            >
                                                <InputNumber/>
                                            </Form.Item>
                                            <div className="input-lang btm">ədəd</div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                                    )
                                  }
                               )
                            }
                        </div>
                    ))}


                    <div
                        className="modalButtons"
                        style={{
                            position: "absolute",
                            bottom: "20px",
                            right: "40px",
                        }}
                    >
                        <Button onClick={() =>{props.setVisibleAddModal(false)}}>
                            {t("cancel")}
                        </Button>
                        <Button type="primary" className="ml-10" htmlType="submit">
                            {t("save")}
                        </Button>
                    </div>

                </div>
            </Form>
        </div>
    );
}


export default connect(null, { notify })(AddModal);


