import React , {useState} from 'react';
import {Button, Col, DatePicker, Form, Input, InputNumber, Row, Select} from "antd";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import {useTranslation} from "react-i18next";
const { Option } = Select;
const { TextArea } = Input;

function AddModal(props) {
    const [demandType, setType] = useState(true);
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const submitForm = () =>{
        console.log('super')
    }

    function selectType(value) {
        setType(value)
    }
    return (
        <div className='w-100'>
            <Form onFinish={submitForm} layout="vertical" form={form}>
                <div className="commontask">
                    <Row gutter={[16, 16]}>
                        <Col lg={6} md={12}>
                            <Form.Item
                                label={'Markası'}
                                name="brand"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Select
                                    // onChange={handleSectorChange}
                                >
                                    <Option key={1}>Marka</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={6} md={12}>
                            <Form.Item
                                label={'Modeli'}
                                name="model"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                                <Select
                                    // onChange={handleSectorChange}
                                >
                                    <Option key={1}>Marka</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col lg={6} md={12}>
                            <Form.Item
                                label={'Dövlət nişanı'}
                                name="platenumber"
                                validateTrigger="onChange"
                                rules={[whiteSpace(t("inputError"))]}
                            >
                                <Input size={'large'}/>
                            </Form.Item>
                        </Col>
                        <Col md={6} sm={12}>
                            <Form.Item
                                label={'Tarixi'}
                                name="date"
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("dateError"))]}
                            >
                                <DatePicker placeholder={t("selectDate")} className="w-100" />
                            </Form.Item>
                        </Col>
                        <Col md={24} sm={24}>
                            <Form.Item
                                label={'Qeyd'}
                                name="purpose"
                                validateTrigger="onChange"
                                rules={[whiteSpace(t("inputError"))]}
                            >
                                <TextArea/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className="task1 border mt-15 p-2">
                        <Row gutter={[16,16]}>
                            <Col xs={24}>
                                <div className="w-100 flex-align-center flex flex-between">
                                    <h3>
                                        Tələb {1}
                                    </h3>
                                    <div className="flex">
                                        {/*{products.length > 1 && (*/}
                                        <Button
                                            className="mr5-5 btn-danger"
                                            // onClick={() => removeProduct(index)}
                                        >
                                            {t("delete")}
                                        </Button>
                                        {/*)}*/}
                                        <Button type="primary"
                                            // onClick={addProduct}
                                        >
                                            {t("addTo")}
                                        </Button>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={12}>
                                <Form.Item
                                    label={'Tələbin adı'}
                                    name="demandName"
                                    validateTrigger="onChange"
                                    rules={[whiteSpace(t("inputError"))]}
                                >
                                    <Input size={'large'}/>
                                </Form.Item>
                            </Col>
                            <Col md={6} sm={12}>
                                <Form.Item
                                    label={'Tələbin növü'}
                                    name={"demandType"}
                                    validateTrigger="onChange"
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                    <Select onChange={selectType}>
                                        <Option key={'11'} value={true}>
                                            Daxili
                                        </Option>
                                        <Option key={'22'} value={false}>
                                            Xarici
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            {!demandType &&
                            <Col lg={6} md={12}>
                                <Form.Item
                                    label={'Servis edən şirkət'}
                                    name="demander"
                                    validateTrigger="onChange"
                                    rules={[whiteSpace(t("inputError"))]}
                                >
                                    <Input size={'large'}/>
                                </Form.Item>
                            </Col>
                            }
                            <Col lg={6} md={12}>
                                <Form.Item
                                    label={'Tələb edən şəxs'}
                                    name="demander"
                                    validateTrigger="onChange"
                                    rules={[whiteSpace(t("inputError"))]}
                                >
                                    <Input size={'large'}/>
                                </Form.Item>
                            </Col>
                            <Col lg={6} md={12}>
                                <div className="form-lang">
                                    <Form.Item
                                        label={'Tələbin təxmini məbləği'}
                                        name="money"
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <InputNumber/>
                                    </Form.Item>
                                    <div className="input-lang btm">azn</div>
                                </div>
                            </Col>
                        </Row>
                        {/*add task*/}
                        {/*{products.map((pr, index) => {*/}
                        {/*    return (*/}
                        <div key={'1'} className="task1  border mt-15  p-2">
                            <Row gutter={[16, 16]}>
                                <Col xs={24}>
                                    <div className="w-100 flex-align-center flex flex-between">
                                        <h3>
                                            Ediləcək iş {1}
                                        </h3>
                                        <div className="flex">
                                            {/*{products.length > 1 && (*/}
                                            <Button
                                                className="mr5-5 btn-danger"
                                                // onClick={() => removeProduct(index)}
                                            >
                                                {t("delete")}
                                            </Button>
                                            {/*)}*/}
                                            <Button type="primary"
                                                // onClick={addProduct}
                                            >
                                                {t("addTo")}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>

                                <Col md={8} sm={12}>
                                    <Form.Item
                                        label={'Dəyişilən material'}
                                        // name={["PurchaseProductList", index, "changedMaterial"]}
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select>
                                            <Option key={'111'} value={'111'}>
                                                Material 1
                                            </Option>
                                            <Option key={'222'} value={'222'}>
                                                Material 2
                                            </Option>
                                            <Option key={3} value={3}>
                                                Material 3
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col md={8} sm={12}>
                                    <Form.Item
                                        label={'Siyahı'}
                                        // name={["PurchaseProductList", index, "changedMaterial"]}
                                        validateTrigger="onChange"
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                        <Select>
                                            <Option key={1} value={1}>
                                                Siyahı 1
                                            </Option>
                                            <Option key={2} value={2}>
                                                Siyahı 2
                                            </Option>
                                            <Option key={3} value={3}>
                                                Siyahı 3
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col lg={8} md={12}>
                                    <div className="form-lang">
                                        <Form.Item
                                            label={t('quantity')}
                                            name="suret"
                                            validateTrigger="onChange"
                                            rules={[whiteSpace(t("inputError"))]}
                                        >
                                            <InputNumber/>
                                        </Form.Item>
                                        <div className="input-lang btm">ədəd</div>
                                    </div>
                                </Col>
                            </Row>
                            <div
                                className="modalButtons"
                                style={{
                                    position: "absolute",
                                    bottom: "20px",
                                    right: "40px",
                                }}
                            >
                                <Button onClick={() =>{props.setVisibleAddModal(false)}}>
                                    {t("cancel")}
                                </Button>
                                <Button type="primary" className="ml-10" htmlType="submit">
                                    {t("save")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
}

export default AddModal;
