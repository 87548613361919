import React, { useEffect, useState } from "react";
import {
    DatePicker,
    Col,
    Input,
    InputNumber,
    Form,
    Row,
    Select,
    Button,
} from "antd";
import { connect } from "react-redux";
import { getOptions, notify } from "./../../../../../redux/actions";
import { useTranslation } from "react-i18next";
import moment from "moment";
import agros from "../../../../../const/api";
import { noWhitespace, whiteSpace } from "../../../../../utils/rules";
const { Option } = Select;

const Edit = (props) => {

    const { t } = useTranslation();
    const [form] = Form.useForm();
    const saveItem = (values)=>{
        console.log(values)
    }

    const {  notify } = props;

    return (
        <Form form={form} onFinish={saveItem} layout="vertical">
            <Row gutter={[8, 8]}>
                    <Col lg={6} md={12}>
                        <Form.Item
                            label={'Markası'}
                            name="brand"
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("inputError"))]}
                        >
                            <Select
                                // onChange={handleSectorChange}
                            >
                                <Option key={1}>Marka</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col lg={6} md={12}>
                        <Form.Item
                            label={'Modeli'}
                            name="type"
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("inputError"))]}
                        >
                            <Select
                                // onChange={handleSectorChange}
                            >
                                <Option key={1}>Marka</Option>
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col md={6} sm={12}>
                        <Form.Item
                            label={t("originCountry")}
                            // name={["PurchaseProductList", index, "CountryId"]}
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("inputError"))]}
                        >
                            <Select>
                                <Option key={1} value={1}>
                                    Azərbaycan
                                </Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Item
                            label={'İstehsal tarixi'}
                            name="origindate"
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("dateError"))]}
                        >
                            <DatePicker placeholder={t("selectDate")} className="w-100" />
                        </Form.Item>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Item
                            label={'İstehsal edən şirkət'}
                            name="origindate"
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("dateError"))]}
                        >
                            <Input size={'large'}/>
                        </Form.Item>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Item
                            label={t('dateToBuy')}
                            name="dateToBuy"
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("dateError"))]}
                        >
                            <DatePicker placeholder={t("selectDate")} className="w-100" />
                        </Form.Item>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Item
                            label={'İstifadə məqsədi'}
                            name="purpose"
                            validateTrigger="onChange"
                            rules={[noWhitespace(t("dateError"))]}
                        >
                            <Input size={'large'}/>
                        </Form.Item>
                    </Col>

                </Row>

            <div
                className="modalButtons"
                style={{ position: "absolute", bottom: "20px", right: "40px" }}
            >
                <Button onClick={() => (props.setVEdit(false))}>
                    {t("cancel")}
                </Button>
                <Button type="primary" className="ml-10" htmlType="submit">
                    {t("save")}
                </Button>
            </div>
        </Form>
    );
};



export default connect( null,{  notify })(Edit);
