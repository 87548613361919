import React, {useEffect, useState} from "react";
import { Bar } from '@ant-design/plots';
import {Badge, Button, Popover, Tooltip} from "antd";
import menu from "../../assets/img/icons/menu.svg";
import {AreaChartOutlined} from "@ant-design/icons";

const NewChart = ({mapData}) => {
    console.log(mapData.features)
    // const [data, setData] = useState([]);

    let data = [
        {type: 'Buğda', value: 42.27},
        {type: 'Buğda', value: 13.33},
        {type: 'Buğda', value: 17.79},
        {type: 'Buğda', value: 19.32},
        {type: 'Buğda', value: 29.95},
        {type: 'Buğda', value: 7.04},
        {type: 'Buğda', value: 53.61},
        {type: 'Buğda', value: 34.69},
        {type: 'Yonca', value: 32.3},
        {type: 'Yonca', value: 32.65},
        {type: 'Buğda', value: 29.68},
        {type: 'Şaftalı', value: 7.96},
        {type: 'Alma', value: 4.56},
        {type: 'Alça', value: 15.75},
        {type: 'Qaysı', value: 6.02},
        {type: 'Alça', value: 1.13},
        {type: 'Alma', value: 7.09},
        {type: 'Alça', value: 1.16},
        {type: 'Alma', value: 1.1},
        {type: 'Şaftalı', value: 3.68},
        {type: 'Şaftalı', value: 3.41},
        {type: 'Armud', value: 8.91},
        {type: 'Alma', value: 2.16},
        {type: 'Şaftalı', value: 0.94},
        {type: 'Alma', value: 4.32},
        {type: 'Alma', value: 3.03},
        {type: 'Şaftalı', value: 1.79},
        {type: 'Şaftalı', value: 4.78},
        {type: 'Alma', value: 1.11},
    ]

    // useEffect(()=>{
    //     // let arr = []
    //     // if (mapData.features){
    //     //     mapData.features.map((s)=>{
    //     //         console.log(s)
    //     //         let obj = {
    //     //             type: s.attributes.EKIN,
    //     //             value:s.attributes.AREAS
    //     //         }
    //     //         arr.push(obj)
    //     //     })
    //     //     console.log(arr)
    //     //     setData(arr)
    //     // }
    // } , [mapData])


    const config = {
        data,
        xField: 'value',
        yField: 'type',
        seriesField: 'type',
        legend: {
            position: 'top-left',
        },
    };

    return (
        <div style={{
            position: 'absolute',
            zIndex: '100',
            right: '14px',
            top: '200px'
        }} className={'chart mainBox'}>

            <Tooltip className={'mr-20'} placement="right" title={'Statistika'}>
                <Popover
                    className="ml-20"
                    placement="topLeft"
                    content={
                        <div style={{
                            width:'500px',
                            height:'350px'
                        }}>
                           <span className={'mb-10'}>
                              QEYD: Rəqəmlər hektarı göstərir
                            </span>
                            <Bar style={{height:'300px'}} {...config} />;

                        </div>
                    }
                    trigger="click"
                >
                    <Button style={{fontSize:'20px' , paddingTop:'15px' , paddingBottom:'15px'}} size={'small'} className={' flex all-center'}>
                        <AreaChartOutlined  />
                    </Button>
                </Popover>
            </Tooltip>


        </div>
    )
}

export default NewChart