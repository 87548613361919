import React from 'react';
import { Col, Row} from "antd";
import {AreaChartOutlined} from "@ant-design/icons";
import Images from "../../../../assets/img/Images/Images";
function Pool(props) {
    return (
        <div>
            <Col xs={24}>
                <div className="border page-heading flex p-2 mt-0 bg-white">
                    <div className="page-name">
                        <AreaChartOutlined className="f-20 mr5-15" />
                        <span className="f-20 bold">Hovuzlar</span>
                    </div>
                </div>
            </Col>
            <Row className={'mt-15 '} gutter={[10, 10]}>
                <Col lg={12} >
                    <div className="bg-white animated zoomIn p-2 mt-0 m-5 ">
                        <div className="page-name">
                            {/*<UsergroupAddOutlined className="f-20 mr5-15" />*/}
                            <span className="f-20 bold">Hovuz 1</span>
                        </div>
                        <div className="card">
                            <div className="card-img mr-20">
                                <img src={Images.Pool} alt=""/>
                            </div>
                            <div>
                                <div className="flex mt-20 align-center ">
                                    <p  className={'mr-5'}>Tutumu:</p>
                                    <span>150 t</span>
                                </div>
                                <div className="flex mt-10">
                                    <p  className={'mr-5'}>Şəraiti:</p>
                                    <span>Qapalı</span>
                                </div>
                                <div className="flex mt-10">
                                    <p  className={'mr-5'}>İstifadə:</p>
                                    <span>Yanğın üçün</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={12} >
                    <div className="bg-white animated zoomIn p-2 mt-0 m-5 ">
                        <div className="page-name">
                            {/*<UsergroupAddOutlined className="f-20 mr5-15" />*/}
                            <span className="f-20 bold">Hovuz 2</span>
                        </div>
                        <div className="card">
                            <div className="card-img mr-20">
                                <img src={Images.Pool} alt=""/>
                            </div>
                            <div>
                                <div className="flex mt-10">
                                    <p  className={'mr-5'}>Tutumu:</p>
                                    <span>120 t</span>
                                </div>
                                <div className="flex mt-10">
                                    <p  className={'mr-5'}>Şəraiti:</p>
                                    <span>Açıq</span>
                                </div>
                                <div className="flex mt-10">
                                    <p  className={'mr-5'}>İstifadə:</p>
                                    <span>Suvarma</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={12} >
                    <div className="bg-white animated zoomIn p-2 mt-0 m-5 ">
                        <div className="page-name">
                            {/*<UsergroupAddOutlined className="f-20 mr5-15" />*/}
                            <span className="f-20 bold">Hovuz 3</span>
                        </div>
                        <div className="card">
                            <div className="card-img mr-20">
                                <img src={Images.Pool} alt=""/>
                            </div>
                            <div>
                                <div className="flex mt-10">
                                    <p  className={'mr-5'}>Tutumu:</p>
                                    <span>200 t</span>
                                </div>
                                <div className="flex mt-10">
                                    <p  className={'mr-5'}>Şəraiti:</p>
                                    <span>Açıq</span>
                                </div>
                                <div className="flex mt-10">
                                    <p  className={'mr-5'}>İstifadə:</p>
                                    <span>Yanğın üçün</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={12} >
                    <div className="bg-white animated zoomIn p-2 mt-0 m-5 ">
                        <div className="page-name">
                            {/*<UsergroupAddOutlined className="f-20 mr5-15" />*/}
                            <span className="f-20 bold">Hovuz 4</span>
                        </div>
                        <div className="card">
                            <div className="card-img mr-20">
                                <img src={Images.Pool} alt=""/>
                            </div>
                            <div>
                                <div className="flex mt-10">
                                    <p  className={'mr-5'}>Tutumu:</p>
                                    <span>140 t</span>
                                </div>
                                <div className="flex mt-10">
                                    <p  className={'mr-5'}>Şəraiti:</p>
                                    <span>Qapalı</span>
                                </div>
                                <div className="flex mt-10">
                                    <p  className={'mr-5'}>İstifadə:</p>
                                    <span>Suvarma üçün</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}



export default Pool;
