import React, {useEffect, useState} from 'react';
import {Col, Table, Row, Button, Tooltip, Form, Select, Input} from "antd";
import {useTranslation} from "react-i18next";
import {CompassOutlined, EditFilled, EyeFilled} from "@ant-design/icons";
import {noWhitespace, whiteSpace} from "../../../../../utils/rules";
import {convertColumns} from "../../../../../utils/columnconverter";
import moment from "moment";
import agros from "../../../../../const/api";

function View({detailed , setvisibleView}) {
    const { t } = useTranslation();
    const [tableData , setTableData] = useState([])

    const getData = async () => {
        await agros.get("TechniqueNormative").then((res) => {
            let arr = [];
            res.data.filter(res => res.technique.id === detailed.id).map((p, index) => {
                p['trailerNormatives'].map((trailer , i) => {
                   arr.push({
                       ...trailer,
                       index:i+1,
                       trailerName:trailer.trailer.name
                   })
                })
            })
            setTableData(arr);

        });
    };

    useEffect(() => {
        getData()
    } , [detailed.id])
    const cols = [
        { key: "index", value: "#", con: true },
        { key: "trailer", value: "İstifadə etdilən aqreqat", con: false },
        { key: "speed", value: "Sürət", con: false },
        { key: "area", value: "Sahə", con: false },
        { key: "fuelConsumption", value: "Sərf olunan yanacaq", con: false },
    ];


    const commonColumns = [
        {
            title: "#",
            key: "index",
            dataIndex: "index",
        },
        {
            title: 'İstifadə etdilən aqreqat',
            key:  "trailerName",
            dataIndex: "trailerName",
        },
        {
            title: 'Sürət',
            key: "speed",
            dataIndex: "speed",
            render: (i) => {
                return(
                    <div>{i} km/saat</div>
                )
            }
        },
        {
            title: 'Sahə',
            key: "area",
            dataIndex: "area",
            render: (i) => {
                return(
                    <div>{i} ha</div>
                )
            }
        },
        {
            title: 'Sərf olunan yanacaq',
            key: "fuelConsumption",
            dataIndex: "fuelConsumption",
            render: (i) => {
                return(
                    <div>{i} litr</div>
                )
            }
        },
    ];


    return (
        <div>

            <Row gutter={[16, 16]}>
                <Col lg={12} xs={24}>
                    <table className="customtable">
                        <tbody>
                            <tr>
                                <td>Marka</td>
                                <td>{detailed.mark}</td>
                            </tr>
                            <tr>
                                <td>Moto saat</td>
                                <td>{detailed.engineSent}</td>
                            </tr>
                            <tr>
                                <td>İstehsalçı ölkə</td>
                                <td>{detailed.producingCountry}</td>
                            </tr>
                            <tr>
                                <td>İstehsal tarixi</td>
                                <td>{moment(detailed.dateOfProduction).format('DD-MM-YYYY')}</td>
                            </tr>
                            <tr>
                                <td>İstehsal edən şirkət</td>
                                <td>{detailed.producingCompany}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col lg={12} xs={24}>
                    <table className="customtable">
                        <tbody>
                            <tr>
                                <td>Model</td>
                                <td>{detailed.model}</td>
                            </tr>
                            <tr>
                                <td>Alınma tarixi </td>
                                <td>{moment(detailed.dateOfPurchase).format('DD.MM.YYYY')}</td>
                            </tr>
                            <tr>
                                <td>İstifadə məqsədi</td>
                                <td>{detailed.purposeOfUse}</td>
                            </tr>
                            <tr>
                                <td>Çəkmə gücü</td>
                                <td>{detailed.tractionPower}</td>
                            </tr>
                            <tr>
                                <td>İstifadə etdiyi yanacaq növü </td>
                                <td>{detailed.fuelType}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col xs={24}>
                    <div className="border p-15">
                        <h3>Normativlər</h3>
                    </div>
                </Col>
                <Col xs={24}>
                   <div className="border">
                       <Table
                           size="small"
                           className="bg-white"
                           dataSource={convertColumns(tableData, cols)}
                           columns={commonColumns}
                           pagination={{
                               pageSize: 10,
                               current_page: 1,
                               pageSizeOptions: ["10", "15", "20", "25", "30"],
                           }}
                       />
                   </div>
                </Col>
            </Row>
            <div
                className="modalButtons"
                style={{
                    position: "absolute",
                    bottom: "20px",
                    right: "40px",
                }}
            >
                <Button onClick={() =>{setvisibleView(false)}}>
                    {t("close")}
                </Button>
            </div>
        </div>
    );
}


export default View;
