import React from "react";
import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../../utils/columnconverter";

const View = ({ demand }) => {
  const { t } = useTranslation();
  const cols = [
    {
      key: "parcel",
      value: <div>{t("area")} </div>,
      con: true,
    },
    { key: "demandType", value: t("demandType"), con: true },
    { key: "demandObject", value: t("demandObject"), con: true },
    { key: "amount", value: t("quantityOf"), con: true },
    { key: "country", value: t("countryOfOrigin"), con: true },
    { key: "requiredDate", value: t("dateMustBuy"), con: true },
    { key: "expirationDate", value: t("expirationDate"), con: true },
    { key: "requestingWorker", value: t("demandedPerson"), con: true },
    { key: "id", value: "", con: false },
  ];
  const modalColumns = [
    {
      title: 'Ehtiyat hissəsi',
      key: 1,
      dataIndex: "techniqueSparePart",
      render(i) {
        return <>{i.name}</>;
      }
    },
    {
      title: 'Status',
      key: 1,
      dataIndex: "haveTaken",
      render(i) {
        return <>{i ? 'Alınıb' : 'Alınmayıb'}</>;
      }
    },
    {
      title: t("quantityOf"),
      key: 3,
      dataIndex: "count",
    },
  ];

  const values = demand.taskDemands

  return (
    <Table
      size="small"
      className="bg-white w-100"
      columns={modalColumns}
      dataSource={convertColumns(values, cols)}
      pagination={{ pageSize: 10, current_page: 1 }}
    />
  );
};


export default View;
