import React, {useEffect, useState} from 'react';
// import { Column , Pie, G2  } from "@ant-design/charts";
import { Row, Col } from "antd";
import Images from "../../../../assets/img/Images/Images";
import {Gauge, Liquid} from '@ant-design/plots';
import { Radar } from '@ant-design/plots';
import agros from "../../../../const/api";
import Compas from "../../../Elements/Compas";

function Charts({data}) {

    // gunesh
    const config = {
        percent: parseInt(data?.davis_current_observation?.solar_radiation) / parseInt(data?.davis_current_observation?.solar_radiation_year_high),
        range: {
            color: '#30BF78',
        },
        animation: true,
        indicator: {
            pointer: {
                style: {
                    stroke: '#D0D0D0',
                    strokeOpacity: 0.5,
                },
            },
            pin: {
                style: {
                    stroke: '#D0D0D0',
                    strokeOpacity: 0.5,
                },
            },
        },
        axis: {
            label: {
                formatter(v) {
                    return Number(v) * 100;
                },
                style: {
                    fontSize: 6
                }
            },
            subTickLine: {
                count: 3,
            },
        },
        statistic: {
            content: {
                formatter: ({ percent }) => `${(percent * 100).toFixed(0)}%   ${data?.davis_current_observation?.solar_radiation} W/m2`,
                style: {
                    color: 'rgba(0,0,0,0.65)',
                    fontSize: 10,
                    fontWeight: 600,
                    opacity: 1
                },
            },
        },
    };


    const configliquid = {
        percent: data?.davis_current_observation?.relative_humidity_in / data?.davis_current_observation?.relative_humidity_year_high,
        outline: {
            border: 1,
            color: 'F0EEEE',
            distance: 0,
            style: {
                stroke: '#F0EEEE',
                strokeOpacity: 0.7,
            },
        },
        animation: true,
        wave: {
            length: 50,
        },
        theme: {
            styleSheet: {
                brandColor: '#00C2FF',
            },
        },
    };

    const configwind = {
        percent: parseInt(data?.davis_current_observation?.wind_day_high_mph) / parseInt(data?.davis_current_observation?.wind_year_high_mph),
        range: {
            color: '#30BF78',
        },
        animation: true,
        indicator: {
            pointer: {
                style: {
                    stroke: '#D0D0D0',
                    strokeOpacity: 0.5,
                },
            },
            pin: {
                style: {
                    stroke: '#D0D0D0',
                    strokeOpacity: 0.5,
                },
            },
        },
        axis: {
            label: {
                formatter(v) {
                    return Number(v) * 100;
                },
                style: {
                    fontSize: 6
                }
            },
            subTickLine: {
                count: 3,
            },
        },
        statistic: {
            content: {
                formatter: ({ percent }) => `${(percent * 100).toFixed(0)}% ${data.davis_current_observation?.wind_day_high_mph} mph`,
                style: {
                    color: 'rgba(0,0,0,0.65)',
                    fontSize: 10,
                    fontWeight: 600,
                    opacity: 1
                },
            },
        },
    };


    const datsa = [
        {
            name: 'G2',
            star: 10371,
        },
        {
            name: 'G6',
            star: 7380,
        },
        {
            name: 'F2',
            star: 7414,
        },
        {
            name: 'L7',
            star: 2140,
        },
        {
            name: 'X6',
            star: 660,
        },
        {
            name: 'AVA',
            star: 885,
        },
        {
            name: 'G2Plot',
            star: 1626,
        },
    ];
    const config2 = {
        data: datsa.map((d) => ({ ...d, star: Math.sqrt(d.star) })),
        xField: 'name',
        yField: 'star',
        appendPadding: [0, 10, 0, 10],
        meta: {
            star: {
                alias: 'star ',
                min: 0,
                nice: true,
                formatter: (v) => Number(v).toFixed(2),
            },
        },
        xAxis: {
            tickLine: null,
        },
        yAxis: {
            label: false,
            grid: {
                alternateColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
        point: {
            size: 2,
        },
        area: {},
    };

    return (
        <div className={'mt-15 w-100'}>
            <Row className={'mt-15 '} gutter={[10, 10]}>
                <Col lg={24}>
                    <div className="page-name mt-10 mb-10">
                        <span className="f-20 bold">Hava haqqında</span>
                    </div>
                    <div className="card bg-white animated zoomIn">
                        <div className='flex flex-between flex-align-center mb-65'>
                            <div className="text-center">
                                <div className='gauge-inf'>
                                    <Gauge className='gauge' {...config} />
                                    <span>Max: {data?.davis_current_observation?.solar_radiation_year_high} W/m <sup>2</sup> </span>
                                </div>
                                <div className="mt-10">
                                    <p className='mr-5'>Günəş radiyasiyası</p>
                                </div>
                            </div>
                            <div className="text-center gauge-inf">
                                <Compas direction={data.wind_degrees}/>
                                <div className='mt-15'>
                                    <span>{data.wind_dir}</span>
                                </div>
                                <div className='mt-10'>
                                    <p className='mr-5'>Küləyin indiki səmti</p>
                                </div>
                            </div>
                            <div className="flex-column text-center gauge-inf">
                                <div className='mt-20'>
                                    <img src={Images.Weather} alt="" />
                                    <strong>{ (((data?.davis_current_observation?.temp_in_f - 32)*5)/9).toFixed(1)  }°<sup>C</sup></strong>
                                </div>
                                <div className='mt-50'>
                                    <p className='mr-5'>Havanın dərəcəsi</p>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-between flex-align-center mb-65'>
                            <div className="text-center">
                                <div className='gauge-inf'>
                                    <Gauge className='gauge' {...configwind} />
                                    <span>Max: {data?.davis_current_observation?.wind_year_high_mph} mph</span>
                                </div>
                                <div className="mt-10">
                                    <p className='mr-5'>Küləyin sürəti</p>
                                </div>
                            </div>
                            <div className="flex-column text-center gauge-inf">
                                <div className='mb-20'>
                                    <Radar className="radar" {...config2} />
                                </div>
                                <div className='mt-30'>
                                    <p className='mr-5'>Küləklərin ümümi səmti</p>
                                </div>
                            </div>
                            <div className="flex-column text-center gauge-inf mt-25">
                                <Liquid className='liquid mt-10 mb-10' {...configliquid} />
                                {/*<div className='mt-15'>*/}
                                {/*    <span>Nəmli</span>*/}
                                {/*</div>*/}
                                <div className='mt-20'>
                                    <p className='mr-5'>Havanın nəmliyi  {(configliquid.percent * 100).toFixed(2)} %</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col lg={24}>
                    <div className="page-name mt-10 mb-10">
                        <span className="f-20 bold">Torpaq </span>
                    </div>
                    <div className="card bg-white animated zoomIn">
                        <div className='flex flex-between flex-align-center mb-50'>
                            <div className="flex-column text-center">
                                <div className='mt-25 mb-20'>
                                    <img src={Images.Land} alt="" />
                                </div>
                                <div className='flex text-center dir-column'>
                                    <p className='mr-5'>Torpağın nəmliyi:</p>
                                    <div>İndiki - {data?.davis_current_observation?.relative_humidity_in}  cp</div>
                                    <div>Max - {data?.davis_current_observation?.relative_humidity_day_high}  cp</div>
                                </div>
                            </div>
                            <div className="flex-column text-center">
                                <div className='mt-25 mb-20'>
                                    <img src={Images.Land} alt="" />
                                </div>
                                <div className='flex text-center  dir-column'>
                                    <p className='mr-5'>Torpağın dərəcəsi:</p>
                                    <div>İndiki - {data?.davis_current_observation?.temp_soil_1}  C</div>
                                    <div>Max - {data?.davis_current_observation?.temp_soil_1_year_high}  C</div>
                                </div>
                            </div>
                            <div className="flex-column text-center">
                                <div className='mt-25 mb-20'>
                                    <img src={Images.Leaf} alt="" />
                                </div>
                                <div className='flex text-center  dir-column'>
                                    <p className='mr-5'>Yarpağın dərəcəsi:</p>
                                    <div>İndiki - {data?.davis_current_observation?.temp_leaf_1}  C</div>
                                    <div>Max - {data?.davis_current_observation?.temp_leaf_1_year_high}  C</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};


export default Charts;
