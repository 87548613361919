import React, {useEffect, useState} from "react";
import { MapContainer, TileLayer , Marker , Popup , Tooltip } from "react-leaflet";
import Routing from "./Routing";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {Button, Col, Spin , Row} from "antd";
import {Link} from "react-router-dom";
import './map.css'
import agros from "../../../const/api";
import {Gauge} from "@ant-design/plots";

const NewMap = ({pergo , stansions}) => {

    let [trigger, setTrigger] = useState(0)
    const [stationData, setStationData] = useState({})
    const [spin, setSpin] = useState(false)

    setInterval(()=>{
        setTrigger(++trigger)
    } , 2000)


    const config = {
        range: {
            color: '#30BF78',
        },
        indicator: {
            pointer: {
                style: {
                    stroke: '#D0D0D0',
                    strokeOpacity: 0.5,
                },
            },
            pin: {
                style: {
                    stroke: '#D0D0D0',
                    strokeOpacity: 0.5,
                },
            },
        },
        axis: {
            label: {
                formatter(v) {
                    return Number(v) * 100;
                },
            },
            subTickLine: {
                count: 3,
            },
        },

    };

    const getStationData = async (id) => {
        console.log(id)
        setSpin(true)
        await agros.get(`weatherlink/v2/station/${id}`).then((res)=>{
            setStationData(res.data)
            console.log(res)
        }).finally(()=>{
            setSpin(false)
        })
    }



    const position = [40.2725518,48.434887];
    const myIcon = L.icon({
        iconUrl: require('./../../../assets/img/icons/stansiya.svg'),
        iconSize: [45,45],
        iconAnchor: [32, 32],
        popupAnchor: [-25,-25],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });


    const myIcon2 = L.icon({
        iconUrl: require('./../../../assets/img/icons/cars.svg'),
        iconSize: [70,70],
        iconAnchor: [10, 10],
        popupAnchor: [-5,-5],
        shadowUrl: null,
        shadowSize: null,
        shadowAnchor: null
    });

  return (
      <>
          <div style={{
              position: 'absolute',
              zIndex: '100',
              right: '35px',
              bottom:'40px',
              width:'400px',
              height:'220px'
          }} className={'chart mainBox p-2 bg-white'}>
              <Row>
                  {pergo.map((s , i) => (
                      <Col key={i} lg={12} xs={12}>
                          <Gauge style={{width:'100%' , height:'120px' }} percent={s['carLocation'][trigger].speed > 0  ? s['carLocation'][trigger].speed/100 : 0.1} {...config} />
                          <div className={'text-center'}>{s['carLocation'][trigger].speed} km/saat</div>
                          <div className={'text-center'}>  Maşın {s.carCode}</div>
                      </Col>
                  ))}
              </Row>
          </div>

          <MapContainer center={position} zoom={8} style={{ height: "83.5vh"  , overflow:'hidden' , zIndex:0}}>
              <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              {stansions.map((s , i) =>(
                  <Marker onClick={()=>{getStationData(s.id)}} key={i} icon={myIcon} position={[s.latitude, s.longitude]}>
                      <Popup style={{maxWidth:'500px'}}>
                          <div className={'p-1 flex all-center'}>
                              Stansiya: {s.id} <br/>
                              <Link  className={'text-dark ml-15'} to={`integratons/analyzes/#${s.id}`}>
                                  <Button type={'primary'}>
                                      Ətraflı
                                  </Button>
                                  {/*<Spin spinning={spin}>*/}
                                  {/*   <div>*/}
                                  {/*       <Charts  data={stationData} />*/}
                                  {/*   </div>*/}
                                  {/*</Spin>*/}
                              </Link>
                          </div>
                      </Popup>
                  </Marker>
              ) )}


              {pergo.map((s ,i)=>
                  {
                      return (
                          <Marker key={i} icon={myIcon2} position={[s['carLocation'][trigger].latitude , s['carLocation'][trigger].longitude]}>
                              <div className="p-1"> <Popup>
                                  Maşın {s.carCode}
                                  <br/>
                                  Sürət {s['carLocation'][trigger].speed} km/saat
                              </Popup></div>
                          </Marker>
                          // <Routing s={s} trigger={trigger} />
                      )
                  }
              )}




          </MapContainer>
      </>
  )
}


export default NewMap