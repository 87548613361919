export let az;
az = {
  // sidebar links
  admin: "İnzibatçı",
  positions: "Vəzifələr",
  permissions: "Səlahiyyətlər",
  users: "İstifadəçilər",
  storageSettings: "Anbar tənzimləmələri",
  areas: "Torpaq sahələri",
  typeAndConditions: "Növ və şərtləri",
  clientAndConsumers: "Müştəri və tədarükçülər",
  todos: "Ediləcək işlər",
  humanResources: "İnsan resursları",
  workers: "İşçilər",
  dependencyTable: "Tabel cədvəli",
  warehouse: "Anbar",
  demands: "Tələblər",
  purchases: "Satınalmalar",
  purchasesOnWait: "Gözləyən alınacaqlar",
  drugAndFertilizersWarehouse: "Dərman və gübrələr anbarı",
  productWarehouse: "Məhsul anbarı",
  reserveWarehouse: "Ehtiyatlar anbarı",
  history: "Tarixçə",
  businessPlanning: "İş planlaması",
  daily: "Günlük",
  yearly: "İllik",
  financeAdmin: "Maliyyə inzibatçısı",
  operationTypes: "Əməliyyat növləri",
  paymentTypes: "Ödəniş növləri",
  accountTypes: "Bank/hesab növləri",
  areaNames: "Sahə adları",
  customers: "Müştərilər",
  operationPoints: "Əməliyyat maddələri",
  sectors: "Sektorlar",
  sector: "Sektor",
  dailyFinancialReports: "Günlük maliyyə hesabatları",
  reports: "Hesabatlar",
  // bottom menu lang and settings
  langSelection: "Dil seçimi",
  support: "Dəstək",
  instruction: "Istifadə qaydaları",
  termsOfUse: "İstifadə şərtləri",
  feedback: "Geri bildirim",
  dakMode: "Qaranlıq Mod",

  // validationMessages
  inputError: "Xana doldurulmalıdır",
  dateError: "Tarix seçilməlidir",
  mailError: "Mail düzgün daxil edilməlidir",

  // common page headings
  name: "Adı",
  fullName: "Tam adı",
  fullnameLabel: "Ad, soyad, ata adı",
  officalName: "Rəsmi adı",
  phone: "Telefon",
  contactNumber: "Əlaqə nömrəsi",
  country: "Ölkə",
  city: "Şəhər",
  adress: "Ünvan",
  industry: "Sənaye",
  relatedPerson: "Əlaqəli şəxs",
  eMail: "E-poçt",
  email: "Email",
  addTo: "Əlavə et",
  edit: "Redaktə et",
  delete: "Sil",
  save: "Yadda saxla",
  cancel: "Ləğv et",
  approved: "Təsdiq edilib",
  accepted: "Qəbul edilib",
  doConfirm: "Təsdiq et",
  canceled: "Ləğv edilib",
  onWait: "Gözləyən",
  close: "Bağla",
  type: "Növ",
  typeOf: "Növü",
  note: "Qeyd",
  additionalNote: "Əlavə qeyd",
  quantity: "Miqdar",
  quantityOf: "Miqdarı",
  productName: "Məhsulun adı",
  product: "Məhsul",
  heading: "Başlıq",
  detailed: "Ətraflı bax",
  detailedInfo: "Ətraflı məlumat",
  selectDate: "Tarixi seçin",
  areYouSure: "Silmək istədiyinizə əminsinizmi ?",
  yes: "Bəli",
  no: "Xeyr",
  // positions page
  responsibility: "Cavabdehlik",
  positionName: "Vəzifənin adı",

  // permissions page
  authorityGroups: "Səlahiyyət qrupları",
  read: "Oxumaq",
  write: "Yazmaq",
  editTo: "Redaktə etmək",
  deleteTo: "Silmək",

  readSectors: "Sektorlara bax",
  readCrops: "Məhsullara bax",
  addCrop: "Məhsul əlavə et",
  deleteCrop: "Məhsulu sil",
  updateCrop: "Məhsula düzəliş et",

  hr: "İnsan resursları",
  readSalaryHistory: "Əmək haqqı tarixçəsinə bax",
  createSalaryHistory: "Əmək haqqı tarixçəsinə əlavə et",

  readDemand: "Tələbə bax",

  // userspage
  username: "İstifadəçi adı",
  newPassword: "Yeni şifrə",
  worker: "İşçi",
  positionGroup: "Vəzifə qrupu",

  // storage-settings
  warehouses: "Anbarlar",
  warehouseName: "Anbarın adı",
  foodProducts: "Qida məhsulları",
  drugAndFertilizers: "Dərman və gübrələr",
  drugAndFertilizer: "Dərman və gübrə",
  activeSubstance: "Təsiredici maddə",
  activeSubstances: "Təsiredici maddələr",
  activeSubstanceName: "Təsiredici maddənin adı",
  fertilizer: "Gübrə",
  medicine: "Dərman",
  kilograms: "kiloqram",
  meters: "metr",
  liter: "litr",
  pieces: "Ədəd",
  hundredliterRatio: "100l nisbəti",
  measurementUnit: "Ölçü vahidi",
  plantProducts: "Bitki məhsulları",
  plantProductsSorts: "Bitki məhsullarının növləri",
  reproduction: "Reproduksiya",
  reserves: "Ehtiyatlar",
  reserv: "Ehtiyat",
  reservName: "Ehtiyatın adı",
  addReserve: "Ehtiyat əlavə et",

  // areas page
  LandAreaRegulations: "Torpaq sahəsi tənzimləmələri",
  region: "Region",
  category: "Kateqoriya",
  areaName: "Sahənin adı",
  area: "Sahə",
  treeNumber: "Ağac sayı",
  respondentPeople: "Cavabdeh şəxs",
  respondent: "Cavabdeh",
  company: "Şirkət",
  position: "Vəzifə",
  createdDate: "Yaranma tarixi",

  // typeAndConditions page
  paymentRules: "Ödəniş qaydaları",
  deliveryTerms: "Çatdırılma şərtləri",

  // client-and-consumers
  allOf: "Hamısı",
  client: "Müştəri",
  clients: "Müştərilər",
  consumer: "Tədarükçü",
  agreementDocumentNumber: "Razılaşma sənədin nömrəsi",
  agreementDocumentDate: "Razılaşma sənədin tarixi",
  paymentTerm: "Ödəniş şərti",
  paymentType: "Ödəniş növü",
  paymentTime: "Ödəniş müddəti",
  deliveryDate: "Çatdırılma müddəti",
  deliveryTern: "Çatdırılma şərti",
  status: "Status",
  advancePaymentCondition: "Avans ödəniş şərti",
  advanceCondition: "Avans şərti",
  advancePaymentType: "Avans ödəmə növü",
  advancePaymentTime: "Avans ödəmə müddəti",

  // human-resources/workers
  workStartDate: "İşə başlama tarixi",
  totalSalary: "Ümumi əmək haqqı",
  salary: "Net əmək haqqı",
  salaryForm: "Əmək haqqı forması",
  salaryType: "Maaş forması",
  dailySalary: "Günə muzd",
  salaryForWork: "İşə muzd",
  fin: "Ş/V fin kodu",
  seriaNo: "Ş/V seriya nömrəsi",
  birthdate: "Doğum tarixi",
  sex: "Cinsi",

  // warehouse/demands
  demand: "Tələb",
  demmandNo: "Tələb №",
  demandType: "Tələbin növü",
  demandObject: "Tələb obyekti",
  demandName: "Tələbin adı",
  demandedPerson: "Tələb edən",
  countryOfOrigin: "İstehsal ölkəsi",
  originCountry: "İstehsalçı ölkə",
  dateMustBuy: "Alınmalı tarix",
  expirationDate: "Son istifadə tarixi",
  areaCategory: "Sahə kateqoriyası",

  // warehouse/purchases
  approveds: "Təsdiq edilən",
  preparedDocuments: "Hazırlanan sənədlər",
  changeStatus: "Statusu dəyiş",
  dateToBuy: "Alınma tarixi",
  endDate: "Bitmə tarixi",
  startDate: "Başlama tarixi",
  supplier: "Təchizatçı",
  supplierConnection: "Təchizatçı əlaqəsi",
  DateOfApproval: "Təsdiq edilmə tarixi",
  approvedPerson: "Təsdiq edən",
  createPurchaseDocument: "Satınalma sənədi yarat",
  createNewPurchaseDocument: "Yeni Satınalma sənədi yarat",
  viewPurchaseDocument: "Satınalma sənədinə bax",
  customsCosts: "Gömrük xərcləri",
  customsDuties: "Gömrük rüsumu",
  transportCosts: "Nəqliyyat xərcləri",
  transportDuties: "Nəqliyyat rüsumu",
  mustDeliverDate: "Çatdırılmalı tarix",
  lastPaymentDate: "Son ödəniləcək tarix",
  productType: "Məhsul növü",
  productCategory: "Məhsul kateqoriyası",
  productSorts: "Məhsulun sortu",
  price: "Qiymət",
  discount: "Endirim",
  AdditionalTax: "Əlavə dəyər vergisi",
  finalPrice: "Yekun qiymət",
  activityOf: "Fəaliyyəti",
  extraIncome: "Əlavə gəlir",
  search: "Axtar",
  approximateTransportFee: "Təxmini nəqliyyat rüsumu",
  ApproximatePriceProducts: "Məhsulların təxmini qiyməti",

  // warehouse/purchases-on-wait
  addToReserves: "Ehtiyatlara əlavə et",
  barCode: "Barkod",
  // drug-and-fertilizers
  using: "İstifadə",
  documentNumber: "Sənəd Nömrəsi",
  documents: "Sənədlər",
  inReserves: "Ehtiyatda olanlar",
  import: "Mədaxil",
  export: "Məxaric",
  tasks: "Tapşırıqlar",
  task: "Tapşırıq",
  operation: "Əməliyyat",
  mainIngridient: "Əsas maddə",
  print: "Çap et",
  person: "Şəxs",

  // warehouse/products
  sale: "Satış",
  sortOf: "Sortu",
  barCodeOf: "Barkodu",
  areaType: "Sahə növü",
  handingOver: "Təhvil verən",
  carNumber: "Maşın nömrəsi",
  addNewProduct: "Yeni məhsul əlavə et",
  addProduct: "Məhsul əlavə et",
  acceptedPerson: "Qəbul edən Şəxs",
  sendedPerson: "Ötürən Şəxs",
  acceptedCarNumber: "Qəbul edənin N/V nömrəsi",
  productQuantity: "Məhsulun Miqdarı",
  importExportDocuments: "Mədaxil/Məxaric sənədləri",

  // eservespage
  acceptedDate: "Qəbul tarixi",

  // historyPage
  operationHistory: "Əməliyyat tarixçəsi",
  buyer: "Alan",

  // daily page
  dailyPlaning: "Günlük İş planlamalarının idarə edilməsi",
  finishedPlans: "Bitmiş İş Planları",
  WorkPlanName: "İş planının adı",
  yearlyWorkPlan: "Illik iş planlaması",
  totalArea: "Ümumi sahə",
  workWillDo: "Ediləcək iş",
  manWorkerNumber: "Kişi işçi sayı",
  womanWorkerNumber: "Qadın işçi sayı",
  workerNumber: "İşçi sayı",
  addtask: "Task əlavə et",
  addMedicine: "Dərman əlavə et",
  finalAmount: "Yekun miqdar",
  notStartedTasks: "Başlanmamış tasklar",
  tasksOnDone: "Icra olunan tasklar",
  finishedTasks: "Bitmiş tasklar",
  date: "Tarix",

  // yearly
  yearlyPlaning: "İllik İş planlamalarının idarə edilməsi",
  parsel: "Sahə",
  periods: "Dövrlər",
  period: "Dövr",
  addSector: "Sektor əlavə et",
  addPeriod: "Dövr əlavə et",
  workPlanWillDone: "Ediləcək iş planı",
  workWillDone: "Ediləcək iş",
  addWorkWillDonw: "İş planı əlavə et",
  operationItem: "Əməliyyat maddəsi",
  bankAccountName: "Bank/Hesab adı",

  // daily financial reports
  dailyreports: "Günlük maliyyə hesabatı",
  report: "Hesabat",

  // new added words
  savedMessage: "Dəyişikliklər yadda saxlanıldı",
  addPasswordError: "Şifrəni təyin edin",
  minimumPasswordError: "Şifrə minimal 6 simvol olmalıdır",
  workerMustSelectError: "İşçi seçilməlidir",
  permissionMustSelectError: "Səlahiyyət növü seçilməlidir",
  itemMustSelectError: "Maddə seçilməlidir",
  measurementUnitMustSelectError: "Ölçü vahidi seçilməlidir",
  typeMustBeSelected: "Növ seçilməlidir",
  positionMustSelectError: "Vəzifə seçilməlidir",
  regionMustSelectError: "Region seçilməlidir",
  categoryMustSelectError: "Kateqoriya seçilməlidir",
  selectCropSort: "Sahə növü seçilməlidir",
  selectCrop: "Sahə seçilməlidir",
  addNewArea: "Yeni sahə əlavə et",
  crops: "Crops",
  successMessage: "Əməliyyat uğurlu oldu.",
  errMessage: "Əməliyyat uğursuz oldu.",
  errorMessage: "Xəta baş verdi",
  newDemandCreated: "Yeni tələb yaradıldı.",
  newDocumentCreated: "Yeni sənəd yaradıldı.",
  areaIsAdded: "Sahə əlavə edildi.",
  importIsAdded: "Yeni mədaxil edildi.",
  productIsAdded: "Məhsul anbara əlavə edildi",
  planIsAdded: "Plan əlavə edildi.",
  clientIsAdded: "Müştəri əlavə edildi.",
  clientDeleted: "Müştəri silindi.",
  workerDeleted: "İşçi sistemdən silindi.",
  workerIsAdded: "Yeni işçi yaradıldı.",
  addNewWorker: "Yeni işçi əlavə et",
  sectorName: "Sektorun adı",
  sectorArea: "Sektorun sahəsi",
  products: "Məhsullar",
  addNewClient: "Yeni müştəri/təchizatçı əlavə et",
  operations: "Əməliyyatlar",
  salaryHistory: "Əməkhaqqı tarixçəsi",
  man: "Kişi",
  woman: "Qadın",
  createNewDemand: "Yeni tələb yarat",
  moneyQuantity: "Məbləğ",
  operationType: "Əməliyyat növü",
  thereIs: "Var",
  thereNo: "Yoxdur",
  ApproximatecustomsCosts: "Təxmini gömrük rüsumu",
  barcodeMustAdd: "Barkod daxil edilməlidir",
  warehouseMustSelect: "Anbar seçilməlidir",
  quantityMustAdd: "Miqdar daxil edilməlidir",
  isUsed: "İstifadə olunub",
  isNotUsed: "İstifadə olunmayıb",
  newExoort: "Yeni məxaric",
  areasSector: "Sahənin sektoru",
  productMustSelect: "Məhsul seçilməlidir",
  areYouSureEndTask: "Taskı bitirmək istəyirsiniz?",
  endTask: "Taskı bitir",
  newReport: "Yeni hesabat",
  shortStory: "Qısa açıqlama",
  rejected: "Rədd edilib",
  isEndsDate: "Sona çatma tarixi",
  createNewTask: "Yeni tapşırıq yarat",
  settings: "Tənzimləmələr",
  notifications: "Bildirişlər",
  login: "Daxil ol",
  register: "Qeydiyyatdan keç",
  activeWorkPlans: "Aktiv İş Planları",
  createNewWorkPlan: "Yeni iş planı yarat",
  mustSelectWorkPlan: "İş planlaması seçilməlidir",
  sectorMustSelect: "Sektor seçilməlidir",
  workMustSelect: "İş seçilməlidir",
  workWillMustSelect: "Ediləcək iş seçilməlidir",
  personMustSelect: "Şəxs seçilməlidir",
  numberMustAdd: "Say daxil edilməlidir",
  medicines: "Dərmanlar",
  personCount: "nəfər",
  logOut: "Çıxış",
  password: "Şifrə",
  EMailFull: "Elektron poçt",
  // permissions
  administrator: "İnzibatçı",
  create: "Əlavə etmək",
  update: "Yeniləmək",
  deleteSector: "Sektoru silmək",
  updateSector: "Sektoru yeniləmək",
  warehouseSettings: "Anbar tənzimləmələri",
  drugandFertilizers: "Dərman və gübrələr",
  mainIngredients: "Təsiredici maddələr",
  cropCategories: "Məhsul kateqoriyaları",
  plantProductSorts: "Bitki məhsulları sortları",
  reproductions: "Reproduksiya",
  typesAndConditions: "Növ və şərtlər",
  landParcels: "Torpaq sahələri",
  paymentTerms: "Ödəmə şərtləri",
  paymentKinds: "Ödəmə növləri",
  waiting: "Gözləyən",
  preparing: "Hazırlanan",
  importAndExport: "Mədaxil/Məxaric sənədləri",
  importExportAndDocs: "Mədaxil/Məxaric sənədləri",
  excelExport: "Excel kimi yüklə",
  productsWarehouse: "Məhsul Anbarı",
  reservesWarehouse: "Ehtiyatlar anbarı",
  workplan: "İş planı",
  addTask: "Task əlavə et",
  doneWorkPlans: "Bitmiş iş planları",
  annual: "İllik",
  financeAdminstrator: "Maliyyə inzibatçısı",
  // new
  warehouseCategories:'Anbar kateqoriyası',

  links: {
    admin: "inzibatci",
    positions: "vezifeler",
    permissions: "selahiyyetler",
    users: "istifadeciler",
    storageSettings: "anbar-tenzimlemeleri",
    areas: "torpaq-saheleri",
    typeAndConditions: "nov-və-shertleri",
    clientAndConsumers: "mushteri-ve-tedarukculer",
    todos: "edilecek-ishler",
    humanResources: "insan-resuslari",
    workers: "ishciler",
    dependencyTable: "tabel-cedveli",
    warehouse: "anbar",
    demands: "telebler",
    purchases: "satinalmalar",
    purchasesOnWait: "gozleyen-alinacaqlar",
    drugAndFertilizersWarehouse: "derman-ve-gubreler",
    productWarehouse: "mehsul-anbari",
    reserveWarehouse: "ehtiyatlar-anbari",
    history: "tarixce",
    businessPlanning: "ish-planlamasi",
    daily: "gunluk",
    yearly: "illik",
    financeAdmin: "maliyyə-inzibatcisi",
    operationTypes: "emeliyyat-novleri",
    paymentTypes: "odenish-novleri",
    accountTypes: "bank-hesab-novleri",
    areaNames: "sahe-adlari",
    customers: "mushteriler",
    operationPoints: "emeliyyat-maddeleri",
    sectors: "sektorlar",
    dailyFinancialReports: "günlük-maliyye-hesabatlari",
    // new added
    reports: "hesabatlar",
  },
  tools: "Alətlər",
  tool: "Alət",

  // layout pages
  // home
  slide1: 'Aqroparkaların mərkəzləşmiş idarə sistemi.',
  subslide1: 'Bu proqram təyinatı Aqroparkların idarə etmək üçün nəzərdə tutalmuş sistem,  sizin işinizin effektiv aparılması və daha optimal idarə etmək imkanını yaradır.',

  slide2: 'Azərbaycan məhsulu.',
  subslide2: 'Proqram təyinatı daxili təcrübəyə əsasən yaradılıb. Bizim komandamızın məqsədi məhs Azərbaycan məhsulu yaratmaqdır. Bu məqsədimizə addım və addım yaxınlaşırıq.',

  slide3 : 'Daim yenilənən və uyğunlaşan proqram təyinatı.',
  subslide3: 'Proqram yenilənir və müştərilərin istəklərini nəzərə alaraq uyğunlaşır, müştərilirımizin aqroparkda olan çətinlikləri  və problemləri aradan qaldırmaq üçün araşdırıb proqramı dahada effektiv edirik.',

  layoutLinks: {
    home: 'ANA SƏHİFƏ',
    about : 'HAQQIMIZDA',
    products : 'MƏHSULLAR',
    packs : 'PAKET',
  },
  aboutCompany: '2018-ci ildən fəaliyyətə başlayan "TimeSoft" şirkəti hazırda Azərbaycanda IT sahəsində fəaliyyət göstərən qabaqcıl şirkətlərdən biridir. Şirkətimizin fəaliyyəti Azərbaycanın müxtəlif müəssisələrindəki biznes və dövlət idarəçiliyində görülən işləri avtomatlaşdırmaq və məhsuldarlığının, effektivliyinin artırılması, həmçinin optimallaşdırılmasına xidmət edir. Bu hədəfə çatmaq üçün İnformasiya Texnologiyalarından effektiv istifadə etməklə vaxta qənaət və daha səmərəli iş prosesi əldə edilir. Biz qısa zaman ərzində bu sahədə bir sıra uğurlu proyektlərə imza atmışıq və ölkəmizdə bir sıra yeni platformalar hazırlamışıq.',

  aboutAgros: 'Agros.az platforması kənd təsərrüfatı məhsullarının qiymətləndirilməsini aparmaq, müxtəlif təsərrüfatlara məxsus məhsulların qarşılıqlı qiymətlərini göstərmək məqsədi ilə yaradılmışdır. Platforma alıcıya seçim etmək, müxtəlif məhsulların qiymətini dəyərləndirmək imkanı verməklə, sahibkarlara, ümumiyyətlə kənd təsərrüfatı ilə maraqlanan hər bir şəxsə faydalı olacaq.',
  aboutAgros2: 'Şirkətlər bu rəqabət yönümlü dövürdə, bazarda qalmaqdan ötrü, məhsulların və şirkətin məhsuldarlığını artırmaq üçün bir çox vasaitləri istifadə edilər. Ya xərclərini azaltmağa çalışırlıar və ya yeni kommersiya yolları axtarırlar. Bu proqramı yaratmaq məqsədimiz məhs şirkətlərin həm zaman və həm də material resurslarını daha effektiv istifadə etmək üçün yaradılıb. Kənd təsərrüfatı sahəsində fəaliyyət göstərən əkinçi və fermerlərin işlərinin idarəsində böyük köməyi olacaqdır. Məsələn: bir AgroParkın əkin planlamasını, iş planlamasını, Suvarma sistemlərini, Anbar və ehtiyatlarını, İnsan resursları və Maliyyə məsələlərini tam əks etdirərək bütün işlər üzrə asanlıq yaradır. Kamera, Turniket, Nəqliyyatların izlənməsi kimi başqa xidmətləri də daxilinə inteqrasiya edərək xəritə üzərindən öz ərazilərini və nəqliyyatlarını görərək onları planlaya,idarə edə biləcəklər.',


  aboutProduct:'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Dui faucibus in ornare quam viverra. Sit amet aliquam id diam maecenas ultricies mi eget mauris. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend. Ac turpis egestas integer eget aliquet nibh praesent tristique. Nibh molestie a iaculis at erat pellentesque. Urna nunc id cursus metus aliquam eleifend mi. Consequat semper viverra nam libero justo. Gravida rutrum quisque non tellus orci ac. Diam donec adipiscing tristique risus nec feugiat in',
  aboutHr:'İnsan Resursları bölməsində bütün əməkdaşların şəxsi məlumatları saxlanılır. Bu modulda əməkdaşların iş bölgüsü, sahədə işləyən əməkdaşların işəmuzd əmək haqqlarının avtomatlaşdırılması nəzərdə tutulmuşdur.  Həmçinin, məlumat bazasına uyğun olaraq statistik hesabatlar öz əksini tapır. İşçilər sistemə daxil edildikdən sonra, onlar haqqında olan tarixçəli məlumatlar saxlanılır.',
  aboutManagement: 'Yerli parkın əsas problemlərdən biri sahəyə aid tapşırıqlardı. “Tapşırıqları idarə edilməsi” məhsul vasitəsi ilə siz işçilərin tapşırıqlarının yerinə yetirmək, effektliyini görə bilərsiz. Bu modulda siz illik planı yaradıb, sonra isə illik plana  əsasən gundəlik planı yaradırsız, yerinə yetirildiyi təqdirdə tapşırığa məsulliyyət daşıyan şəxs hesabat yazır. Statistikada siz illik planı, gündəlik planı və həmdə tapşırıq yerinə yetirdikdən sonra verilən hesabatı müqayisə edə bilərsiz ki nə qədər resurs istifade edilib. Əgər resurslar çatışmırsa onda keçid edir satınalma və anbar proseslərinə.',
  aboutWarehouse: 'Bu modulda aqroparkın bütün satınalma prosesləri və anbar prosesləri göstərir. İk öncə tələb yaranır, sonra bu satınalamaya düşür və təsdiqlədikdən sonra məhsul barkod olunur anabra gedir. Orada anabar səhifəsində siz mədaxil, məxaric və transfer edə bilərsiz.',
  // headings
  manegment:'Tapşırıqların idarə edilməsi',
  // packs page
  garage: 'Qaraj',
  storage: 'Yaddaş',
  user:'İstifadəçi',
  userCost:'İstifadəçi məbləği',
  storageCost:'Yaddaş məbləği',
  packCost:'Paket məbləği',
  additionalCost:'Əlavələr məbləği',
  finalCost:'Əlavələr məbləği',
  continue: 'Davam et',
  // registerform
  companyInfo: 'Şirkət məlumatları',
  companyName: 'Şirkət adı',
  companyEmail: 'Şirkət emaili',
  remember:'Yadda saxla',
  entry: 'GİRİŞ',
};


