import React from 'react';
import {Button, Col, Row, Tooltip} from 'antd';
import {DeleteColumnOutlined} from "@ant-design/icons";
import Images from "../../../../assets/img/Images/Images";

function Pivots(props) {
    const pivots = [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
    ]
    return (
        <div className={'mt-15'}>
            <Col xs={24}>
                <div className="border page-heading flex p-2 mt-0 bg-white">
                    <div className="page-name">
                        <DeleteColumnOutlined className="f-20 mr5-15" />
                        <span className="f-20 bold">Pivotlar</span>
                    </div>
                </div>
            </Col>
            <Row className={'mt-15 '} gutter={[10, 10]}>
                {pivots.map((p, i)=>{
                    return  <Col key={i} lg={3} md={6} xs={12} >
                            <Tooltip placement="bottomLeft" title={`Pivot ${i+1}`} >
                                <div className="bg-white animated zoomIn flex all-center p-2 mt-0 m-5 ">
                                    <Button type="primary" shape="circle" className="pivots-button">
                                        {i+1}
                                    </Button>
                                </div>
                            </Tooltip>
                        </Col>
                })}

            </Row>
        </div>
    );
}


export default Pivots;
