import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Table,
    Button,
    Tooltip,
    Popconfirm, Modal,
} from "antd";
import {
    UnorderedListOutlined,
    FileMarkdownFilled,
    EditFilled,
    DeleteFilled, FileSearchOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../../utils/columnconverter";
import { getOptions, notify } from "../../../../../redux/actions";
import { connect } from "react-redux";
import Authorize from "../../../../Elements/Authorize";
import moment from "moment";
import PPurchase from "../Modals/PPurchase";

const PurchaseList = (props) => {
    const { t, i18n } = useTranslation();

    const [visiblePPurchase, setVisiblePPurchase] = useState(false);
    const [id, setId] = useState(null)

    useEffect(() => {
        console.log(props)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);


    const  viewPurchaseDetails  = (id) =>{
        setId(id)
        setVisiblePPurchase(true)
    }

    const cols = [
        { key: "tableIndex", value: "#", con: true },
        { key: "customerName", value: t("supplier"), con: true },
        { key: "customerPhone", value: t("supplierConnection"), con: true },
        { key: "approvedDate", value: t("DateOfApproval"), con: true },
        { key: "approvedWorkerName", value: t("approvedPerson"), con: true },
    ];



    // table data columns
    const listColumns = [
        {
            title: "#",
            key: 1,
            dataIndex: "tableIndex",
        },
        {
            title: t("supplier"),
            key: 2,
            dataIndex: "customerName",
        },
        {
            title: t("supplierConnection"),
            key: 3,
            dataIndex: "customerPhone",
        },
        {
            title: t("DateOfApproval"),
            key: 4,
            dataIndex: "approvedDate",
        },
        {
            title: t("approvedPerson"),
            key: 5,
            dataIndex: "approvedWorkerName",
        },
        {
            title: "",
            key: 6,
            dataIndex: "id",
            render: (i) => {
                return (
                    <div className="flex flex-end">
                        <Tooltip className="ml-5" title={t("viewPurchaseDocument")}>
                            <Button
                                onClick={() => viewPurchaseDetails(i)}
                                className="border-none"
                                type="text"
                                shape="circle"
                            >
                                <FileSearchOutlined />
                            </Button>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];


    const tableData = props.list.map((p, index) => {
        return { ...p, key: index, index , tableIndex : index + 1 ,
            approvedDate: p.approvedDate && moment(p.approvedDate).format("DD-MM-YYYY, hh:mm"),
        };
    });







    return (
        <div className="w-100">
            <Row gutter={[10, 10]}>
                <Col xs={24}>
                    <div className="border flex-between flex p-1  flex flex-align-center px-2 mt-0">
                        <div>
                            <UnorderedListOutlined className="f-20 mr5-15" />
                            <span className="f-20 bold">{t("preparedDocuments")}</span>
                        </div>
                    </div>
                </Col>
                <Col xs={24}>
                    <Table
                        size="small"
                        className="border sektorTable"
                        columns={listColumns}
                        dataSource={convertColumns(tableData, cols)}
                        pagination={false}
                    />
                </Col>
            </Row>


            <Modal
                title={t("detailedInfo")}
                centered
                className="addTaskModal"
                onOk={() => setVisiblePPurchase(false)}
                onCancel={() => setVisiblePPurchase(false)}
                visible={visiblePPurchase}
                footer={null}
            >
                <PPurchase
                    visiblePPurchase={visiblePPurchase}
                    setVisiblePPurchase={setVisiblePPurchase}
                    id={id}
                />
            </Modal>

        </div>
    );
};

const mapStateToProps = ({ options, lang }) => {
    return { options, lang };
};

export default connect(mapStateToProps, { getOptions, notify })(PurchaseList);

