import React, { useEffect } from "react";
import {
  Button,
  Col,
  InputNumber,
  Row,
  Form,
  Modal, Input,
} from "antd";
import agros from "../../../../../const/api";
import { connect } from "react-redux";
import { notify} from "../../../../../redux/actions";
import { useTranslation } from "react-i18next";
import {noWhitespace} from "../../../../../utils/rules";


const IncomeModal = (props) => {
  const [form] = Form.useForm();
  const {notify , data} = props;
  const { t, i18n } = useTranslation();

  const hideModal = () => {
    form.resetFields();
    props.setVisibleIncomeModal(false);
  };

  const addReserve = (values) => {
    console.log(data)
    agros
      .post(`TechnicalStock/Demand`, {
        ...values,
        Count:data.count,
        TechniqueSparePartsId:data.sparePart.id
      })
      .then(() => {
        notify("", true);
        props.triggerFetch();
        hideModal();
      })
      .catch((err) => {
        console.log(err)
        notify(err.response, false);
      });
  };

  return (
    <Modal
      title={'Anbar tələbi yarat'}
      centered
      className="padModal"
      onCancel={hideModal}
      visible={props.visibleIncomeModal}
      footer={false}
    >
      <Form onFinish={addReserve} layout="vertical" form={form}>
        <div className="commontask">
          <Row gutter={[16, 16]}>
            <Col xs={24}>
              <Form.Item
                label={'Vin'}
                name="VIN"
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
              >
                <Input className="w-100" />
              </Form.Item>
            </Col>
          </Row>
        </div>
        <div
          className="modalButtons"
          style={{ position: "absolute", bottom: "20px", right: "25px" }}
        >
          <Button onClick={hideModal}>{t("cancel")}</Button>
          <Button type="primary" className="ml-10" htmlType="submit">
            {t("save")}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ lang }) => {
  return {  lang };
};

export default connect(mapStateToProps, {  notify })(IncomeModal);
