import React, { useState, useEffect } from "react";
import "@ant-design/compatible/assets/index.css";
import {Button, List ,Col, DatePicker, Row, Select, InputNumber, Form, Table, Input} from "antd";
import {getOptions, notify} from "../../../../../redux/actions";
import { connect } from "react-redux";
import agros from "../../../../../const/api";
import { useTranslation } from "react-i18next";
import { noWhitespace } from "../../../../../utils/rules";
import {convertColumns} from "../../../../../utils/columnconverter";
import moment from "moment";
import az from "../../../../../assets/img/az.svg";
import en from "../../../../../assets/img/us.svg";
import ru from "../../../../../assets/img/ru.svg";
const { Option } = Select;

const PurchaseDocument = (props) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [selectedRows , setSelectedRows] = useState([])
  const [selectedRowKeys , setSelectedRowKeys] = useState([])
  const [data, setData] = useState(undefined);
  const [selectData, setSelectData] = useState(undefined);
  const [isClosed,  setIsClosed] =  useState(false)
  const [totalCost , setTotalCost] = useState(0)
  const { notify } = props;
  const { getOptions } = props;
  const options = props.options[props.lang];

  const mapData = (data) => {
    let formData = [];
    let prods = [];
    for(const  d of data){
      const existingEntry = formData.find(o => o.ProductId === d.productId);
      if(existingEntry){
        existingEntry['Quantity'] = parseInt(existingEntry.Quantity) + parseInt(d.editedQuantity)
        existingEntry['maxValue'] = parseInt(existingEntry.maxValue) + parseInt(d.editedQuantity)
        form.setFieldsValue({PurchaseProductList: formData});
        setProducts(formData);
      } else {
            let obj = {
              id: d.id,
              FertilizerKindId: d.fertilizerKindId,
              MainIngredientId: d.mainIngredientId,
              CropCategoryId: d.cropCategoryId,
              CropId: d.cropId,
              Quantity: d.editedQuantity,
              maxValue: d.quantity,
              ProductId: d.productId,
              measurementUnit: d?.measurementUnit,
            };
            let type = "";
            if (d.fertilizerKindId !== null) {
              type = 1;
            } else if (d.cropCategoryId !== null) {
              type = 2;
            } else {
              type = 3;
            }
            prods.push({
              id: d.id,
              type,
              FertilizerKindId: d.fertilizerKindId,
              MainIngredientId: d.mainIngredientId,
              CropCategoryId: d.cropCategoryId,
              CropId: d.cropId,
              quantity: d.editedQuantity,
              maxValue: d.quantity,
              measurementUnit: d?.measurementUnit,
            });
            obj["type"] = type;
            formData.push(obj);
            form.setFieldsValue({PurchaseProductList: formData});
            setProducts(formData);
      }
    }
  };


  const cols = [
    { key: "productName", value: t("name"), con: true },
    { key: "fullQuantity", value: t("quantity"), con: true },
    { key: "productName", value: t("productName"), con: true },
  ];


  const handleTypeChange = (e, index) => {
    const all = [...products];
    all[index].type = e;
    setProducts(all);
  };

  const handleKeyChange = (e, index, key) => {
    const all = [...products];
    all[index][key] = e;
    setProducts(all);
  };

  const columns = [
    {
      title: t("demandName"),
      key: 2,
      dataIndex: "demandName",
    },
    {
      title: t("productName"),
      key: 3,
      dataIndex: "productName",
    },
    {
      title: t("quantityOf"),
      key: 4,
      dataIndex: "fullQuantity",
    },
  ];



  useEffect(() => {
    form.resetFields()
    setSelectedRows([])
    setProducts([])
    setSelectedRowKeys([])
    setSelectData([])
    agros.get("Purchase/DemandProducts").then((res) => {
      setData(
          res.data.map((r, index) => {
            return {
              ...r,
              quantity:r.quantity,
              fullQuantity: r.quantity + ' ' + r.measurementUnit,
              editedQuantity: r.quantity,
              key: index + 1,
              index,
              createDate: moment(r.createDate).format("DD-MM-YYYY"),
            };
          })
      );
    })
    getOptions(
      [
        "parcelCategories",
        "crops",
        "cropSorts",
        "customers",
        "cropCategories",
        "reserves",
        "parcels",
        "paymentKinds",
        "fertilizers",
        "paymentTerms",
        "countries",
        "deliveryTerms",
        "fertilizerKinds",
        "mainIngredients",
      ],
      props.options,
      i18n.language
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t , props.visibleAPurchase]);


  const Calculate = () =>{
    let quantityData = [];
    let newTotalCost = 0;
    form.getFieldsValue().PurchaseProductList &&  form.getFieldsValue().PurchaseProductList.forEach((d) =>{
      let lastprice = parseInt(
          (
              ( ( (d.Price  !== undefined ? d.Price: 0) - (d.Discount  !== undefined ? d.Discount : 0) ) *(d.Quantity  !== undefined ? d.Quantity: 0))+
              (
                  (( (d.Price  !== undefined ? d.Price: 0) - (d.Discount  !== undefined ? d.Discount : 0) )*(d.Quantity  !== undefined ? d.Quantity: 0))*
                  ((d.VATPercentage  !== undefined ? d.VATPercentage : 0)/100)
              )
              + (d.CustomsCost  !== undefined ? d.CustomsCost : 0)
              + (form.getFieldsValue().TransportCost !== undefined ? form.getFieldsValue().TransportCost : 0)
          )
              // .toFixed(1)
      );
      let obj = {
        CountryId: d.CountryId,
        CropCategoryId: d.CropCategoryId,
        MainIngredientId: d.MainIngredientId,
        CropId: d.CropId,
        ProductId: d.ProductId,
        type: d.type,
        FertilizerKindId: d.FertilizerKindId,
        Price:d.Price,
        Quantity: d.Quantity,
        CustomsCost:d.CustomsCost,
        Discount:d.Discount,
        VATPercentage:d.VATPercentage,
        VAT: ((( (d.Price  !== undefined ? d.Price: 0) - (d.Discount  !== undefined ? d.Discount : 0) )*(d.Quantity  !== undefined ? d.Quantity : 0))
            *(d.VATPercentage  !== undefined ? d.VATPercentage: 0)/100),
        LastPrice: (d.Price  !== undefined ? d.Price: 0) - (d.Discount  !== undefined ? d.Discount: 0) + ( (d.Price  !== undefined ? d.Price: 0) - (d.Discount  !== undefined ? d.Discount: 0)  ) * ((d.VATPercentage !== undefined ? d.VATPercentage : 0 ) /100 ),
        endPrice: lastprice
      };
      newTotalCost+= lastprice;
      quantityData.push(obj);
    })
    form.setFieldsValue({
      PurchaseProductList: quantityData,
      TotalCost:newTotalCost
    });
    setTotalCost(newTotalCost)
  }

  const onSwitchChange = () => {
    setIsClosed(!isClosed);
  };

  const rowSelection = {
    type:"checkbox",
    onChange: (selectedRowKeys, selectedRows) =>{
      setStock(selectedRows , selectedRowKeys);
    },
    selectedRowKeys,
    preserveSelectedRowKeys:true,
    hideSelectAll:true,
  }

  const setStock = (selectedRows , selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys)
    setSelectedRows(selectedRows)
    mapData(selectedRows)
  };

  const OnValueChange  =  (value , id) =>{
     const  arr = [...selectedRows]
    let selectedData = arr.find((d)=>d.id === id);
    selectedData['editedQuantity'] = value
    mapData(arr)
  }

  const renderName = (kind) =>{
    switch (kind) {
      case 1:
        return  t('drugAndFertilizer')
        break;
      case 2:
        return  t('product')
        break;
      case 3:
        return  t('reserv')
        break;
    }
  }

  const addProduct = () => {
    const all = [...products];
    all.push({ type: 1 });
    setProducts(all);
  };

  const removeProduct = (index) => {
    const all = [...products];
    all.splice(index, 1);
    setProducts(all);
  };

  const saveDocument = (values) => {
    agros
      .post("purchase", {
        ...values,
        PaymentPeriod: "after 20 day",
        // DemandCloseStatus: isClosed,
      })
      .then((res) => {
        notify(t("newDocumentCreated"), true);
        onCancel()
        props.triggerFetch()
      })
      .catch((err) => {
        notify(err.response, false);
      });
  };


  const setMeasurementUnit = (e, index, obj) => {
    const all = [...products];
    all[index]["measurementUnit"] = options[obj].find(
      (o) => o.id === e
    ).measurementUnit;
    setProducts(all);
  };

  const onCancel = () =>{
    props.setVisibleAPurchase(false);
    form.resetFields()
    setSelectData([])
    setSelectedRows([])
    setProducts([])
    setSelectedRowKeys([])
  }

  const handleMainTypeChange = (e) =>{
    setSelectData(
        data.filter((d)=>{
          return d.productKind === e})
    )
  }
  return (
      <div>
        <Form layout="vertical" form={form} onFinish={saveDocument}>
         <Row gutter={[16,16]}>
          <Col md={6} sm={12} xs={24}>
            <Form.Item
                name={'selectType'}
                validateTrigger="onChange"
                rules={[noWhitespace(t("inputError"))]}
            >
              <Select
                  placeholder={t('typeOf')}
                  showSearch
                  notFoundContent={null}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                  }
                  onChange={(e) => handleMainTypeChange(e)}
              >
                <Option value={1}>{t("drugAndFertilizer")}</Option>
                <Option value={2}>{t("product")}</Option>
                <Option value={3}>{t("reserv")}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Table
                size="small"
                className="w-100"
                columns={columns}
                rowSelection={rowSelection}
                dataSource={convertColumns(selectData, cols)}
                pagination={{ pageSize: 10, current_page: 1 }}
            />
          </Col>
        </Row>
          <div className="commontask">
            <Row gutter={[16, 16]}>
              <Col xs={24}>
                <Row  gutter={[16, 16]}>
                  {selectedRows.map((p , i)=>(
                      <Col key={i} lg={12} md={24}>
                        <div className={'animated zoomIn'}>
                          <Form.Item
                              className={'d-none'}
                              initialValue={p.demandId}
                              validateTrigger="onChange"
                              name={[
                                "PurchaseDemandList",
                                i,
                                "DemandId",
                              ]}
                              rules={[noWhitespace(t("inputError"))]}
                          >
                            <Input type={'hidden'}  className="w-100" />
                          </Form.Item>

                          <Form.Item
                              className={'d-none'}
                              initialValue={p.productId}
                              validateTrigger="onChange"
                              name={[
                                "PurchaseDemandList",
                                i,
                                "ProductId",
                              ]}
                              rules={[noWhitespace(t("inputError"))]}
                          >
                            <Input type={'hidden'}  className="w-100" />
                          </Form.Item>

                          <Form.Item
                              className={'d-none'}
                              initialValue={p.id}
                              validateTrigger="onChange"
                              name={[
                                "PurchaseDemandList",
                                i,
                                "id",
                              ]}
                              rules={[noWhitespace(t("inputError"))]}
                          >
                            <Input type={'hidden'}  className="w-100" />
                          </Form.Item>

                          <List
                              header={
                                  <div className="flex w-100 flex-between">
                                    <p>{t('demandName')}</p>
                                      <p>{p.demandName}</p>
                                  </div>
                              }
                              footer={false}
                              bordered
                              className={'w-100'}
                              dataSource={data}
                          >
                            {p.productKind &&
                              <List.Item>
                                <div className="flex w-100 flex-between">
                                  <div>   {t('productType')}</div>
                                  <div>{renderName(p.productKind)}</div>
                                </div>
                              </List.Item>
                            }
                            {p.productName &&
                              <List.Item>
                                <div className="flex w-100 flex-between">
                                  <div>   {t('productName')}</div>
                                  <div> {p.productName}</div>
                                </div>
                              </List.Item>
                              }
                            <List.Item>
                              <div className="flex w-100 flex-align-center flex-between">
                                <div className={'mr-15'}>
                                  {t('quantity')} : {p.quantity} {p.measurementUnit && p.measurementUnit}
                                </div>
                                <div className={'ml-15'}>
                                  <div className="form-lang">
                                    <Form.Item
                                        initialValue={p.quantity}
                                        validateTrigger="onChange"
                                        name={[
                                          "PurchaseDemandList",
                                          i,
                                          "OneOrderQuantity",
                                        ]}
                                        rules={[noWhitespace(t("inputError"))]}
                                    >
                                      <InputNumber
                                          min={0}
                                          onChange={(e)=>{OnValueChange(e , p.id)}}
                                          max={p.quantity} className="w-100"
                                      />
                                    </Form.Item>
                                    <span className="input-lang btm">
                                                {p?.measurementUnit}
                                        </span>
                                  </div>
                                </div>
                              </div>
                            </List.Item>
                          </List>
                        </div>
                      </Col>
                  ))}
                </Row>
              </Col>
              <Col xs={24}>
                <Form.Item
                    label={t("supplier")}
                    name="CustomerId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                >
                  <Select
                      showSearch
                      notFoundContent={null}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                  >
                    {options.customers.filter((c)=>{return c.positionStatus === 1}).map((c, index) => {
                      return (
                          <Option key={index} value={c.id}>
                            {c.name}
                          </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} sm={12} xs={24}>
                <Form.Item
                    label={t("customsCosts")}
                    name="CustomsInclude"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                >
                  <Select
                      showSearch
                      notFoundContent={null}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                  >
                    <Option value="true">{t("thereIs")}</Option>
                    <Option value="false">{t("thereNo")}</Option>
                  </Select>
                </Form.Item>
              </Col>

              {/*<Col md={8} sm={12} xs={24}>*/}
              {/*  <div className="form-lang">*/}
              {/*    <Form.Item*/}
              {/*      label={t("customsDuties")}*/}
              {/*      name="CustomsCost"*/}
              {/*      validateTrigger="onChange"*/}
              {/*      rules={[noWhitespace(t("inputError"))]}*/}
              {/*    >*/}
              {/*      <InputNumber  onChange={()=>{Calculate()}}  className="w-100" />*/}
              {/*    </Form.Item>*/}
              {/*    <span className="input-lang btm">azn</span>*/}
              {/*  </div>*/}
              {/*</Col>*/}

              <Col md={8} sm={12} xs={24}>
                <Form.Item
                    label={t("transportCosts")}
                    name="TransportInclude"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                >
                  <Select
                      showSearch
                      notFoundContent={null}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }>
                    <Option value="true">{t("thereIs")}</Option>
                    <Option value="false">{t("thereNo")}</Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} sm={12} xs={24}>
                <div className="form-lang">
                  <Form.Item
                      label={t("transportDuties")}
                      name="TransportCost"
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("inputError"))]}
                  >
                    <InputNumber min={0} onChange={(value)=>{Calculate()}} className="w-100" />
                  </Form.Item>
                  <span className="input-lang btm">azn</span>
                </div>
              </Col>

              <Col md={8} sm={12} xs={24}>
                <Form.Item
                    label={t("paymentType")}
                    name="PaymentKindId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                >
                  <Select
                      showSearch
                      notFoundContent={null}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                  >
                    {options.paymentKinds.map((c, index) => {
                      return (
                          <Option key={index} value={c.id}>
                            {c.name}
                          </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} sm={12} xs={24}>
                <Form.Item
                    label={t("paymentTerm")}
                    name="PaymentTermId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                >
                  <Select
                      showSearch
                      notFoundContent={null}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }>
                    {options.paymentTerms.map((c, index) => {
                      return (
                          <Option key={index} value={c.id}>
                            {c.name}
                          </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} sm={12} xs={24}>
                <Form.Item
                    label={t("deliveryTern")}
                    name="DeliveryTermId"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("inputError"))]}
                >
                  <Select
                      showSearch
                      notFoundContent={null}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }
                  >
                    {options.deliveryTerms.map((c, index) => {
                      return (
                          <Option key={index} value={c.id}>
                            {c.name}
                          </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} sm={12} xs={24}>
                <Form.Item
                    label={t("mustDeliverDate")}
                    name="DeliveryPeriod"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("dateError"))]}
                >
                  <DatePicker placeholder={t("selectDate")} className="w-100" />
                </Form.Item>
              </Col>

              <Col md={8} sm={12} xs={24}>
                <Form.Item
                    label={t("lastPaymentDate")}
                    name="PaymentLastDate"
                    validateTrigger="onChange"
                    rules={[noWhitespace(t("dateError"))]}
                >
                  <DatePicker placeholder={t("selectDate")} className="w-100" />
                </Form.Item>
              </Col>


              <Col md={8} sm={12} xs={24}>
                <div className="form-lang">
                  <Form.Item
                      label={'Ümumi yekun qiymət'}
                      name={'TotalCost'}
                      validateTrigger="onChange"
                      rules={[noWhitespace(t("inputError"))]}
                  >
                    <InputNumber   min={0} className="w-100"   readOnly/>
                  </Form.Item>
                  <div className="input-lang btm">azn</div>
                </div>
              </Col>
            </Row>

            {products.map((pr, index) => {
                  return (
                      <div key={index} className="task1 border mt-20  p-2">
                        <Row gutter={[16, 16]}>
                          <Col xs={24}>
                            <div className="w-100  flex-align-center flex flex-between">
                              <h3>
                                {t("product")} {index + 1}
                              </h3>
                              {/*<div className="flex">*/}
                              {/*  {products.length > 1 && (*/}
                              {/*    <Button*/}
                              {/*      className="mr5-5 btn-danger"*/}
                              {/*      onClick={() => removeProduct(index)}*/}
                              {/*    >*/}
                              {/*      {t("delete")}*/}
                              {/*    </Button>*/}
                              {/*  )}*/}
                              {/*  <Button type="primary" onClick={addProduct}>*/}
                              {/*    {t("addTo")}*/}
                              {/*  </Button>*/}
                              {/*</div>*/}
                            </div>
                          </Col>

                          <Col md={6} sm={12} xs={24}>
                            <Form.Item
                                label={t("typeOf")}
                                name={["PurchaseProductList", index, "type"]}
                                initialValue={pr.type}
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                              <Select
                                  disabled
                                  showSearch
                                  notFoundContent={null}
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                  }
                                  onChange={(e) => handleTypeChange(e, index)}>
                                <Option value={1}>{t("drugAndFertilizer")}</Option>
                                <Option value={2}>{t("product")}</Option>
                                <Option value={3}>{t("reserv")}</Option>
                              </Select>
                            </Form.Item>
                          </Col>

                          {pr.type === 1 && (
                              <>
                                <Col md={6} sm={12} xs={24}>
                                  <Form.Item
                                      label={t("productType")}
                                      name={[
                                        "PurchaseProductList",
                                        index,
                                        "FertilizerKindId",
                                      ]}
                                      validateTrigger="onChange"
                                      rules={[noWhitespace(t("inputError"))]}
                                  >
                                    <Select
                                        disabled
                                        showSearch
                                        notFoundContent={null}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        onChange={(e) =>
                                            handleKeyChange(e, index, "FertilizerKindId")
                                        }
                                    >
                                      {options.fertilizerKinds.map((c, index) => {
                                        return (
                                            <Option key={index} value={c.id}>
                                              {c.name}
                                            </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={6} sm={12} xs={24}>
                                  <Form.Item
                                      label={t("activeSubstance")}
                                      name={[
                                        "PurchaseProductList",
                                        index,
                                        "MainIngredientId",
                                      ]}
                                      validateTrigger="onChange"
                                      rules={[noWhitespace(t("inputError"))]}
                                  >
                                    <Select
                                        disabled
                                        showSearch
                                        notFoundContent={null}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        onChange={(e) =>
                                            handleKeyChange(e, index, "MainIngredientId")
                                        }
                                    >
                                      {options.mainIngredients.filter(
                                          (f) =>
                                              f.categoryId ===
                                              pr.FertilizerKindId
                                      ).map((cr, index) => {
                                        return (
                                            <Option key={index} value={cr.id}>
                                              {cr.name}
                                            </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={6} sm={12} xs={24}>
                                  <Form.Item
                                      label={t("productName")}
                                      name={["PurchaseProductList", index, "ProductId"]}
                                      validateTrigger="onChange"
                                      rules={[noWhitespace(t("inputError"))]}
                                  >
                                    <Select
                                        disabled
                                        showSearch
                                        notFoundContent={null}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        onChange={(e) =>
                                            setMeasurementUnit(e, index, "fertilizers")
                                        }
                                    >
                                      {options.fertilizers
                                          .filter(
                                              (cr) =>
                                                  cr.fertilizerKindId === pr.FertilizerKindId &&
                                                  cr.mainIngredientId === pr.MainIngredientId
                                          )
                                          .map((cr, index) => {
                                            return (
                                                <Option key={index} value={cr.id}>
                                                  {cr.name}
                                                </Option>
                                            );
                                          })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </>
                          )}

                          {pr.type === 2 && (
                              <>
                                <Col md={6} sm={12} xs={24}>
                                  <Form.Item
                                      label={t("productCategory")}
                                      name={["PurchaseProductList", index, "CropCategoryId"]}
                                      validateTrigger="onChange"
                                      rules={[noWhitespace(t("inputError"))]}
                                  >
                                    <Select
                                        disabled
                                        showSearch
                                        notFoundContent={null}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        onChange={(e) =>
                                            handleKeyChange(e, index, "CropCategoryId")
                                        }
                                    >
                                      {options.cropCategories.map((cc, index) => {
                                        return (
                                            <Option key={index} value={cc.id}>
                                              {cc.name}
                                            </Option>
                                        );
                                      })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={6} sm={12} xs={24}>
                                  <Form.Item
                                      label={t("product")}
                                      name={["PurchaseProductList", index, "CropId"]}
                                      validateTrigger="onChange"
                                      rules={[noWhitespace(t("inputError"))]}
                                  >
                                    <Select
                                        disabled
                                        showSearch
                                        notFoundContent={null}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }
                                        onChange={(e) => handleKeyChange(e, index, "CropId")}
                                    >
                                      {options.crops
                                          .filter((cs) => cs.categoryId === pr.CropCategoryId)
                                          .map((cr, index) => {
                                            return (
                                                <Option key={index} value={cr.id}>
                                                  {cr.name}
                                                </Option>
                                            );
                                          })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col md={6} sm={12} xs={24}>
                                  <Form.Item
                                      label={t("productSorts")}
                                      name={["PurchaseProductList", index, "ProductId"]}
                                      validateTrigger="onChange"
                                      rules={[noWhitespace(t("inputError"))]}
                                  >
                                    <Select
                                        showSearch
                                        disabled
                                        notFoundContent={null}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                        filterSort={(optionA, optionB) =>
                                            optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                        }

                                        onChange={(e) =>
                                            setMeasurementUnit(e, index, "cropSorts")
                                        }
                                    >
                                      {options.cropSorts
                                          .filter((cs) => cs.categoryId === pr.CropId)
                                          .map((cs, index) => {
                                            return (
                                                <Option key={index} value={cs.id}>
                                                  {cs.name}
                                                </Option>
                                            );
                                          })}
                                    </Select>
                                  </Form.Item>
                                </Col>
                              </>
                          )}

                          {pr.type === 3 && (
                              <Col md={6} sm={12} xs={24}>
                                <Form.Item
                                    label={t("reserv")}
                                    name={["PurchaseProductList", index, "ProductId"]}
                                    validateTrigger="onChange"
                                    rules={[noWhitespace(t("inputError"))]}
                                >
                                  <Select
                                      showSearch
                                      disabled
                                      notFoundContent={null}
                                      optionFilterProp="children"
                                      filterOption={(input, option) =>
                                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                      }
                                      filterSort={(optionA, optionB) =>
                                          optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                      }
                                      onChange={(e) =>
                                          setMeasurementUnit(e, index, "reserves")
                                      }
                                  >
                                    {options.reserves.map((r, index) => {
                                      return (
                                          <Option key={index} value={r.id}>
                                            {r.name}
                                          </Option>
                                      );
                                    })}
                                  </Select>
                                </Form.Item>
                              </Col>
                          )}

                          <Col md={6} sm={12} xs={24}>
                            <Form.Item
                                label={t("originCountry")}
                                name={["PurchaseProductList", index, "CountryId"]}
                                validateTrigger="onChange"
                                rules={[noWhitespace(t("inputError"))]}
                            >
                              <Select
                                  showSearch
                                  notFoundContent={null}
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                  }
                                  filterSort={(optionA, optionB) =>
                                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                                  }
                              >
                                {options.countries.map((c, index) => {
                                  return (
                                      <Option key={index} value={c.id}>
                                        {c.name}
                                      </Option>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                          </Col>


                          <Col md={6} sm={12} xs={24}>
                            <div className="form-lang">
                              <Form.Item
                                  label={t("customsDuties")}
                                  name={["PurchaseProductList", index, "CustomsCost"]}
                                  validateTrigger="onChange"
                                  rules={[noWhitespace(t("inputError"))]}
                              >
                                <InputNumber   min={0} onChange={()=>{Calculate()}}  className="w-100" />
                              </Form.Item>
                              <span className="input-lang btm">azn</span>
                            </div>
                          </Col>

                          <Col md={6} sm={12} xs={24}>
                            <div className="form-lang">
                              <Form.Item
                                  label={t("quantity")}
                                  name={["PurchaseProductList", index, "Quantity"]}
                                  validateTrigger="onChange"
                                  rules={[noWhitespace(t("inputError"))]}
                              >
                                <InputNumber   min={0} readOnly max={pr.maxValue} onChange={()=>{Calculate()}}  className="w-100" />
                              </Form.Item>
                              <div className="input-lang btm">{pr.measurementUnit}</div>
                            </div>
                          </Col>

                          <Col md={6} sm={12} xs={24}>
                            <div className="form-lang">
                              <Form.Item
                                  label={t("price")}
                                  name={["PurchaseProductList", index, "Price"]}
                                  validateTrigger="onChange"
                                  rules={[noWhitespace(t("inputError"))]}
                              >
                                <InputNumber    min={0} onChange={()=>{Calculate()}}  className="w-100" />
                              </Form.Item>
                              <div className="input-lang btm">azn</div>
                            </div>
                          </Col>

                          <Col md={6} sm={12} xs={24}>
                            <div className="form-lang">
                              <Form.Item
                                  label={t("discount")}
                                  name={["PurchaseProductList", index, "Discount"]}
                                  validateTrigger="onChange"
                                  rules={[noWhitespace(t("inputError"))]}
                              >
                                <InputNumber min={0}  onChange={()=>{Calculate()}}  className="w-100" />
                              </Form.Item>
                              <div className="input-lang btm">azn</div>
                            </div>
                          </Col>

                          <Col md={6} sm={12} xs={24}>
                            <div className="form-lang">
                              <Form.Item
                                  label={t("finalPrice")}
                                  name={["PurchaseProductList", index, "LastPrice"]}
                                  validateTrigger="onChange"
                                  rules={[noWhitespace(t("inputError"))]}
                              >
                                <InputNumber min={0} readOnly className="w-100" />
                              </Form.Item>
                              <div className="input-lang btm">azn</div>
                            </div>
                          </Col>

                          <Col md={6} sm={12} xs={24}>
                            <div className="form-lang">
                              <Form.Item
                                  label='Ədv faizi'
                                  name={["PurchaseProductList", index, "VATPercentage"]}
                                  validateTrigger="onChange"
                                  rules={[noWhitespace(t("inputError"))]}
                              >
                                <InputNumber min={0} onChange={()=>{Calculate()}}  className="w-100" />
                              </Form.Item>
                              <div className="input-lang btm">%</div>
                            </div>
                          </Col>

                          <Col md={6} sm={12} xs={24}>
                            <div className="form-lang">
                              <Form.Item
                                  label={t("AdditionalTax")}
                                  name={["PurchaseProductList", index, "VAT"]}
                                  validateTrigger="onChange"
                                  rules={[noWhitespace(t("inputError"))]}
                              >
                                <InputNumber min={0} readOnly className="w-100" />
                              </Form.Item>
                              <div className="input-lang btm">azn</div>
                            </div>
                          </Col>



                          <Col md={6} sm={12} xs={24}>
                            <div className="form-lang">
                              <Form.Item
                                  label={t("Cəm qiymət")}
                                  name={["PurchaseProductList", index, "endPrice"]}
                                  validateTrigger="onChange"
                                  rules={[noWhitespace(t("inputError"))]}
                              >
                                <InputNumber min={0} readOnly className="w-100" />
                              </Form.Item>
                              <div className="input-lang btm">azn</div>
                            </div>
                          </Col>

                        </Row>
                      </div>
                  );
                })}
            <div
                className="modalButtons flex flex-align-center"
                style={{ position: "absolute", bottom: "20px", right: "40px" }}
            >
              {/*<div className="flex ml-10 flex-align-center">*/}
              {/*  <span className="ml-10 mr-10">Status</span>*/}
              {/*  <span className={!isClosed ? "green" : "red"}>*/}
              {/*     {!isClosed ? "Tələbi aktivdir" : "Tələbi bitir"}*/}
              {/*  </span>*/}
              {/*  <Switch className={'ml-10'} checked={!isClosed} onChange={onSwitchChange} />*/}
              {/*</div>*/}
              <Button type="primary" className="ml-10" htmlType="submit">
                {t("save")}
              </Button>
              <Button className="ml-10" onClick={() => {onCancel()}}>
                {t("cancel")}
              </Button>
            </div>
          </div>
        </Form>
      </div>
  );
};

const mapStateToProps = ({ options, lang  }) => {
  return { options, lang  };
};

export default connect(mapStateToProps, { getOptions, notify  })(
  PurchaseDocument
);
