import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/charts';
import {Row , Col } from 'antd'
const DemoLine  = () => {
    const [data, setData] = useState(
        [
            {
                year: '12-21-2020',
                value:5757,
                category: 'Yağış'
            },
            {
                year: '12-22-2020',
                value:8575,
                category: 'Yağış'
            },
            {
                year: '12-25-2020',
                value:4533,
                category: 'Yağış'
            },
            {
                year: '01-01-2021',
                value:2453,
                category: 'Yağış'
            },
            {
                year: '01-05-2021',
                value:5455,
                category: 'Yağış'
            },
            {
                year: '12-21-2020',
                value:2323,
                category: 'Havadakı istilik dərəcəsi'
            },
            {
                year: '12-22-2020',
                value:2323,
                category: 'Havadakı istilik dərəcəsi'
            },
            {
                year: '12-23-2020',
                value:1223,
                category: 'Havadakı istilik dərəcəsi'
            },
            {
                year: '12-24-2020',
                value:6556,
                category: 'Havadakı istilik dərəcəsi'
            },
            {
                year: '12-25-2020',
                value:6565,
                category: 'Havadakı istilik dərəcəsi'
            },
            {
                year: '01-01-2021',
                value:3541,
                category: 'Havadakı istilik dərəcəsi'
            },
            {
                year: '01-05-2021',
                value:3443,
                category: 'Havadakı istilik dərəcəsi'
            },
            {
                year: '12-21-2020',
                value:3452,
                category: 'Torpaqdakı nəmişlik miqdarı'
            },
            {
                year: '12-22-2020',
                value:4345,
                category: 'Torpaqdakı nəmişlik miqdarı'
            },
            {
                year: '12-23-2020',
                value:4444,
                category: 'Torpaqdakı nəmişlik miqdarı'
            },
            {
                year: '12-25-2020',
                value:2434,
                category: 'Torpaqdakı nəmişlik miqdarı'
            },
            {
                year: '12-26-2020',
                value:3233,
                category: 'Torpaqdakı nəmişlik miqdarı'
            },
            {
                year: '01-01-2021',
                value:3232,
                category: 'Torpaqdakı nəmişlik miqdarı'
            },
            {
                year: '01-05-2021',
                value:3243,
                category: 'Torpaqdakı nəmişlik miqdarı'
            },
        ]
);


    var config = {
        data: data,
        xField: 'year',
        yField: 'value',
        seriesField: 'category',
        xAxis: { type: 'time' },
        yAxis: {
            label: {
                formatter: function formatter(v) {
                    return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function (s) {
                        return ''.concat(s, ',');
                    });
                },
            },
        },
        color: ['#469be4', '#3e906e', '#FAA219'],
    };
    return (
        <div>
            <Line {...config} />
            <div className="risks mt-15">
                <Row gutter={[10, 10]}>
                    <Col md={6} xs={12} >
                        <div className="flex p-1 border flex-align-center">
                            <div className="map-circle"></div>
                            <p>Risk yoxdur</p>
                        </div>
                    </Col>
                    <Col md={6} xs={12} >
                        <div className="flex p-1 border flex-align-center">
                            <div className="map-circle blue"></div>
                            <p>Aşağı risk</p>
                        </div>
                    </Col>
                    <Col md={6} xs={12} >
                        <div className="flex p-1 border flex-align-center">
                            <div className="map-circle warning"></div>
                            <p>Orta risk</p>
                        </div>
                    </Col>
                    <Col md={6} xs={12} >
                        <div className="flex p-1 border flex-align-center">
                            <div className="map-circle error"></div>
                            <p>Yüksək risk</p>
                        </div>
                    </Col>
                    <Col xs={24}>
                        <div className="flex p-1  flex-align-center">
                            <p className={'mr-15'}>Hal hazırda</p>
                            <div className="map-circle "></div>
                            <p>Risk yoxdur</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default DemoLine;