import React from 'react';
import {Row, Col, Table} from 'antd'
import {CarOutlined} from "@ant-design/icons";
import ReactPlayer from "react-player";
function Datas({wareHouse, name, keys , w}) {

    const columns = [
        {
            title: 'Məhsulun adı:',
            dataIndex: keys[0],
            key: keys[0],
        },
        name !== 'reserveList' ? {
            title: 'Məhsulun sortu:',
            dataIndex: keys[2],
            key: keys[2],
        } : {},
        {
            title: 'Məhsulun miqdarı:',
            dataIndex: keys[3],
            key: keys[3],
        },
    ];


    return (
        <div className={'mt-5'}>
            <Row gutter={[16, 16]}>
                <Col  lg={24}>
                    <Table
                        size="small"
                        className="bg-white animated fadeInUp"
                        columns={columns}
                        dataSource={
                            w[name].map((s)=>{
                                return{
                                    ...s,
                                    quantity: s.quantity + ' ' + s.measurementUnit
                                }
                            })
                        }
                        pagination={{
                            pageSize: 10,
                            current_page: 1,
                        }}
                    />
                </Col>
            </Row>
        </div>
    );
}


export default Datas;
