import React, { useState, useEffect } from "react";
import {Row, Col, Table, Button, Tooltip, Modal, Form, Input, DatePicker, Select} from "antd";
import {ClearOutlined, EyeFilled, FileSyncOutlined, PlusCircleOutlined} from "@ant-design/icons";
import AddReserve from "./Modals/AddReserve";
import agros from "../../../../const/api";
import { useTranslation } from "react-i18next";
import { convertColumns } from "../../../../utils/columnconverter";
import Authorize from "../../../Elements/Authorize";
import moment from "moment";
import {connect} from "react-redux";
import {getOptions} from "../../../../redux/actions";
import ViewDemand from "./Modals/ViewDemand";

const { Option } = Select;

const PurchasesOnWait = (props) => {
  const { t , i18n} = useTranslation();
  const [form] = Form.useForm();
  const { getOptions } = props;
  const options = props.options[props.lang];
  const [mainIngridientId , setMainIngridientId] = useState(undefined)
  const cols = [
    { key: "tableIndex", value: "#", con: true },
    { key: "productName", value: t("name"), con: true },
    { key: "supplierName", value: t("consumer"), con: true },
    { key: "fertilizerKind", value: t("typeOf"), con: true },
    { key: "mainIngredient", value: t("activeSubstance"), con: true },
    { key: "quantity", value: t("quantityOf"), con: true },
    { key: "index", value: "", con: false },
  ];

  const [purchases, setPurchases] = useState([]);
  const [selected, setSelected] = useState(0);
  let [trigger, setTrigger] = useState(0);

  useEffect(() => {
    getOptions(
        [
          "fertilizers",
          "mainIngredients",
          "customers",
        ],
        props.options,
        i18n.language
    );
    const getPurchases = () => {
      agros.get("medicalstock/medicalpurchase").then((res) => {
        setPurchases(
          res.data.map((r, index) => {
            return { ...r, key: index + 1, index, tableIndex: index + 1 };
          })
        );
      });
    };
    getPurchases();
  }, [trigger , t]);
  const [visibleViewReserves, setVisibleViewReserves] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [visibleDocs, setVisibleDocs] = React.useState(false);


  const clearFilter = () =>{
    setTrigger(++trigger)
    triggerFetch()
    form.resetFields()
  }


  const onSearch = (values) =>{
    let obj = {}
    // eslint-disable-next-line no-unused-expressions
    values.CustomerId ? obj['CustomerId'] = values.CustomerId : null
    // eslint-disable-next-line no-unused-expressions
    values.MainIngredientId ? obj['MainIngredientId'] = values.MainIngredientId : null
    // eslint-disable-next-line no-unused-expressions
    values.ProductId ? obj['ProductId'] = values.ProductId : null
    agros.get('MedicalStock/MedicalPurchase'  , { params: obj}).then((res) => {
      setPurchases(
          res.data.map((r, index) => {
            return { ...r, key: index + 1, index, tableIndex: index + 1 };
          })
      );
    });
  }


  const initialColumns = [
    {
      title: "#",
      dataIndex: "tableIndex",
      key: "1",
      width: 60,
    },
    {
      title: t("consumer"),
      dataIndex: "supplierName",
      key: "2",
    },
    {
      title: t("name"),
      dataIndex: "productName",
      key: "3",
    },
    {
      title: t("typeOf"),
      dataIndex: "fertilizerKind",
      key: "4",
    },
    {
      title: t("activeSubstance"),
      dataIndex: "mainIngredient",
      key: "5",
    },
    {
      title: t("quantityOf"),
      dataIndex: "quantity",
      key: "6",
    },
    {
      title: "",
      dataIndex: "index",
      key: "9",
      render: (i) => {
        return (
          <div className="flex flex-end">
            <Tooltip
                placement="leftBottom"
                className="ml-5"
                title={t("viewPurchaseDocument")}
            >
              <Button
                  className="border-none"
                  shape="circle"
                  onClick={() => setViewingRow(i , 'docs')}
              >
                <EyeFilled />
              </Button>
            </Tooltip>

            <Authorize
                mainMenu={'warehouse'}
                page={['purchasesOnWait','perms']}
                type={'addToReserves'}
            >
              <Tooltip
                placement="leftBottom"
                className="ml-5"
                title={t("addToReserves")}
              >
                <Button
                  className="border-none"
                  shape="circle"
                  onClick={() => setViewingRow(i ,'reserves')}
                >
                  <PlusCircleOutlined />
                </Button>
              </Tooltip>
            </Authorize>
          </div>
        );
      },
    },
  ];

  const handleKeyChange = (e) =>{
    setMainIngridientId(e)
  }

  const setViewingRow = (index , modalName) => {
    setSelected(index);
    if (modalName === 'reserves'){
      setVisibleViewReserves(true);
    }
    if (modalName === 'docs') {
      setVisibleDocs(true)
    }
  };

  const triggerFetch = () => {
    setTrigger(++trigger);
  };

  return (
    <div>
      <Row gutter={[8, 8]}>
        <Col xs={24}>
          <div className="border  flex p-2 mt-0 bg-white">
            <div>
              <FileSyncOutlined className="f-20 mr5-15" />
              <span className="f-20 bold">{t("purchasesOnWait")}</span>
            </div>
          </div>
        </Col>

        <Col xs={24}>
          <div  className={'w-100'}>
            <Form onFinish={onSearch} layout="vertical" form={form}>
              <div className="commontask bg-white px-2 pt-15">
                <Row className={'mt-5'} gutter={[16, 16]}>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                        name="CustomerId"
                        validateTrigger="onChange"
                    >
                      <Select
                          showSearch
                          notFoundContent={null}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          placeholder={t('Tədarükçü')}
                      >
                        {options.customers.filter((c)=>{return c.positionStatus === 2}).map((c, index) => {
                          return (
                              <Option key={index} value={c.id}>
                                {c.name}
                              </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                        name="MainIngredientId"
                        validateTrigger="onChange"
                    >
                      <Select
                          showSearch
                          notFoundContent={null}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={(e)=>{handleKeyChange(e)}}
                          placeholder={t("activeSubstance")}
                      >
                        {options.mainIngredients.map((c, index) => {
                          return (
                              <Option key={index} value={c.id}>
                                {c.name}
                              </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={6} sm={12} xs={24}>
                    <Form.Item
                        name="ProductId"
                        validateTrigger="onChange"
                    >
                      <Select
                          showSearch
                          notFoundContent={null}
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                              optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                          }
                          placeholder={t("productName")}
                      >
                        {options.fertilizers.filter((m)=>{return mainIngridientId && m.mainIngredientId === mainIngridientId }).map((c, index) => {
                          return (
                              <Option key={index} value={c.id}>
                                {c.name}
                              </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>


                  <Col md={3} sm={12} xs={24}>
                    <Form.Item
                        name={" "}
                    >
                      <Button type="primary" size={'large'}  className="w-100 f-13" htmlType="submit">
                        {t("search")}
                      </Button>
                    </Form.Item>
                  </Col>

                  <Col md={3} sm={12} xs={24}>
                    <Form.Item
                        name={" "}
                    >
                      <Button type="primary" size={'large'}  onClick={()=>{clearFilter()}} className="w-100" htmlType="submit">
                        <ClearOutlined />
                      </Button>
                    </Form.Item>
                  </Col>

                </Row>
              </div>
            </Form>
          </div>
        </Col>

        <Col xs={24}>
          <Table
            size="small"
            className="bg-white"
            columns={initialColumns}
            dataSource={convertColumns(purchases, cols)}
            pagination={{
              current_page: 1,
              pageSizeOptions: ["10", "15", "20", "25", "30"],
              showSizeChanger: true,
            }}
          />
        </Col>
      </Row>

      <Modal
        title={t("addToReserves")}
        centered
        className="addTaskModal padModal"
        visible={visibleViewReserves}
        onCancel={() => setVisibleViewReserves(false)}
        footer={null}
      >
        <AddReserve
          reserve={purchases[selected]}
          visibleViewReserves={visibleViewReserves}
          triggerFetch={triggerFetch}
          setVisibleViewReserves={setVisibleViewReserves}
        />
      </Modal>

      <Modal
          title={t("viewPurchaseDocument")}
          centered
          className="addTaskModal padModal"
          visible={visibleDocs}
          onCancel={() => setVisibleDocs(false)}
          footer={null}
      >
        <ViewDemand
            id={purchases[selected]?.id}
            visibleDocs={visibleDocs}
            triggerFetch={triggerFetch}
            setVisibleDocs={setVisibleDocs}
        />
      </Modal>


    </div>
  );
}

const mapStateToProps = ({ options, lang }) => {
  return { options, lang };
};

export default connect(mapStateToProps, { getOptions })(PurchasesOnWait);


