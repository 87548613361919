import React, {useEffect} from 'react';
import { Col, Row, Tabs} from "antd";
import { AreaChartOutlined} from "@ant-design/icons";
// import Datas from "./Datas";
import Images from "../../../../assets/img/Images/Images";
// const { TabPane } = Tabs;
import {getArtesian} from "../../../../redux/actions";
import {connect} from "react-redux";

function Artesian(props) {

    const {artesian, getArtesian} = props
    useEffect(() => {
        let  mounted = true
        if(mounted){
            if(artesian.length === 0) {
                getArtesian();
            }
        }
        return function cleanUp() {
            mounted = false
        }
    },[])

    return (
        <div>
            <Col xs={24}>
                <div className="border page-heading animated fadeIn bg-white p-2 mt-0">
                    <AreaChartOutlined className="f-20 mr5-15" />
                    <span className="f-20 bold">Artezyanlar</span>
                </div>
            </Col>
            <Row className={'mt-15 '} gutter={[10, 10]}>
                {
                    artesian.map((a,i) => (
                        <Col key={i} lg={12} className="mb-50" >
                            <div className="page-name">
                                <span className="f-20 bold">{a.wellName}</span>
                            </div>
                            <div className="card bg-white animated zoomIn">
                                <div className="artesian">
                                    <div className='flex flex-between mb-50'>
                                        <div className="flex-column text-center">
                                            <div className='mt-25 mb-20'>
                                                <img src={a.sabotage === true ? Images.True : Images.False} alt="" />
                                            </div>
                                            <div className='flex flex-align-baseline'>
                                                <p className='mr-5'>Sabotaj:</p>
                                                <span>{a.sabotage === true ? "Yararlı" : "Yararsız"}</span>
                                            </div>
                                        </div>
                                        <div className="flex-column text-center">
                                            <div className='mt-25 mb-20'>
                                                <img src={a.hasEnergy === true ? Images.FlashOn : Images.FlashOff} alt="" />
                                            </div>
                                            <div className='flex flex-align-baseline'>
                                                <p className='mr-5'>Enerji:</p>
                                                <span>{a.hasEnergy === true ? "Var" : "Yoxdur"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-between mb-50'>
                                        <div className="flex-column ">
                                            <div className='mt-25 mb-20'>
                                                <img src={a.thermal === true ? Images.Hot : Images.Cold} alt="" />
                                            </div>
                                            <div className='flex flex-align-baseline'>
                                                <p className='mr-5'>Motortermik:</p>
                                                <span>9°</span>
                                            </div>
                                        </div>
                                        <div className="flex-column text-center">
                                            <div className='mt-25 mb-20'>
                                                <img src={a.hasWater === true ? Images.WaterOn : Images.WaterOff} alt="" />
                                            </div>
                                            <div className='flex flex-align-baseline'>
                                                <p className='mr-5'>Quyuda su:</p>
                                                <span>{a.hasWater === true ? "Var" : "Yoxdur"}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='flex flex-between mb-50'>
                                        <div className="flex-column text-center">
                                            <div className='mt-25 mb-20'>
                                                <button>{a.waterMeter}</button>
                                            </div>
                                            <div className='flex all-center'>
                                                <p className='mr-5'>İşıq sayağacı</p>
                                            </div>
                                        </div>
                                        <div className="flex-column text-center">
                                            <div className='mt-25 mb-20'>
                                                <button>{a.lightMeter}</button>
                                            </div>
                                            <div className='flex all-center'>
                                                <p className='mr-5'>Su sayağacı</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))
                }
            </Row>
        </div>
    );
}

const mapStateToProps = ({artesian }) => {
    return { artesian };
}

export default connect(mapStateToProps, { getArtesian}) (Artesian);
