import React , { useState, useEffect , useRef } from 'react';
import { loadModules } from 'esri-loader';
import './map.css'
import {Modal, Tabs, Select, Spin, Tooltip, Popover, Badge, Button} from 'antd';
import Statistics from "./Statistics";
import NewMap from "./NewMap";
import BasicTemplate from "../Garage/EquipmentService/BasicTemplate/BasicTemplate";
import agros from "../../../const/api";
import NewChart from "../../Elements/NewChart";
import {AreaChartOutlined, FilterFilled, LoadingOutlined} from "@ant-design/icons";
import menu from "../../../assets/img/icons/menu.svg";
const { Option  , OptGroup } = Select;
const { TabPane } = Tabs;

//         // ID=1 - bitkiler; ID=2 - Baglar; Ekin - bag ve bitkilerin icerisi; NOV- Ekinin icerisidi


const Test = (props) => {
    const mapRef = useRef()
    const [show , setShow] = useState(false)
    const [tabkey , setTabkey] = useState('map')
    const [spinning , setSpinning] = useState(false)
    const [mapData, setMapData] = useState({})
    const [pergo , setPergo] = useState([])
    const [stansions , setStansions] = useState([])
    // eslint-disable-next-line no-unused-vars

    const  loadMap = (query) =>{
        loadModules(["esri/Map", "esri/layers/MapImageLayer" ,"esri/layers/FeatureLayer", "esri/widgets/ScaleBar","esri/widgets/TimeSlider",
            "esri/views/MapView","esri/widgets/Legend", "esri/widgets/Expand","esri/TimeExtent", "esri/widgets/LayerList" ], { css: true })
            .then(([ArcGISMap, MapImageLayer, FeatureLayer, Scalebar, TimeSlider, MapView, Legend, Expand, TimeExtent, LayerList]) => {
                const popTemplate = {
                    // autocasts as new PopupTemplate()
                    title: "Əkinlə bağlı məlumat",
                    content: `
                       <div>
                         <table class="customtable mb-15"> 
                            <tbody>
                                <tr>
                                    <td>Ekin</td>
                                    <td>{EKIN}</td>
                                 </tr>
                                 <tr>
                                    <td>Növ</td>
                                    <td>{NOV}</td>
                                </tr>
                            </tbody>
                         </table>
                          <div id="test" class="risc">Xəstəlik riski</div>
                        </div>
                    `
                };



                const agrosLayer = new FeatureLayer({
                    title:'Area map',
                    url: "http://213.154.5.139:8021/arcgis/rest/services/AgroParkDetail/MapServer/1", //agros
                    "opacity": 0.9,
                    definitionExpression: query &&  query,
                    // definitionExpression: null,
                    outFields: ["*"], // used by queryFeatures
                    popupTemplate: popTemplate
                });




                // const baseOrtho = new MapImageLayer({
                //     url: "http://213.154.5.139:8021/arcgis/rest/services/RasterDataAgroPark/MapServer", //agros
                //     visible:true,
                //     title: 'Topography map',
                //     sublayers: [
                //         {
                //             id: 0,
                //             title:'ATMOSPHERIC',
                //             visible: false
                //         },
                //         {
                //             id: 1,
                //             title:'ULTRAVİOLET 2',
                //             visible: false
                //         },
                //         {
                //             id: 2,
                //             title:'SAVI',
                //             visible: false
                //         },
                //         {
                //             id: 3,
                //             title:'NATURAL COLOR',
                //             visible: false
                //         },
                //         {
                //             title:'INFRARED',
                //             id: 4,
                //             visible: false
                //         },
                //         {
                //             title:'ARVI',
                //             id: 5,
                //             visible: false
                //         },
                //         {
                //             title:'AZERCOSMOS',
                //             id: 6,
                //             visible: false
                //         }
                //     ]
                // });



                //create the infoTemplate to be used in the infoWindow.
                //All ${attributeName} will be substituted with the attribute value for current feature.

                const map = new ArcGISMap({
                    basemap: 'satellite',
                    // layers: [baseOrtho, agrosLayer]
                    layers: [agrosLayer]
                });

                const view = new MapView({
                    container: mapRef.current,
                    map: map,
                    center: [46.8579570, 41.22099959], //DC coordinates
                    zoom: 13,
                });


                const legend = new Legend({
                    view: view,
                    layerInfos: [
                        {
                            layer: agrosLayer,
                            title: "Legend",
                        }
                    ],
                });

                const scaleBar = new Scalebar({
                    view: view,
                    scalebarUnit: "english"
                })
                view.ui.add(scaleBar,"bottom-right");

                const expandLegend = new Expand({
                    view: view,
                    expandIconClass: "esri-icon-layers",
                    expandTooltip: "Legend",
                    content: legend,
                    expanded: false,
                    mode: "floating",
                });
                view.ui.add(expandLegend,"top-left");

                const date_today = new Date();
                const date_yesterday = new Date(date_today);
                date_yesterday.setDate(date_yesterday.getDate()-1);


                const layerList = new LayerList({
                    view: view
                })
                view.ui.add(layerList, "top-right");
            })
    }


    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


    useEffect(()=>{
        loadMap(null)
        window.addEventListener('click', (e) => {
            if (e.target.getAttribute("class") === 'risc' ){
                setShow(true)
            }
        })

        setSpinning(true)

        agros.get('weatherlink/v2').then((res)=>{
            setStansions(res.data)
        })

        getData().then(()=>{
            setSpinning(false)
        })

        agros.get('http://213.154.5.139:8021/arcgis/rest/services/AgroParkDetail/MapServer/1/query?f=json&geometry=%7B%22spatialReference%22%3A%7B%22latestWkid%22%3A3857%2C%22wkid%22%3A102100%7D%2C%22xmin%22%3A5205055.8781030215%2C%22ymin%22%3A5048512.844180988%2C%22xmax%22%3A5214839.81772352%2C%22ymax%22%3A5058296.783801489%7D&maxAllowableOffset=19.10925707128908&orderByFields=OBJECTID_12%20ASC&outFields=*&outSR=102100&resultType=tile&returnExceededLimitFeatures=false&spatialRel=esriSpatialRelIntersects&geometryType=esriGeometryEnvelope&inSR=102100\n' , {
            params:{
                outFields:'*',
                where: '1=1'
            }
        }).then((res)=>{
            console.log(res.data)
            setMapData(res.data)
        })

    }, [1])



    const getData = async  () => {
      await  agros.get('Pergo').then((s)=>{
          setPergo(s.data)
          console.log(s.data)
      })
    }




    return (
        <div>
            <div>

                <Modal
                    title={'Xəstəlik riskləri'}
                    centered
                    className="mediumModal riskmodal"
                    visible={show}
                    onOk={() => setShow(false)}
                    onCancel={() => setShow(false)}
                    footer={[]}
                >
                    <Statistics/>
                </Modal>

                {tabkey === 'map' ?
                    <NewChart mapData={mapData} />
                    :
                    ''
                }


                <div  className="tab-section animated  mainBoxes mt-10 mr-10 ml-10 p-1">
                    <Tabs onChange={(e)=>{setTabkey(e)}} className={'tab-map'} activeKey={tabkey}>
                        <TabPane className={'mainBoxes'} tab={'Ərazilər'} key={'map'}>
                            <div className="selects">
                               <Tooltip  placement="right" title={'Filter'}>
                                <Popover
                                    placement="bottomRight"
                                    content={<div>
                                            <Select listHeight={600} onChange={(value)=>{loadMap(value)}} defaultValue="Hamısı" style={{ width: 280 , position:'relative !important' }} >
                                                <Option value={null}>Hamısı</Option>
                                                <OptGroup label="Sahələr">
                                                    <Option value={`ID=N'2'`}>Bağlar</Option>
                                                    <Option value={`ID=N'1'`}>Dənli bitkilər</Option>
                                                </OptGroup>
                                                <OptGroup label="Bağlar">
                                                    <Option value={`EKIN=N'ALMA'`}>Alma</Option>
                                                    <Option value={`EKIN=N'ALÇA'`}>Alça</Option>
                                                    <Option value={`EKIN=N'Şaftalı'`}>Şaftalı</Option>
                                                    <Option value={`EKIN=N'Armud'`}>Armud</Option>
                                                    <Option value={`EKIN=N'Gilas'`}>Gilas</Option>
                                                    <Option value={`EKIN=N'Qaysı'`}>Qaysı</Option>
                                                    <Option value={`EKIN=N'Badəm'`}>Badəm</Option>
                                                    <Option value={`EKIN=N'Qoz'`}>Qoz</Option>
                                                    <Option value={`EKIN=N'Üzüm'`}>Üzüm</Option>
                                                </OptGroup>
                                                <OptGroup label="Dənli bitkilər">
                                                    <Option value={`EKIN=N'Buğda'`}>Buğda</Option>
                                                    <Option value={`EKIN=N'Yonca'`}>Yonca</Option>
                                                    <Option value={`EKIN=N'Soya'`}>Soya</Option>
                                                    <Option value={`EKIN=N'Arpa'`}>Arpa</Option>
                                                    <Option value={`EKIN=N'Qarğıdalı'`}>Qarğıdalı</Option>
                                                </OptGroup>
                                                <Option value={`EKIN=N'Bostan'`}>Bostan</Option>
                                            </Select>
                                    </div>}
                                    trigger="click"
                                >
                                    <Button style={{fontSize:'20px' , paddingTop:'15px' , paddingBottom:'15px'}} size={'small'} className={' flex all-center'}>
                                        <FilterFilled />
                                    </Button>
                                </Popover>
                            </Tooltip>
                            </div>


                            {/*ref={slider => (clienthistory.current = slider)}*/}
                            <div className="webmap w-100" style={  { height: '83.5vh' } } ref={mapRef} />
                        </TabPane>
                        <TabPane disabled={spinning} tab={<div>
                            <Spin indicator={antIcon} size={'large'} spinning={spinning}>
                                  Nəqliyyat və Metroloji stansiyalar
                            </Spin>
                        </div>} key={'metroloji'}>
                            <Spin indicator={antIcon} spinning={spinning}>
                                <NewMap pergo={pergo} stansions={stansions} />
                            </Spin>
                        </TabPane>

                    </Tabs>
                </div>



            </div>
        </div>
    );
}


export default Test;