import React from "react";
import '../../assets/css/compas.css'

const Compas = ({direction}) => {
    return (
        <div className="compass">
            <div className="compass-inner">
                <div className="north">N</div>
                <div className="east">E</div>
                <div className="west">W</div>
                <div className="south">S</div>
                <div style={{transform:`rotate(${direction}deg)`}} className="main-arrow">
                    <div className="arrow-up"></div>
                    <div className="arrow-down"></div>
                </div>
            </div>
        </div>
    )
}


export default Compas