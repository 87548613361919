import React from 'react';
import {Tabs} from "antd";
import {BankOutlined} from "@ant-design/icons";
import Storage from "./Storage";
import Silos from "./Silos";
const { TabPane } = Tabs;


function MainStorage(props) {
    return (
        <>
            <div className={'w-100'}>
                <div className="border  animated fadeInDown page-heading flex-between flex p-2 mt-0 bg-white">
                    <div className="page-name">
                        <BankOutlined className="f-20 mr-15" />
                        <span className="f-20 bold">Anbarlar və silolar</span>
                    </div>
                </div>
            </div>
            <div style={{height:'100vh'}} className="w-100 purchase-tabs tab-section">
                <div className="position-relative animated fadeIn mt-15">
                    <Tabs style={{height:'100vh !important'}} defaultActiveKey="1">
                        <TabPane tab={'Anbarlar'} key={1}>
                            <Storage style={{height:'20000px'}} />
                        </TabPane>
                        <TabPane tab={'Silolar'} key={2}>
                            <Silos/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </>
    );
}


export default MainStorage ;
