import React , {useEffect , useState} from 'react';
import "@ant-design/compatible/assets/index.css";
import {Tabs , Spin} from 'antd';
import {UnorderedListOutlined} from "@ant-design/icons";
import Charts from "./Charts";
import history from "../../../../const/history";
import agros from "../../../../const/api";
const { TabPane } = Tabs;




function Analyzes(props) {
    const [stansions, setStansions] = useState([])
    const [stationData, setStationData] = useState({})
    const [spin, setSpin] = useState(false)
    const [tabkey, setTabkey] = useState(undefined)
    let hash = history.location.hash.split('#')[1]

    useEffect(()=>{
        getData()
    } , [hash])


    const getData = async () => {
      setSpin(true)
      await agros.get('weatherlink/v2').then((res)=>{
          setStansions(res.data)
          if (res.data.length && !hash){
              getStationData(res.data[0].id)
          }
          else{
              getStationData(hash)
              setTabkey(hash)
          }
      })
    }


    const getStationData = async (id) => {

        setSpin(true)
        await agros.get(`weatherlink/v2/station/${id}`).then((res)=>{
            setStationData(res.data)
            console.log(res)
        }).finally(()=>{
            setSpin(false)
        })
    }

    return (
        <div>
            <div className="border bg-white p-2 mt-0">
                <UnorderedListOutlined className="f-20 mr5-15" />
                <span className="f-20 bold">Metroloji stansiyalar </span>
            </div>
            <Spin spinning={spin} className={'bg-white p-3 mt-15'}>
                <div className="position-relative mt-15">
                    <div className="position-absolute w-100 purchase-tabs tab-section">
                        <Tabs onChange={(e)=>{getStationData(e); setTabkey(e)}}   activeKey={tabkey} >
                            {stansions.map((s)=>(
                                <TabPane tab={`Stansiya ${s.id}`} key={s.id}>
                                    <Charts data={stationData} />
                                </TabPane>
                            ))}
                        </Tabs>
                    </div>
                </div>
            </Spin>
        </div>
    );
}



export default Analyzes;
